import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// components
import JSSidebar from './JSSidebar';
import Footer from '../element/Footer';
import NavBar from '../element/NavBar';
// helpers
import { logOutIfTokenExpired, resultInfo } from '../helpers';

const JSEducation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');

  const [loading, setLoading] = useState(false);
  const [yearsList, setYearsList] = useState([]);
  const [educationDetails, setEducationDetails] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + '/candidates/editEducation',
        null,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);

      if (response.data.status === 200) {
        setYearsList(response.data.response.yearList);
        setEducationDetails(response.data.response.eduDetails);
      }  else {
        resultInfo('error', response.data.message, t('jobseekerEducation.close'));
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      }

      console.error(error);
    }
  };

  const formattedYearList = yearsList.map((year, index) => ({
    id: index,
    value: year,
  }));

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    setEducationDetails(prevEducationDetails => {
      const updatedDetails = [...prevEducationDetails];
      updatedDetails[index] = {
        ...updatedDetails[index],
        [name]: value,
      };

      return updatedDetails;
    });
  };

  const handleYearChange = (selectedOption, name, index) => {
    setEducationDetails(prevEducationDetails => {
      const updatedDetails = [...prevEducationDetails];

      updatedDetails[index] = {
        ...updatedDetails[index],
        [name]: selectedOption?.value || '',
      };

      return updatedDetails;
    });
  };

  const validateFields = () => {
    const errors = educationDetails.map(edu => ({
      basic_year: edu.basic_year === '',
      basic_course_id: edu.basic_course_id === '',
      basic_university: edu.basic_university.trim() === '',
    }));

    setValidationErrors(errors);
    return errors.every(
      error =>
        !error.basic_year &&
        !error.basic_course_id &&
        !error.basic_university,
    );
  };

  const handleClick = async () => {
    if (!validateFields()) {
      window.scrollTo(0, 0);

      return;
    }

    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: t('jobseekerEducation.no'),
        confirmButtonText: t('jobseekerEducation.yes'),
        text: t('jobseekerEducation.educationConfirmTxt'),
        title: t('jobseekerEducation.educationConfirmTitle'),
      });

      if (confirmationResult.isConfirmed) {
        setLoading(true);

        const response = await axios.post(
          BaseApi + '/candidates/editEducation',
          { Education: educationDetails },
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              'Content-Type': 'application/json',
            },
          },
        );

        setLoading(false);

        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            confirmButtonText: t('jobseekerEducation.close'),
            text: t('jobseekerEducation.educationSuccessTxt'),
            title: t('jobseekerEducation.educationSuccessTitle'),
          });

          setEducationDetails([]);
          getData();
        } else if (response.data.status === 400) {
          navigate('/');

          Cookies.remove('fname');
          Cookies.remove('user_id');
          Cookies.remove('user_type');
          Cookies.remove('tokenClient');

          resultInfo('warning', response.data.message, t('jobseekerEducation.close'));
        } else {
          resultInfo('error', response.data.message, t('jobseekerEducation.close'));
        }
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        Swal.fire({
          icon: 'error',
          confirmButtonText: t('jobseekerEducation.close'),
          text: t('jobseekerEducation.educationFailedTxt'),
          title: t('jobseekerEducation.educationFailedTitle'),
        });
      }

      console.error(error);
    }
  };

  const handleRemove = async id => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: t('jobseekerEducation.no'),
        confirmButtonText: t('jobseekerEducation.yes'),
        text: t('jobseekerEducation.removeConfirmTxt'),
        title: t('jobseekerEducation.removeConfirmTitle'),
      });

      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + `/candidates/deleteeducation/${id}`,
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          getData();

          resultInfo('success', t('jobseekerEducation.removeSuccessTitle'), t('jobseekerEducation.close'));
        } else if (response.data.status === 400) {
          navigate('/');

          Cookies.remove('fname');
          Cookies.remove('user_id');
          Cookies.remove('user_type');
          Cookies.remove('tokenClient');

          resultInfo('warning', response.data.message, t('jobseekerEducation.close'));
        } else {
          resultInfo('error', response.data.message, t('jobseekerEducation.close'));
        }
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        Swal.fire({
          icon: 'error',
          text: t('jobseekerEducation.removeFailedTxt'),
          confirmButtonText: t('jobseekerEducation.close'),
          title: t('jobseekerEducation.removeFailedTitle'),
        });
      }

      console.error(error);
    }
  };

  const handleRemoveWithoutId = indexToRemove => {
    setEducationDetails(prevEducationDetails =>
      prevEducationDetails.filter((_, index) => index !== indexToRemove),
    );
  };

  const handleAdd = () => {
    const newQualification = {
      basic_year: '',
      basic_course_id: '',
      basic_university: '',
    };

    setEducationDetails(prevEducationDetails => [
      ...prevEducationDetails,
      newQualification,
    ]);
  };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/user/jobseekerlogin');
    } else {
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); // eslint-disable-line

  return (
    <>
      <NavBar />
      <div className='container editProfile'>
        <div className='row'>
          <div className='col-lg-3'>
            <JSSidebar />
          </div>
          {
            loading
              ? (
                <div className='loader-container'></div>
              ) : (
                <div
                  className='col-lg-9 mb-5'
                  style={{
                    borderLeft: '2px solid #e6e8e7',
                    borderRight: '2px solid #e6e8e7',
                  }}
                >
                  <div className='mx-3 d-flex PageHeader'>
                    <img src='/Images/jobseekerSide/Education-color.png' alt='' />
                    <h3 className='ms-1' style={{ color: '#549cb4' }}>
                      {t('jobseekerEducation.educationInfo')}
                    </h3>
                  </div>
                  <form>
                    <div className=' mt-4 mx-4'>
                      {
                        educationDetails.map((j, index) => (
                          <>
                            <h4 className='mt-5 mb-5'>
                              {t('jobseekerEducation.institution')} №{index + 1}:
                            </h4>
                            <div className='form-outline mb-4 DashBoardInputBx'>
                              <label className='form-label' htmlFor='form3Example3'>
                                {t('jobseekerEducation.university/institute')}
                                <span className='RedStar'>*</span>
                              </label>
                              <input
                                type='text'
                                id='basic_university'
                                name='basic_university'
                                className='form-control'
                                value={j.basic_university}
                                onChange={e => handleChange(e, index)}
                                placeholder={t('jobseekerEducation.university/institute')}
                              />
                              <div className='mt-0'>
                                {
                                  validationErrors[index]?.basic_university && (
                                    <small className='text-danger'>
                                      {t('jobseekerEducation.universityRequired')}
                                    </small>
                                  )
                                }
                              </div>
                            </div>
                            <div className='form-outline mb-4 DashBoardInputBx'>
                              <label className='form-label' htmlFor='form3Example3'>
                                {t('jobseekerEducation.courseName')}
                                <span className='RedStar'>*</span>
                              </label>
                              <input
                                type='text'
                                id='basic_course_id'
                                name='basic_course_id'
                                className='form-control'
                                value={j.basic_course_id}
                                onChange={e => handleChange(e, index, j.id)}
                                placeholder={t('jobseekerEducation.courseName')}
                              />
                              <div className='mt-0'>
                                {
                                  validationErrors[index]?.basic_course_id && (
                                    <small className='text-danger'>
                                      {t('jobseekerEducation.courseNameRequired')}
                                    </small>
                                  )
                                }
                              </div>
                            </div>
                            <div className='form-outline mb-4 DashBoardInputBx skillPackage'>
                              <label
                                className='form-label'
                                htmlFor='form3Example3'
                              >
                                {t('jobseekerEducation.passedIn')}
                                <span className='RedStar'>*</span>
                              </label>
                              <Select
                                options={formattedYearList.map(year => ({
                                  value: year.value,
                                  label: year.value,
                                }))}
                                value={{
                                  value: j.basic_year,
                                  label: j.basic_year
                                    ? j.basic_year
                                    : t('jobseekerEducation.selectYear'),
                                }}
                                onChange={selectedOption =>
                                  handleYearChange(
                                    selectedOption,
                                    'basic_year',
                                    index,
                                  )
                                }
                              />
                              <div className='mt-0'>
                                {
                                  validationErrors[index]?.basic_year && (
                                    <small className='text-danger'>
                                      {t('jobseekerEducation.yearRequired')}
                                    </small>
                                  )
                                }
                              </div>
                              <div className='row'>
                                <div className='removeButtonJobseeker mb-4 mt-4'>
                                  <button
                                    type='button'
                                    className='btn btn-primary button2'
                                    onClick={() => j.id ? handleRemove(j.id) : handleRemoveWithoutId(index)}
                                  >
                                    {t('jobseekerEducation.removeButton')}
                                  </button>
                                  <button
                                    type='button'
                                    onClick={handleAdd}
                                    className='btn btn-primary button1'
                                  >
                                    {t('jobseekerEducation.addMoreButton')}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ))
                      }
                      {
                        educationDetails.length <= 0 && (
                          <>
                            <div className='noData'>{t('jobseekerEducation.noInfoAvl')}</div>
                            <div className='mb-4 mt-4 PRAddMore jobseekerAddDetailsButton'>
                              <button
                                type='button'
                                onClick={handleAdd}
                                className='btn btn-primary button1'
                              >
                                {t('jobseekerEducation.addDetails')}
                              </button>
                            </div>
                          </>
                        )
                      }
                      {
                        educationDetails.length > 0 && (
                          <div className='mt-4 bottomButtons'>
                            <button
                              type='button'
                              onClick={handleClick}
                              className='btn btn-primary button1'
                            >
                              {t('jobseekerEducation.updateButton')}
                            </button>
                            <button
                              type='button'
                              className='btn btn-primary button2'
                              onClick={() => navigate('/candidates/my-cv-list')}
                            >
                              {t('jobseekerEducation.cancelButton')}
                            </button>
                          </div>
                        )
                      }
                    </div>
                  </form>
                </div>
              )
          }
        </div>
      </div>
      <Footer />
    </>
  );
};

export default JSEducation;
