/* eslint-disable react/prop-types */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// components
import EducationInfoBlock from './components/EducationInfoBlock';
import WorkExperienceBlock from './components/WorkExperienceBlock';
import DesiredPositionBlock from './components/DesiredPositionBlock';
import ContactInformationBlock from './components/ContactInformationBlock';
import PersonalInformationBlock from './components/PersonalInformationBlock';
import AdditionalInfoBlock from './components/AdditionalInfo';

const JSEditProfileForm = ({ errors, faculties, setErrors, directories, profileBody, handleSubmit, setFaculties, setProfileBody }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');

  const handleChange = (e, index) => {
    const { name, value, type, checked } = e.target;

    const cleanName = name.replace(`_${index}`, '');
    const experienceFields = ['achievements', 'exp_country', 'from_month_exp', 'from_year_exp', 'to_month_exp', 'to_year_exp'];
    const educationFields = ['edu_type', 'institution', 'faculty', 'specialty', 'from_month_edu', 'from_year_edu', 'to_month_edu', 'to_year_edu'];

    setProfileBody(prevProfileBody => {
      const updatedProfileBody = { ...prevProfileBody };

      if (cleanName === 'gender') {
        updatedProfileBody.gender = value === 'female' ? '0' : '1';
      } else if (cleanName === 'category') {
        updatedProfileBody.desired_category[index] = { ...updatedProfileBody.desired_category[index], [cleanName]: value };
      } else if (experienceFields.includes(cleanName)) {
        updatedProfileBody.experiences[index] = { ...updatedProfileBody.experiences[index], [cleanName]: value };
        if ((cleanName.startsWith('to_') && updatedProfileBody.experiences[index].still_working)) {
          updatedProfileBody.experiences[index].still_working = 0;
        }
      } else if (cleanName === 'still_working') {
        updatedProfileBody.experiences[index] = { ...updatedProfileBody.experiences[index], still_working: checked };
        if (checked) {
          updatedProfileBody.experiences[index].to_year_exp = '';
          updatedProfileBody.experiences[index].to_month_exp = '';
        }
      } else if (cleanName === 'edu_type') {
        updatedProfileBody.educations[index] = { ...updatedProfileBody.educations[index], [cleanName]: value, institution: '', faculty: '', specialty: '' };
        setFaculties(prevState => prevState.map((fac, i) => i === index ? [] : fac));
      } else if (educationFields.includes(cleanName)) {
        updatedProfileBody.educations[index] = { ...updatedProfileBody.educations[index], [cleanName]: value };
        if ((cleanName.startsWith('to_') && updatedProfileBody.educations[index].still_learning)) {
          updatedProfileBody.educations[index].still_learning = 0;
        }
      } else if (cleanName === 'still_learning') {
        updatedProfileBody.educations[index] = { ...updatedProfileBody.educations[index], still_learning: checked };
        if (checked) {
          updatedProfileBody.educations[index].to_year_edu = '';
          updatedProfileBody.educations[index].to_month_edu = '';
        }
      } else if (['course_name', 'course_link'].includes(cleanName)) {
        updatedProfileBody.additional_educations[index] = { ...updatedProfileBody.additional_educations[index], [cleanName]: value };
      } else if (['lng', 'lng_skill'].includes(cleanName)) {
        updatedProfileBody.languages[index] = { ...updatedProfileBody.languages[index], [cleanName]: value };
      } else if (cleanName === 'driver_license') {
        updatedProfileBody.driver_license = type === 'checkbox' ? checked : value;
      } else {
        updatedProfileBody[cleanName] = value;
      }

      return updatedProfileBody;
    });

    setErrors(prevErrors => {
      const updatedErrors = { ...prevErrors };

      if (cleanName === 'category') {
        updatedErrors.desired_category[index] = { ...updatedErrors.desired_category[index], [cleanName]: '' };
      } else if (experienceFields.includes(cleanName)) {
        updatedErrors.experiences[index] = { ...updatedErrors.experiences[index], [cleanName]: '' };
      } else if (cleanName === 'still_working') {
        updatedErrors.experiences[index] = { ...updatedErrors.experiences[index], to_month_exp: '', to_year_exp: '' };
      } else if (educationFields.includes(cleanName)) {
        updatedErrors.educations[index] = { ...updatedErrors.educations[index], [cleanName]: '' };
      } else if (cleanName === 'still_learning') {
        updatedErrors.educations[index] = { ...updatedErrors.educations[index], to_month_edu: '', to_year_edu: '' };
      } else if (['course_name', 'course_link'].includes(cleanName)) {
        updatedErrors.additional_educations[index] = { ...updatedErrors.additional_educations[index], [cleanName]: '' };
      } else if (['lng', 'lng_skill'].includes(cleanName)) {
        updatedErrors.languages[index] = { ...updatedErrors.languages[index], [cleanName]: '' };
      } else {
        updatedErrors[cleanName] = '';
      }

      return updatedErrors;
    });
  };

  return (
    <form>
      <div className='mb-5 mt-4 mx-lg-4'>
        <PersonalInformationBlock
          errors={errors}
          setErrors={setErrors}
          directories={directories}
          profileBody={profileBody}
          handleChange={handleChange}
          setProfileBody={setProfileBody}
        />
        <ContactInformationBlock
          errors={errors}
          setErrors={setErrors}
          directories={directories}
          profileBody={profileBody}
          handleChange={handleChange}
          setProfileBody={setProfileBody}
        />
        <DesiredPositionBlock
          errors={errors}
          setErrors={setErrors}
          directories={directories}
          profileBody={profileBody}
          handleChange={handleChange}
          setProfileBody={setProfileBody}
        />
        <WorkExperienceBlock
          errors={errors}
          setErrors={setErrors}
          directories={directories}
          profileBody={profileBody}
          handleChange={handleChange}
          setProfileBody={setProfileBody}
        />
        <EducationInfoBlock
          errors={errors}
          faculties={faculties}
          setErrors={setErrors}
          directories={directories}
          profileBody={profileBody}
          handleChange={handleChange}
          setFaculties={setFaculties}
          setProfileBody={setProfileBody}
        />
        <AdditionalInfoBlock
          errors={errors}
          setErrors={setErrors}
          directories={directories}
          profileBody={profileBody}
          handleChange={handleChange}
          setProfileBody={setProfileBody}
        />
        <div className='bottomButtons'>
          <button
            type='button'
            onClick={handleSubmit}
            className='btn btn-primary button1'
          >
            {t('jobseekerEditProfile.updateButton')}
          </button>
          <button
            type='button'
            onClick={handleSubmit}
            className='btn btn-primary button1'
          >
            {t('jobseekerEditProfile.createCVButton')}
          </button>
          <button
            type='button'
            className='btn btn-primary button2'
            onClick={() => navigate('/candidates/my-cv-list')}
          >
            {t('jobseekerEditProfile.cancelButton')}
          </button>
        </div>
      </div>
    </form>
  );
};

export default JSEditProfileForm;
