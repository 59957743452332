import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// components
import Sidebar from './Sidebar';
import Footer from '../element/Footer';
import NavBar from '../element/NavBar';
import JSSidebar from '../jobseekersSide/JSSidebar';
// helpers
import { logOutIfTokenExpired } from '../helpers';

const InnerMembershipPlan = () => {
  const navigate = useNavigate();
  const curr = Cookies.get('curr');
  const { t } = useTranslation('global');
  const userType = Cookies.get('user_type');
  const tokenKey = Cookies.get('tokenClient');
  const primaryColor = Cookies.get('primaryColor');
  const secondaryColor = Cookies.get('secondaryColor');

  const [loading, setLoading] = useState(false);
  const [membershipData, setMembershipData] = useState([]);

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(BaseApi + '/plans/purchase', null, {
        headers: {
          key: ApiKey,
          token: tokenKey,
          'Content-Type': 'application/json',
        },
      });

      setLoading(false);
      setMembershipData(response.data.response.plan);
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      }

      console.error('Could not get membership plan data!');
    }
  };

  const freePlanPurchase = async planId => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        showCancelButton: true,
        text: t('membershipPlan.modalTxt'),
        title: t('membershipPlan.confirmTitle'),
        cancelButtonText: t('membershipPlan.confirmReject'),
        confirmButtonText: t('membershipPlan.confirmApply'),
      });

      if (confirmationResult.isConfirmed) {
        const response = await axios.post(
          BaseApi + '/plans/order',
          { id: planId },
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            text: t('membershipPlan.freePlanSuccessTxt'),
            confirmButtonText: t('membershipPlan.close'),
            title: t('membershipPlan.freePlanSuccessTitle'),
          });

          if (userType === 'candidate') {
            navigate('/candidates/my-cv-list');
          }

          if (userType === 'recruiter') {
            navigate('/user/myprofile');
          }
        }
      }
    } catch (error) {
      const confirmationResult = await Swal.fire({
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: t('membershipPlan.close'),
        confirmButtonText: t('membershipPlan.contacts'),
        title: t('membershipPlan.orderPlanFailedTitle'),
      });

      if (confirmationResult.isConfirmed) {
        navigate('/contact');
      }

      console.error(error);
    }
  };

  const handleOrderPlan = async planId => {
    try {
      const response = await axios.post(
        BaseApi + '/plans/order',
        { id: planId },
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          confirmButtonText: t('membershipPlan.close'),
          text: t('membershipPlan.orderPlanSuccessTxt'),
          title: t('membershipPlan.orderPlanSuccessTitle'),
        });

        if (userType === 'candidate') {
          navigate('/candidates/my-cv-list');
        }

        if (userType === 'recruiter') {
          navigate('/user/myprofile');
        }
      }
    } catch (error) {
      const confirmationResult = await Swal.fire({
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: t('membershipPlan.close'),
        confirmButtonText: t('membershipPlan.contacts'),
        title: t('membershipPlan.orderPlanFailedTitle'),
      });

      if (confirmationResult.isConfirmed) {
        navigate('/contact');
      }

      console.error(error);
    }
  };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/user/employerlogin');
    } else {
      getData();

      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); // eslint-disable-line

  return (
    <>
      <NavBar />
      <div className='container membershipPlan'>
        <div className='row'>
          <div className='col-lg-3'>
            {userType == 'candidate' ? <JSSidebar /> : <Sidebar />}
          </div>
          {
            loading
              ? (
                <div className='loader-container'></div>
              ) : (
                <div
                  className='col-lg-9 mb-5 CLPanelRight'
                  style={{
                    borderLeft: '2px solid #e6e8e7',
                    borderRight: '2px solid #e6e8e7',
                  }}
                >
                  <div className='d-flex PageHeader'>
                    <img src='/Images/employerSide/us-dollar-32-color.png' alt='' />
                    <h3 className='mx-2'>
                      {' '}
                      {t('membershipPlan.purchaseMembershipPlan')}
                    </h3>
                  </div>
                  <div className='row'>
                    {
                      membershipData.map(i => (
                        <div key={i.plan_id} className='col-md-4 mt-5'>
                          <div className='card text-center membershipCardMain'>
                            <div className='item'>
                              <div className='MembershipCardAuth'>
                                <div className='membershipCardAuthUpperPart'>
                                  <h4>{i.plan_name}</h4>
                                  <h2 style={{ color: primaryColor }}>
                                    {curr} {i.amount}
                                  </h2>
                                  {
                                    i.plan_type_value === 'Months' && (
                                      i.plan_type == '1'
                                        ? (
                                          <h6 className='mt-3'>
                                            {i.plan_type}{' '}
                                            {t('membershipPlan.month')}
                                          </h6>
                                        ) : (
                                          <h6 className='mt-3'>
                                            {i.plan_type}{' '}
                                            {t('membershipPlan.months')}
                                          </h6>
                                        )
                                    )
                                  }
                                  {
                                    i.plan_type_value === 'Years' && (
                                      i.plan_type == '1'
                                        ? (
                                          <h6 className='mt-3'>
                                            {i.plan_type} {t('membershipPlan.year')}
                                          </h6>
                                        ) : (
                                          <h6 className='mt-3'>
                                            {i.plan_type}{' '}
                                            {t('membershipPlan.years')}
                                          </h6>
                                        )
                                    )
                                  }
                                </div>
                                {
                                  i.is_plan_active === 1 && (
                                    <Link
                                      className='btn btn-secondary'
                                      style={{
                                        cursor: 'default',
                                        border: secondaryColor,
                                        backgroundColor: secondaryColor,
                                      }}
                                    >
                                      {t('membershipPlan.currentPlanButton')}
                                    </Link>
                                  )
                                }
                                {
                                  i.is_plan_active === 0 && (
                                    i.amount <= 0
                                      ? (
                                        <Link
                                          className='btn btn-primary'
                                          style={{
                                            border: primaryColor,
                                            backgroundColor: primaryColor,
                                          }}
                                          onClick={() => freePlanPurchase(i.plan_id)}
                                        >
                                          {t('membershipPlan.applyThisPlan')}
                                        </Link>
                                      ) : (
                                        <Link
                                          className='btn btn-primary'
                                          onClick={() => handleOrderPlan(i.plan_id)}
                                          style={{
                                            backgroundColor: primaryColor,
                                            border: primaryColor,
                                          }}
                                        >
                                          {t('membershipPlan.buyThisPlan')}
                                        </Link>
                                      )
                                  )
                                }
                                <hr />
                                <ul className='applyoptionAuth'>
                                  {
                                    i.features.map((j, index) => (
                                      <li key={index}>
                                        {
                                          j.outer
                                            ? HTMLReactParser(j.outer)
                                            : ''
                                        }
                                        <Tooltip
                                          placement='top'
                                          overlay={
                                            <span>
                                              {
                                                j.inner
                                                  ? HTMLReactParser(j.inner)
                                                  : ''
                                              }
                                            </span>
                                          }
                                        >
                                          <span>
                                            <i className='fa-solid fa-circle-info ms-2'></i>
                                          </span>
                                        </Tooltip>
                                      </li>
                                    ))
                                  }
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              )
          }
        </div>
      </div>
      <Footer />
    </>
  );
};

export default InnerMembershipPlan;
