import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// components
import JSSidebar from './JSSidebar';
import NavBar from '../element/NavBar';
import Footer from '../element/Footer';
// forms
import JSEditProfileForm from '../forms/JSEditProfileForm';
// helpers
import { logOutIfTokenExpired, resultInfo, scrollToElement } from '../helpers';

const JSEditProfile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');

  const [loading, setLoading] = useState(true);
  const [faculties, setFaculties] = useState([]);
  const [directories, setDirectories] = useState({});
  const [profileBody, setProfileBody] = useState({});

  const {
    city = '',
    gender = '',
    skills = [],
    country = '',
    birthday = '',
    position = '',
    languages = [],
    last_name = '',
    work_type = '',
    educations = [],
    first_name = '',
    salary_max = '',
    salary_min = '',
    visibility = '',
    experiences = [],
    middle_name = '',
    relocations = [],
    social_links = [],
    profile_image = '',
    video_cv_link = '',
    about_yourself = '',
    contact_number = '',
    driver_license = '',
    desired_country = '',
    driver_category = [],
    messenger_links = [],
    salary_currency = '',
    contact_number_2 = '',
    contact_number_3 = '',
    desired_category = [],
    additional_educations = [],
  } = profileBody;
  console.log('profileBody', profileBody);

  const [errors, setErrors] = useState({
    city: '',
    gender: '',
    skills: '',
    country: '',
    birthday: '',
    position: '',
    languages: [],
    last_name: '',
    work_type: '',
    educations: [],
    first_name: '',
    salary_max: '',
    salary_min: '',
    visibility: '',
    experiences: [],
    middle_name: '',
    relocations: '',
    social_links: [],
    email_address: '',
    profile_image: '',
    video_cv_link: '',
    about_yourself: '',
    contact_number: '',
    driver_license: '',
    desired_country: '',
    driver_category: '',
    messenger_links: [],
    salary_currency: '',
    contact_number_2: '',
    contact_number_3: '',
    desired_category: [],
    additional_educations: [],
  });
  console.log('errors', errors);

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + '/candidates/editProfile',
        null,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);

      if (response.data.status === 200) {
        const data = response.data.response;
        const userDetail = {
          ...data.userdetail,
          country: data.userdetail.country ?? 5,
          desired_country: data.userdetail.desired_country ?? 5,
          experiences: data.userdetail.experiences.map((exp, index) =>
            index === 0 ? { ...exp, exp_country: exp.exp_country ? exp.exp_country : 5 } : exp,
          ),
        };

        setDirectories(data);
        setProfileBody(userDetail);
      } else if (response.data.status === 400) {
        navigate('/');

        Cookies.remove('fname');
        Cookies.remove('user_id');
        Cookies.remove('user_type');
        Cookies.remove('tokenClient');

        resultInfo('warning', response.data.message, t('jobseekerEditProfile.close'));
      } else {
        resultInfo('error', response.data.message, t('jobseekerEditProfile.close'));
      }
    } catch (error) {
      setLoading(false);

      if (
        error.message === 'Network Error' || (error.response && error.response.data.message === 'Token has expired')
      ) {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      }

      console.error(error);
    }
  };

  const handleSubmit = async () => {
    try {
      const salaryFormat = /^\d*\.?\d+$/;
      const nameFormat = /^[A-Za-zА-Яа-яіІїЇєЄыЫ]+$/;
      const contactNumberFormat = /^\+\d{1,3}\d{6,14}$/;
      const urlFormat = /^(https?|http|ftp):\/\/[^\s/$.?#].[^\s]*$/;

      const newErrors = {
        messenger_links: messenger_links.reduce((acc, item, index) => {
          const fieldErrors = {};

          if (!item.type && item.link) {
            fieldErrors.type = t('jobseekerEditProfile.messengerTypeRequired', { index: index + 1 });
            scrollToElement(`messenger_type_${index}`);
          }

          if (item.type && !item.link) {
            if (item.type === 'Telegram') {
              fieldErrors.link = t('jobseekerEditProfile.messengerLinkRequired', { index: index + 1 });
            } else if (['WhatsApp', 'Viber', 'Signal'].includes(item.type)) {
              fieldErrors.link = t('jobseekerEditProfile.messengerContactNumberRequired', { index: index + 1 });
            } else if (['Skype', 'Discord'].includes(item.type)) {
              fieldErrors.link = t('jobseekerEditProfile.messengerNameRequired', { index: index + 1 });
            }
            scrollToElement(`messenger_link_${index}`);
          }

          if (item.link) {
            if (['WhatsApp', 'Viber', 'Signal'].includes(item.type) && !contactNumberFormat.test(item.link)) {
              fieldErrors.link = t('jobseekerEditProfile.contactNumberValidation', { index: index + 1 });
              scrollToElement(`messenger_link_${index}`);
            }

            if (['Telegram', 'Skype', 'Discord'].includes(item.type) && urlFormat.test(item.link)) {
              fieldErrors.link = t('jobseekerEditProfile.messengerNicknameInvalid', { index: index + 1 });
              scrollToElement(`messenger_link_${index}`);
            }
          }

          if (Object.keys(fieldErrors).length > 0) {
            acc[index] = fieldErrors;
          }

          return acc;
        }, []),

        social_links: social_links.reduce((acc, item, index) => {
          const fieldErrors = {};

          if (!item.type && item.link) {
            fieldErrors.type = t('jobseekerEditProfile.socialTypeRequired', { index: index + 1 });
            scrollToElement(`social_type_${index}`);
          }

          if (item.type) {
            if (!item.link) {
              fieldErrors.link = t('jobseekerEditProfile.socialLinkRequired', { index: index + 1 });
              scrollToElement(`social_link_${index}`);
            } else {
              const urlFormatMap = {
                'LinkedIn': /^https:\/\/www\.linkedin\.com\//,
                'Facebook': /^https:\/\/www\.facebook\.com\//,
                'Twitter': /^https:\/\/x\.com\//,
                'Instagram': /^https:\/\/www\.instagram\.com\//,
                'GitHub': /^https:\/\/github\.com\//,
                'TikTok': /^https:\/\/www\.tiktok\.com\//,
              };

              const selectedSocialFormat = urlFormatMap[item.type];

              if (selectedSocialFormat && !selectedSocialFormat.test(item.link)) {
                fieldErrors.link = t('jobseekerEditProfile.socialLinkValidation', { index: index + 1, type: item.type });
                scrollToElement(`social_link_${index}`);
              }
            }
          }

          if (Object.keys(fieldErrors).length > 0) {
            acc[index] = fieldErrors;
          }

          return acc;
        }, []),

        desired_category: desired_category.reduce((acc, item, index) => {
          const fieldErrors = {};

          if (!item.category) {
            fieldErrors.category = t('jobseekerEditProfile.categoryRequired', { index: index + 1 });
            scrollToElement(`category_${index}`);
          }

          if (item.sub_category == '') {
            fieldErrors.sub_category = t('jobseekerEditProfile.subCategoryRequired', { index: index + 1 });
            scrollToElement(`sub_category_${index}`);
          }

          if (Object.keys(fieldErrors).length > 0) {
            acc[index] = fieldErrors;
          }

          return acc;
        }, []),

        experiences: experiences.reduce((acc, item, index) => {
          const fieldErrors = {};

          if (item.position == '') {
            fieldErrors.position = t('jobseekerEditProfile.positionRequired', { index: index + 1 });
            scrollToElement(`position_${index}`);
          }

          if (item.organization == '') {
            fieldErrors.organization = t('jobseekerEditProfile.organizationRequired', { index: index + 1 });
            scrollToElement(`organization_${index}`);
          }

          if (item.industry == '') {
            fieldErrors.industry = t('jobseekerEditProfile.industryRequired', { index: index + 1 });
            scrollToElement(`industry_${index}`);
          }

          if (item.exp_country == '') {
            fieldErrors.exp_country = t('jobseekerEditProfile.countryRequired', { index: index + 1 });
            scrollToElement(`exp_country_${index}`);
          }

          if (item.from_month_exp == '') {
            fieldErrors.from_month_exp = t('jobseekerEditProfile.monthRequired', { index: index + 1 });
            scrollToElement(`from_month_exp_${index}`);
          }

          if (item.from_year_exp == '') {
            fieldErrors.from_year_exp = t('jobseekerEditProfile.yearRequired', { index: index + 1 });
            scrollToElement(`from_year_exp_${index}`);
          }

          if (item.to_month_exp == '' && !item.still_working) {
            fieldErrors.to_month_exp = t('jobseekerEditProfile.monthRequired', { index: index + 1 });
            scrollToElement(`to_month_exp_${index}`);
          }

          if (item.to_year_exp == '' && !item.still_working) {
            fieldErrors.to_year_exp = t('jobseekerEditProfile.yearRequired', { index: index + 1 });
            scrollToElement(`to_year_exp_${index}`);
          }

          if (item.achievements == '') {
            fieldErrors.achievements = t('jobseekerEditProfile.achievementsRequired', { index: index + 1 });
            scrollToElement(`achievements_${index}`);
          }

          if (
            item.to_year_exp && item.to_month_exp &&
            (item.from_year_exp > item.to_year_exp || (item.from_year_exp === item.to_year_exp && item.from_month_exp >= item.to_month_exp))
          ) {
            fieldErrors.to_month_exp = t('jobseekerEditProfile.endDateMustBeAfterStartDate', { index: index + 1 });
            scrollToElement(`to_month_exp_${index}`);
          }

          if (Object.keys(fieldErrors).length > 0) {
            acc[index] = fieldErrors;
          }

          return acc;
        }, []),

        educations: educations.reduce((acc, item, index) => {
          const fieldErrors = {};

          if (item.edu_type == '') {
            fieldErrors.edu_type = t('jobseekerEditProfile.educationalDegreeRequired', { index: index + 1 });
            scrollToElement(`edu_type_${index}`);
          }

          if (item.institution == '') {
            fieldErrors.institution = t('jobseekerEditProfile.institutionRequired', { index: index + 1 });
            scrollToElement(`institution_${index}`);
          }

          if (faculties[index] != '' && (item.edu_type !== '7' || item.edu_type !== '8') && item.faculty == '') {
            fieldErrors.faculty = t('jobseekerEditProfile.facultyRequired', { index: index + 1 });
            scrollToElement(`faculty_${index}`);
          }

          if (item.institution != '' && item.edu_type !== '7' && item.specialty == '') {
            fieldErrors.specialty = t('jobseekerEditProfile.specialtyRequired', { index: index + 1 });
            scrollToElement(`specialty_${index}`);
          }

          if (item.from_month_edu == '') {
            fieldErrors.from_month_edu = t('jobseekerEditProfile.monthRequired', { index: index + 1 });
            scrollToElement(`from_month_edu_${index}`);
          }

          if (item.from_year_edu == '') {
            fieldErrors.from_year_edu = t('jobseekerEditProfile.yearRequired', { index: index + 1 });
            scrollToElement(`from_year_edu_${index}`);
          }

          if (item.to_month_edu == '' && !item.still_learning) {
            fieldErrors.to_month_edu = t('jobseekerEditProfile.monthRequired', { index: index + 1 });
            scrollToElement(`to_month_edu_${index}`);
          }

          if (item.to_year_edu == '' && !item.still_learning) {
            fieldErrors.to_year_edu = t('jobseekerEditProfile.yearRequired', { index: index + 1 });
            scrollToElement(`to_year_edu_${index}`);
          }

          if (
            item.to_year_edu && item.to_month_edu &&
            (item.from_year_edu > item.to_year_edu || (item.from_year_edu === item.to_year_edu && item.from_month_edu >= item.to_month_edu))
          ) {
            fieldErrors.to_month_edu = t('jobseekerEditProfile.endDateMustBeAfterStartDate', { index: index + 1 });
            scrollToElement(`to_month_edu_${index}`);
          }

          if (Object.keys(fieldErrors).length > 0) {
            acc[index] = fieldErrors;
          }

          return acc;
        }, []),

        additional_educations: additional_educations.reduce((acc, item, index) => {
          const fieldErrors = {};
          const fileInput = document.querySelector(`[name="certificate_${index}"]`);

          if (item.course_name && item.course_name.trim().length > 255) {
            fieldErrors.course_name = t('jobseekerEditProfile.textMaxLength', { index: index + 1 });
            scrollToElement(`course_name_${index}`);
          }

          if (item.course_link && !urlFormat.test(item.course_link.trim())) {
            fieldErrors.course_link = t('jobseekerEditProfile.urlInvalid', { index: index + 1 });
            scrollToElement(`course_link_${index}`);
          }

          if (fileInput && fileInput.files.length > 0 && fileInput.files[0].size >= 5 * 1024 * 1024) {
            fieldErrors.certificate = t('jobseekerEditProfile.maxFileSize', { index: index + 1 });
            scrollToElement(`certificate_${index}`);
          }

          if (Object.keys(fieldErrors).length > 0) {
            acc[index] = fieldErrors;
          }

          return acc;
        }, []),

        languages: languages.reduce((acc, item, index) => {
          const fieldErrors = {};

          if (!item.lng && item.lng_skill) {
            fieldErrors.lng = t('jobseekerEditProfile.lngRequired', { index: index + 1 });
            scrollToElement(`lng_${index}`);
          }

          if (item.lng && !item.lng_skill) {
            fieldErrors.lng_skill = t('jobseekerEditProfile.lngSkillRequired', { index: index + 1 });
            scrollToElement(`lng_skill_${index}`);
          }

          if (Object.keys(fieldErrors).length > 0) {
            acc[index] = fieldErrors;
          }

          return acc;
        }, []),
      };

      if (first_name.trim() === '') {
        newErrors.first_name = t('jobseekerEditProfile.firstNameRequired');
        scrollToElement('first_name');
      } else if (!nameFormat.test(first_name)) {
        newErrors.first_name = t('jobseekerEditProfile.textFormat');
        scrollToElement('first_name');
      } else if (first_name.trim().length > 255) {
        newErrors.first_name = t('jobseekerEditProfile.textMaxLength');
        scrollToElement('first_name');
      }
      if (last_name.trim() === '') {
        newErrors.last_name = t('jobseekerEditProfile.lastNameRequired');
        scrollToElement('last_name');
      } else if (!nameFormat.test(last_name)) {
        newErrors.last_name = t('jobseekerEditProfile.textFormat');
        scrollToElement('last_name');
      } else if (last_name.trim().length > 255) {
        newErrors.last_name = t('jobseekerEditProfile.textMaxLength');
        scrollToElement('last_name');
      }
      if (middle_name) {
        if (!nameFormat.test(middle_name)) {
          newErrors.middle_name = t('jobseekerEditProfile.textFormat');
          scrollToElement('middle_name');
        } else if (middle_name.trim().length > 255) {
          newErrors.middle_name = t('jobseekerEditProfile.textMaxLength');
          scrollToElement('middle_name');
        }
      }
      if (!gender) {
        newErrors.gender = t('jobseekerEditProfile.genderRequired');
        scrollToElement('gender');
      }
      if (!birthday) {
        newErrors.birthday = t('jobseekerEditProfile.birthdayRequired');
        scrollToElement('birthday');
      } else {
        const minDate = new Date('1930-01-01');
        const maxDate = new Date();
        const selectedDate = new Date(birthday);

        if (selectedDate < minDate) {
          newErrors.birthday = t('jobseekerEditProfile.birthdayTooEarly');
          scrollToElement('birthday');
        } else if (selectedDate > maxDate) {
          newErrors.birthday = t('jobseekerEditProfile.birthdayTooLate');
          scrollToElement('birthday');
        }
      }
      if (!country) {
        newErrors.country = t('jobseekerEditProfile.countryRequired');
        scrollToElement('country');
      }
      if (!city) {
        newErrors.city = t('jobseekerEditProfile.cityRequired');
        scrollToElement('city');
      }
      if (!contact_number) {
        newErrors.contact_number = t('jobseekerEditProfile.contactNumberRequired');
        scrollToElement('contact_number');
      } else if (!contactNumberFormat.test(contact_number)) {
        newErrors.contact_number = t('jobseekerEditProfile.contactNumberValidation');
        scrollToElement('contact_number');
      }
      if (contact_number_2 && !contactNumberFormat.test(contact_number_2)) {
        newErrors.contact_number_2 = t('jobseekerEditProfile.contactNumberValidation');
        scrollToElement('contact_number');
      }
      if (contact_number_3 && !contactNumberFormat.test(contact_number_3)) {
        newErrors.contact_number_3 = t('jobseekerEditProfile.contactNumberValidation');
        scrollToElement('contact_number');
      }
      if (video_cv_link && !urlFormat.test(video_cv_link.trim())) {
        newErrors.video_cv_link = t('jobseekerEditProfile.urlInvalid');
        scrollToElement('video_cv_link');
      }
      if (!position) {
        newErrors.position = t('jobseekerEditProfile.positionRequired');
        scrollToElement('position');
      }
      if (!desired_country) {
        newErrors.desired_country = t('jobseekerEditProfile.countryRequired');
        scrollToElement('desired_country');
      }
      if (relocations.length === 0) {
        newErrors.relocations = t('jobseekerEditProfile.relocationsRequired');
        scrollToElement('relocations');
      }
      if (salary_min && !salaryFormat.test(salary_min)) {
        newErrors.salary_min = t('jobseekerEditProfile.salaryNumeric');
        scrollToElement('salary_min');
      }
      if (salary_max) {
        if (!salaryFormat.test(salary_max)) {
          newErrors.salary_max = t('jobseekerEditProfile.salaryNumeric');
          scrollToElement('salary_max');
        }
        if (parseFloat(salary_max) < parseFloat(salary_min)) {
          newErrors.salary_max = t('jobseekerEditProfile.salaryMaxLowerSalaryMin');
          scrollToElement('salary_max');
        }
      }
      if (!salary_currency && (salary_min || salary_max)) {
        newErrors.salary_currency = t('jobseekerEditProfile.salaryCurrencyRequired');
        scrollToElement('salary_currency');
      }
      if (!salary_currency && (salary_min || salary_max)) {
        newErrors.salary_currency = t('jobseekerEditProfile.salaryCurrencyRequired');
        scrollToElement('salary_currency');
      }
      if (work_type.length === 0) {
        newErrors.work_type = t('jobseekerEditProfile.workTypeRequired');
        scrollToElement('work_type');
      }
      if (about_yourself == '') {
        newErrors.about_yourself = t('jobseekerEditProfile.descriptionRequired');
        scrollToElement('about_yourself');
      }
      if (skills.length === 0) {
        newErrors.skills = t('jobseekerEditProfile.skillRequired');
        scrollToElement('skills');
      }
      if (driver_license && driver_category.length === 0) {
        newErrors.driver_category = t('jobseekerEditProfile.driverCategoryRequired');
        scrollToElement('driver_category');
      }
      if (!visibility) {
        newErrors.visibility = t('jobseekerEditProfile.visibilityRequired');
        scrollToElement('visibility');
      }

      setErrors(newErrors);

      const hasErrors = Object.keys(newErrors).some(
        key => Array.isArray(newErrors[key]) ? newErrors[key].length > 0 : newErrors[key],
      );

      if (!hasErrors) {
        const confirmationResult = await Swal.fire({
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: t('jobseekerEditProfile.no'),
          text: t('jobseekerEditProfile.editConfirmTxt'),
          confirmButtonText: t('jobseekerEditProfile.yes'),
          title: t('jobseekerEditProfile.editConfirmTitle'),
        });

        if (confirmationResult.isConfirmed) {
          const response = await axios.post(
            BaseApi + '/candidates/editProfile',
            profileBody,
            {
              headers: {
                key: ApiKey,
                token: tokenKey,
                'Content-Type': 'application/json',
              },
            },
          );

          setLoading(false);

          if (response.data.status === 200) {
            navigate('/candidates/my-cv-list');

            resultInfo('success', t('jobseekerEditProfile.editSuccessTitle'), t('jobseekerEditProfile.close'));
          } else if (response.data.status === 400) {
            navigate('/');

            Cookies.remove('fname');
            Cookies.remove('user_id');
            Cookies.remove('user_type');
            Cookies.remove('tokenClient');

            resultInfo('warning', response.data.message, t('jobseekerEditProfile.close'));
          } else {
            resultInfo('error', response.data.message, t('jobseekerEditProfile.close'));
          }
        }
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        resultInfo('error', t('jobseekerEditProfile.editFailedTitle'), t('jobseekerEditProfile.close'));
      }

      console.error('Could not submit edit data!', error);
    }
  };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/user/jobseekerlogin');
    } else {
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); // eslint-disable-line

  return (
    <>
      <NavBar />
      <div className='container editProfile'>
        <div className='row'>
          <div className='col-lg-3'>
            <JSSidebar />
          </div>
          {
            loading
              ? (
                <div className='loader-container'></div>
              ) : (
                <div
                  className='col-lg-9 mb-5'
                  style={{
                    borderLeft: '2px solid #e6e8e7',
                    borderRight: '2px solid #e6e8e7',
                  }}
                >
                  <div className='mx-lg-3 d-flex PageHeader'>
                    <img src='/Images/employerSide/icon8color.png' alt='' />
                    <h3>{t('jobseekerEditProfile.editProfile')}</h3>
                  </div>
                  <div className='JSEPFirstSegment'>
                    <div className='profileImageBox'>
                      <img
                        alt='profile image'
                        className='profileImage'
                        src={profile_image ? profile_image : '/Images/jobseekerSide/dummy-profile.png'}
                      />
                    </div>
                    <Link
                      to='/candidates/uploadPhoto'
                      className='btn btn-primary button1'
                    >
                      {t('jobseekerEditProfile.changePhotoButton')}
                    </Link>
                  </div>
                  {
                    profileBody != '' && (
                      <JSEditProfileForm
                        errors={errors}
                        faculties={faculties}
                        setErrors={setErrors}
                        directories={directories}
                        profileBody={profileBody}
                        handleSubmit={handleSubmit}
                        setFaculties={setFaculties}
                        setProfileBody={setProfileBody}
                      />
                    )
                  }
                </div>
              )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default JSEditProfile;
