/* eslint-disable react/prop-types */
import React from 'react';
// components
import PersonalInfo from './components/PersonalInfo';
import ExperienceInfo from './components/ExperienceInfo';
import SkillsInfo from './components/SkillsInfo';
import EducationInfo from './components/EducationInfo';
import LanguagesInfo from './components/LanguagesInfo';
import AboutYourselfInfo from './components/AboutYourselfInfo';
import DriverLicenseInfo from './components/DriverLicenseInfo';

// import HTMLReactParser from 'html-react-parser';

const CVCard = ({ cvDetails }) => {
  const {
    languages = [],
    driver_license = '',
  } = cvDetails;

  return (
    <>
      <div className='CVcard'>
        <PersonalInfo cvDetails={cvDetails} />
        <ExperienceInfo cvDetails={cvDetails} />
        <SkillsInfo cvDetails={cvDetails} />
        <EducationInfo cvDetails={cvDetails} />
        {languages?.length > 0 && <LanguagesInfo cvDetails={cvDetails} />}
        <AboutYourselfInfo cvDetails={cvDetails} />
        {driver_license && <DriverLicenseInfo cvDetails={cvDetails} />}
      </div>
    </>
  );
};

export default CVCard;
