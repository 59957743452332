import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const getNameFromArray = (id, array) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i].id == id) {
      return array[i].name;
    }
  }
};

export const formatPhoneNumber = phoneNumber => {
  const phoneNumberParsed = parsePhoneNumberFromString(phoneNumber);

  if (phoneNumberParsed) {
    return phoneNumberParsed.formatInternational();
  }

  return phoneNumber;
};

export const resultInfo = (icon, title, confirmButtonText) => {
  Swal.fire({
    icon: icon,
    title: title,
    confirmButtonText: confirmButtonText,
  });
};

export const logOutIfTokenExpired = (title, confirmButtonText) => {
  Cookies.remove('fname');
  Cookies.remove('user_id');
  Cookies.remove('user_type');
  Cookies.remove('tokenClient');

  Swal.fire({
    title: title,
    icon: 'warning',
    confirmButtonText: confirmButtonText,
  }).then(result => {
    if (result.isConfirmed) {
      window.location.reload();
    }
  });
};

export const debounce = (func, delay) => {
  let timeoutId;

  return function(...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

export const debounceAsync = (func, delay) => {
  let timeoutId;

  return async function(...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    return new Promise((resolve, reject) => {
      timeoutId = setTimeout(async () => {
        try {
          const result = await func.apply(this, args);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      }, delay);
    });
  };
};
