/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
// mui
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const DriverLicenseInfo = ({ cvDetails }) => {
  const { t } = useTranslation('global');

  const { driver_category = [] } = cvDetails;

  return (
    <div className='driverLicenseSection'>
      <h4>
        {t('CVCard.driverLicense')}
      </h4>
      <p>
        {t('CVCard.haveDriverLicense')}
      </p>
      <div className='driverLicenseBox'>
        {
          driver_category?.map(i => (
            <p key={i.value}>
              <FiberManualRecordIcon sx={{ mr: '8px', width: '10px', height: '10px', color: '#294A9C' }} />
              {t('CVCard.driverLicenseCategory')}
              {' '}
              <span>
                {i.label}
              </span>
            </p>
          ))
        }
      </div>
    </div>
  );
};

export default DriverLicenseInfo;
