/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';

const SkillsInfo = ({ cvDetails }) => {
  const { t } = useTranslation('global');

  const { skills = [] } = cvDetails;

  return (
    <div className='skillsSection'>
      <h4>
        {t('CVCard.skills')}
      </h4>
      <div className='skillsBox'>
        {
          skills?.map(i => (
            <button key={i.value}>
              {i.label}
            </button>
          ))
        }
      </div>
    </div>
  );
};

export default SkillsInfo;
