/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
// helpers
import { getNameFromArray } from '../../../helpers';
// mui
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const months = [
  { 'id': 1, 'name': 'Січень' },
  { 'id': 2, 'name': 'Лютий' },
  { 'id': 3, 'name': 'Березень' },
  { 'id': 4, 'name': 'Квітень' },
  { 'id': 5, 'name': 'Травень' },
  { 'id': 6, 'name': 'Червень' },
  { 'id': 7, 'name': 'Липень' },
  { 'id': 8, 'name': 'Серпень' },
  { 'id': 9, 'name': 'Вересень' },
  { 'id': 10, 'name': 'Жовтень' },
  { 'id': 11, 'name': 'Листопад' },
  { 'id': 12, 'name': 'Грудень' },
];

const countries = [
  {
    'id': 7,
    'name': 'Австрія',
    'slug': 'austria',
  },
  {
    'id': 9,
    'name': 'Азербайджан',
    'slug': 'azerbaijan',
  },
  {
    'id': 2357,
    'name': 'Албанія',
    'slug': 'albania',
  },
  {
    'id': 6359,
    'name': 'Барбадос',
    'slug': 'barbados',
  },
  {
    'id': 18,
    'name': 'Бельгія',
    'slug': 'belgium',
  },
  {
    'id': 200,
    'name': 'Болгарія',
    'slug': 'bulgaria',
  },
  {
    'id': 2358,
    'name': 'Боснія і Герцеговина',
    'slug': 'bosniya-i-gercegovina',
  },
  {
    'id': 6329,
    'name': 'Бруней Даруссалам',
    'slug': 'brunei',
  },
  {
    'id': 21,
    'name': 'Великобританія',
    'slug': 'great-britain',
  },
  {
    'id': 13,
    'name': 'Вірменія',
    'slug': 'armenia',
  },
  {
    'id': 213,
    'name': 'Греція',
    'slug': 'greece',
  },
  {
    'id': 28,
    'name': 'Грузія',
    'slug': 'georgia',
  },
  {
    'id': 30,
    'name': 'Данія',
    'slug': 'denmark',
  },
  {
    'id': 6328,
    'name': 'Еритрея',
    'slug': 'eritrea',
  },
  {
    'id': 109,
    'name': 'Естонія',
    'slug': 'estonia',
  },
  {
    'id': 305,
    'name': 'Єгипет',
    'slug': 'egipet',
  },
  {
    'id': 6367,
    'name': 'Замбия',
    'slug': 'zambia',
  },
  {
    'id': 33,
    'name': 'Ізраїль',
    'slug': 'israel',
  },
  {
    'id': 36,
    'name': 'Ірландія',
    'slug': 'ireland',
  },
  {
    'id': 302,
    'name': 'Ісландія',
    'slug': 'iceland',
  },
  {
    'id': 37,
    'name': 'Іспанія',
    'slug': 'spain',
  },
  {
    'id': 38,
    'name': 'Італія',
    'slug': 'italy',
  },
  {
    'id': 40,
    'name': 'Казахстан',
    'slug': 'kazakhstan',
  },
  {
    'id': 45,
    'name': 'Канада',
    'slug': 'canada',
  },
  {
    'id': 48,
    'name': 'Киргизстан',
    'slug': 'kyrgyzstan',
  },
  {
    'id': 50,
    'name': 'Китай',
    'slug': 'china',
  },
  {
    'id': 236,
    'name': 'Кіпр',
    'slug': 'cyprus',
  },
  {
    'id': 6948,
    'name': 'Косово',
    'slug': 'kosovo',
  },
  {
    'id': 6323,
    'name': 'Лаос',
    'slug': 'laos',
  },
  {
    'id': 57,
    'name': 'Латвія',
    'slug': 'latvia',
  },
  {
    'id': 59,
    'name': 'Литва',
    'slug': 'lithuania',
  },
  {
    'id': 2355,
    'name': 'Ліхтенштейн',
    'slug': 'liechtenstein',
  },
  {
    'id': 206,
    'name': 'Люксембург',
    'slug': 'luxembourg',
  },
  {
    'id': 2359,
    'name': 'Македонія',
    'slug': 'macedonia',
  },
  {
    'id': 2353,
    'name': 'Мальта',
    'slug': 'malta',
  },
  {
    'id': 2229,
    'name': 'Мексика',
    'slug': 'mexico',
  },
  {
    'id': 62,
    'name': 'Молдова',
    'slug': 'moldova',
  },
  {
    'id': 2356,
    'name': 'Монако',
    'slug': 'monaco',
  },
  {
    'id': 2231,
    'name': 'Монголія',
    'slug': 'mongolia',
  },
  {
    'id': 65,
    'name': 'Нiдерланди',
    'slug': 'netherlands',
  },
  {
    'id': 6326,
    'name': 'Непал',
    'slug': 'nepal',
  },
  {
    'id': 27,
    'name': 'Німеччина',
    'slug': 'germany',
  },
  {
    'id': 207,
    'name': 'Норвегія',
    'slug': 'norway',
  },
  {
    'id': 208,
    'name': 'ОАЕ',
    'slug': 'uae',
  },
  {
    'id': 6058,
    'name': 'Об\'єднана Республіка Танзанія',
    'slug': 'united-republic-of-tanzania',
  },
  {
    'id': 211,
    'name': 'ПАР',
    'slug': 'south-africa',
  },
  {
    'id': 6327,
    'name': 'Парагвай',
    'slug': 'paraguay',
  },
  {
    'id': 74,
    'name': 'Польща',
    'slug': 'poland',
  },
  {
    'id': 241,
    'name': 'Португалія',
    'slug': 'portugal',
  },
  {
    'id': 6377,
    'name': 'Республіка Гаїті',
    'slug': 'haiti-republic',
  },
  {
    'id': 6356,
    'name': 'Республіка Ель-Сальвадор',
    'slug': 'republic-of-el-salvador',
  },
  {
    'id': 6337,
    'name': 'Республіка Малаві',
    'slug': 'malawi',
  },
  {
    'id': 6350,
    'name': 'Республіка Чад',
    'slug': 'republic-of-chad',
  },
  {
    'id': 234,
    'name': 'Румунія',
    'slug': 'romania',
  },
  {
    'id': 6962,
    'name': 'Сан-Марино',
    'slug': 'san-marino',
  },
  {
    'id': 146,
    'name': 'Сербія',
    'slug': 'serbia',
  },
  {
    'id': 318,
    'name': 'Словаччина',
    'slug': 'slovakiya',
  },
  {
    'id': 2105,
    'name': 'Словенія',
    'slug': 'slovenia',
  },
  {
    'id': 85,
    'name': 'США',
    'slug': 'usa',
  },
  {
    'id': 86,
    'name': 'Таджикистан',
    'slug': 'tajikistan',
  },
  {
    'id': 6330,
    'name': 'Тоголезська Республіка',
    'slug': 'togo',
  },
  {
    'id': 94,
    'name': 'Туреччина',
    'slug': 'turkey',
  },
  {
    'id': 93,
    'name': 'Туркменістан',
    'slug': 'turkmenistan',
  },
  {
    'id': 114,
    'name': 'Угорщина',
    'slug': 'hungary',
  },
  {
    'id': 97,
    'name': 'Узбекистан',
    'slug': 'uzbekistan',
  },
  {
    'id': 5,
    'name': 'Україна',
    'slug': 'ukraine',
  },
  {
    'id': 100,
    'name': 'Фінляндія',
    'slug': 'finland',
  },
  {
    'id': 101,
    'name': 'Франція',
    'slug': 'france',
  },
  {
    'id': 2103,
    'name': 'Хорватія',
    'slug': 'croatia',
  },
  {
    'id': 6448,
    'name': 'Центральноафриканська Республіка',
    'slug': 'central-afrikan-republic',
  },
  {
    'id': 199,
    'name': 'Чехія',
    'slug': 'czech-republic',
  },
  {
    'id': 5046,
    'name': 'Чорногорія',
    'slug': 'montenegro',
  },
  {
    'id': 108,
    'name': 'Швейцарія',
    'slug': 'switzerland',
  },
  {
    'id': 149,
    'name': 'Швеція',
    'slug': 'sweden',
  },
];

const ExperienceInfo = ({ cvDetails }) => {
  const { t } = useTranslation('global');

  const { experiences = [] } = cvDetails;

  return (
    <div className='experienceInfoSection'>
      <h4>
        {t('CVCard.experience')}
      </h4>
      <div className='experienceInfoBox'>
        {
          experiences?.map(i => (
            <div key={i.id}>
              <h5>
                {i.position.label}
              </h5>
              <h6>
                {i.organization.label}
              </h6>
              <div className='industryBox'>
                {
                  i.industry?.map(industry => (
                    <p key={industry.id}>
                      <FiberManualRecordIcon sx={{ mr: '8px', width: '10px', height: '10px', color: '#294A9C' }} />
                      {industry.label}
                    </p>
                  ))
                }
              </div>
              <div className='workDurationBox'>
                <p className='workDuration'>
                  <i className='fa-solid fa-calendar-days' />
                  {getNameFromArray(i.from_month_exp, months)}{' '}{i.from_year_exp}{' - '}
                  {
                    i.still_working
                      ? t('CVCard.stillWorking')
                      : `${getNameFromArray(i.to_month_exp, months)} ${i.to_year_exp}`
                  }
                </p>
                <p className='workDuration'>
                  <i className='fa-sharp fa-solid fa-location-dot' />
                  {i.exp_city && `${i.exp_city.label}, `}
                  {`${getNameFromArray(i.exp_country, countries)}`}
                </p>
              </div>
              <p className='achievements'>
                {t('CVCard.achievements')}
              </p>
              <div className='standartListStyle'>
                {HTMLReactParser(i.achievements)}
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default ExperienceInfo;
