/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const ContactInformationBlock = ({ errors, setErrors, directories, profileBody, handleChange, setProfileBody }) => {
  const { t } = useTranslation('global');

  const { socials = [], messengers = [] } = directories;

  const {
    social_links = [],
    email_address = '',
    video_cv_link = '',
    contact_number = '',
    messenger_links = [],
    contact_number_2 = '',
    contact_number_3 = '',
  } = profileBody;

  const handleFocus = (e, index = null, type = null) => {
    const { name, value } = e.target;

    if (value) {
      return;
    }

    if (['contact_number', 'contact_number_2', 'contact_number_3'].includes(name)) {
      setProfileBody(prevState => ({
        ...prevState,
        [name]: '+380',
      }));
    } else if (['WhatsApp', 'Viber', 'Signal'].includes(type) && index !== null) {
      const newMessengerLinks = [...messenger_links];
      newMessengerLinks[index]['link'] = '+380';

      setProfileBody(prevState => ({
        ...prevState,
        messenger_links: newMessengerLinks,
      }));
    }
  };

  const handleBlur = (e, index = null, type = null) => {
    const { name, value } = e.target;

    setProfileBody(prevState => {
      const updatedValue = value === '+380' ? '' : value;

      if (['WhatsApp', 'Viber', 'Signal'].includes(type)) {
        const newMessengerLinks = [...prevState.messenger_links];
        newMessengerLinks[index]['link'] = updatedValue;

        return {
          ...prevState,
          messenger_links: newMessengerLinks,
        };
      } else if (['Telegram', 'Skype', 'Discord'].includes(type)) {
        const newMessengerLinks = [...prevState.messenger_links];
        newMessengerLinks[index]['link'] = value;

        return {
          ...prevState,
          messenger_links: newMessengerLinks,
        };
      }

      return {
        ...prevState,
        [name]: updatedValue,
      };
    });
  };

  const handleAddLink = linkType => {
    const newLink = {
      link: '',
      type: '',
    };

    setProfileBody(prevState => ({
      ...prevState,
      [linkType]: [...prevState[linkType], newLink],
    }));
  };

  const handleChangeLink = (linkType, index, field, value) => {
    const newLinks = [...profileBody[linkType]];

    newLinks[index][field] = value;

    setProfileBody({
      ...profileBody,
      [linkType]: newLinks,
    });

    const newErrors = { ...errors };

    if (newErrors[linkType] && newErrors[linkType][index] && newErrors[linkType][index][field]) {
      delete newErrors[linkType][index][field];

      if (Object.keys(newErrors[linkType][index]).length === 0) {
        delete newErrors[linkType][index];
      }
    }

    setErrors(newErrors);
  };

  const handleRemoveLink = (linkType, index) => {
    setProfileBody(prevState => ({
      ...prevState,
      [linkType]: prevState[linkType].filter((_, i) => i !== index),
    }));

    setErrors(prevState => ({
      ...prevState,
      [linkType]: prevState[linkType].filter((_, i) => i !== index),
    }));
  };

  return (
    <>
      <h3 className='mb-4'>{t('jobseekerEditProfile.contactInformation')}</h3>
      <div className='form-outline mb-4 DashBoardInputBx'>
        <label id='email_address' className='form-label' htmlFor='email_address'>
          {t('jobseekerEditProfile.emailAddress')}
          <span className='RedStar'>*</span>
        </label>
        <input
          disabled
          type='text'
          name='email_address'
          value={email_address}
          onChange={handleChange}
          placeholder={t('jobseekerEditProfile.emailAddress')}
          className={`form-control ${errors.email_address && 'input-error'}`}
        />
        {
          errors.email_address && (
            <div className='text-danger'>{errors.email_address}</div>
          )
        }
      </div>
      <div className='row mb-4'>
        <div className='col-lg-4 form-outline mb-4 mb-lg-0 DashBoardInputBx DashBoardCreatBx'>
          <label id='contact_number' className='form-label' htmlFor='contact_number'>
            {t('jobseekerEditProfile.contactNumber')}
            <span className='RedStar'>*</span>
          </label>
          <input
            type='tel'
            onBlur={handleBlur}
            name='contact_number'
            onFocus={handleFocus}
            value={contact_number}
            onChange={handleChange}
            placeholder={t('jobseekerEditProfile.contactNumberPlaceholder')}
            className={`form-control ${errors.contact_number && 'input-error'}`}
          />
        </div>
        <div className='col-lg-4 form-outline mb-4 mb-lg-0 DashBoardInputBx DashBoardCreatBx'>
          <label id='contact_number_2' className='form-label' htmlFor='contact_number_2'>
            {t('jobseekerEditProfile.contactNumber')}
          </label>
          <input
            type='tel'
            onBlur={handleBlur}
            onFocus={handleFocus}
            name='contact_number_2'
            onChange={handleChange}
            value={contact_number_2}
            placeholder={t('jobseekerEditProfile.contactNumberPlaceholder')}
            className={`form-control ${errors.contact_number_2 && 'input-error'}`}
          />
        </div>
        <div className='col-lg-4 form-outline DashBoardInputBx DashBoardCreatBx'>
          <label id='contact_number_3' className='form-label' htmlFor='contact_number_3'>
            {t('jobseekerEditProfile.contactNumber')}
          </label>
          <input
            type='tel'
            onBlur={handleBlur}
            onFocus={handleFocus}
            name='contact_number_3'
            onChange={handleChange}
            value={contact_number_3}
            placeholder={t('jobseekerEditProfile.contactNumberPlaceholder')}
            className={`form-control ${errors.contact_number_3 && 'input-error'}`}
          />
        </div>
        {
          (errors.contact_number || errors.contact_number_2 || errors.contact_number_3) && (
            <div className='text-danger'>
              {errors.contact_number || errors.contact_number_2 || errors.contact_number_3}
            </div>
          )
        }
      </div>
      <div className='mt-4 mb-4'>
        {
          messenger_links.map((i, index) => {
            const selectedMessengers = messenger_links.map(link => link.type);
            const availableMessengers = messengers.filter(
              social => !selectedMessengers.includes(social) || social === i.type,
            );

            const inputType = i.type === 'Telegram' || i.type === 'Discord' || i.type === 'Skype'  ? 'text' : 'tel';

            const label = !i.type || i.type === 'Telegram'
              ? t('jobseekerEditProfile.messengerLink')
              : i.type === 'Discord' || i.type === 'Skype'
                ? t('jobseekerEditProfile.messengerNickname')
                : t('jobseekerEditProfile.messengerContactNumber');

            const placeholder = !i.type || i.type === 'Telegram'
              ? t('jobseekerEditProfile.messengerLink')
              : i.type === 'Discord' || i.type === 'Skype'
                ? t('jobseekerEditProfile.messengerNickname')
                : t('jobseekerEditProfile.contactNumberPlaceholder');

            return (
              <div className='row' key={index}>
                <div
                  className={classNames(
                    'mb-4 col-lg-3 form-outline DashBoardInputBx DashBoardCreatBx',
                    { 'mb-lg-0': messenger_links[index]?.type === 'Telegram' },
                  )}
                >
                  <label id={`messenger_type_${index}`} className='form-label' htmlFor={`messenger_type_${index}`}>
                    {t('jobseekerEditProfile.messenger')}
                    {
                      (messenger_links[index]?.link || messenger_links[index]?.type) && (
                        <span className='RedStar'>*</span>
                      )
                    }
                  </label>
                  <select
                    value={i.type}
                    name={`messenger_type_${index}`}
                    aria-label='Default select example'
                    className={`form-select ${errors.messenger_links[index]?.type && 'input-error'}`}
                    onChange={e => handleChangeLink('messenger_links', index, 'type', e.target.value)}
                  >
                    <option value=''>
                      {t('jobseekerEditProfile.messenger')}
                    </option>
                    {
                      availableMessengers.map(social => (
                        <option key={social} value={social}>
                          {social}
                        </option>
                      ))
                    }
                  </select>
                  {
                    errors.messenger_links[index]?.type && (
                      <div className='text-danger'>{errors.messenger_links[index].type}</div>
                    )
                  }
                </div>
                <div
                  className={classNames(
                    'mb-4 col-lg-6 form-outline DashBoardInputBx DashBoardCreatBx',
                    { 'mb-lg-0': messenger_links[index]?.type === 'Telegram' },
                  )}
                >
                  <label id={`messenger_link_${index}`} className='form-label' htmlFor={`messenger_link_${index}`}>
                    {label}
                    {(messenger_links[index]?.link || messenger_links[index]?.type) && (<span className='RedStar'>*</span>)}
                  </label>
                  <input
                    value={i.link}
                    type={inputType}
                    placeholder={placeholder}
                    name={`messenger_link_${index}`}
                    onBlur={e => handleBlur(e, index, i.type)}
                    onFocus={e => handleFocus(e, index, i.type)}
                    className={`form-control ${errors.messenger_links[index]?.link && 'input-error'}`}
                    onChange={e => handleChangeLink('messenger_links', index, 'link', e.target.value)}
                  />
                  {
                    errors.messenger_links[index]?.link && (
                      <div className='text-danger'>{errors.messenger_links[index].link}</div>
                    )
                  }
                </div>
                <div
                  className={classNames(
                    'mb-4 col-lg-3 text-center',
                    { 'mb-lg-0': messenger_links[index]?.type === 'Telegram' },
                  )}
                >
                  {
                    index === messenger_links.length - 1 && availableMessengers.length > 1 && messenger_links.length < messengers.length
                      ? (
                        <button
                          type='button'
                          className='btn btn-primary button1 mt-lg-2'
                          onClick={() => handleAddLink('messenger_links')}
                        >
                          {t('jobseekerEditProfile.addMoreButton')}
                        </button>
                      ) : (
                        <button
                          type='button'
                          className='btn btn-danger button2 mt-lg-2'
                          onClick={() => handleRemoveLink('messenger_links', index)}
                        >
                          {t('jobseekerEditProfile.removeButton')}
                        </button>
                      )
                  }
                </div>
                {
                  messenger_links[index]?.type === 'Telegram' && (
                    <div className={`${index < messenger_links.length - 1 ? 'mb-4' : ''} form-text mt-0`}>
                      {t('jobseekerEditProfile.messengerLinkTelegramBelowTxt')}
                    </div>
                  )
                }
              </div>
            );
          })
        }
      </div>
      <hr />
      <div className='mt-5'>
        {
          social_links.map((i, index) => {
            const selectedSocials = social_links.map(link => link.type);
            const availableSocials = socials.filter(
              social => !selectedSocials.includes(social) || social === i.type,
            );

            return (
              <div className='row' key={index}>
                <div className='col-lg-3 mb-4 form-outline DashBoardInputBx DashBoardCreatBx'>
                  <label id='social_type' className='form-label' htmlFor='social_type'>
                    {t('jobseekerEditProfile.social')}
                    {(social_links[index]?.link || social_links[index]?.type) && <span className='RedStar'>*</span>}
                  </label>
                  <select
                    value={i.type}
                    name='social_type'
                    aria-label='Default select example'
                    className={`form-select ${errors.social_links[index]?.type && 'input-error'}`}
                    onChange={e => handleChangeLink('social_links', index, 'type', e.target.value)}
                  >
                    <option value=''>
                      {t('jobseekerEditProfile.social')}
                    </option>
                    {
                      availableSocials.map(social => (
                        <option key={social} value={social}>
                          {social}
                        </option>
                      ))
                    }
                  </select>
                  {
                    errors.social_links[index]?.type && (
                      <div className='text-danger'>{errors.social_links[index].type}</div>
                    )
                  }
                </div>
                <div className='col-lg-6 mb-4 form-outline DashBoardInputBx DashBoardCreatBx'>
                  <label id={`social_link_${index}`} className='form-label' htmlFor={`social_link_${index}`}>
                    {t('jobseekerEditProfile.socialLink')}
                    {(social_links[index]?.link || social_links[index]?.type) && <span className='RedStar'>*</span>}
                  </label>
                  <input
                    type='text'
                    value={i.link}
                    name={`social_link_${index}`}
                    placeholder={t('jobseekerEditProfile.socialLink')}
                    className={`form-control ${errors.social_links[index]?.link && 'input-error'}`}
                    onChange={e => handleChangeLink('social_links', index, 'link', e.target.value)}
                  />
                  {
                    errors.social_links[index]?.link && (
                      <div className='text-danger'>{errors.social_links[index].link}</div>
                    )
                  }
                </div>
                <div className='col-lg-3 mb-4 text-center'>
                  {
                    index === social_links.length - 1 && availableSocials.length > 1 && social_links.length < socials.length
                      ? (
                        <button
                          type='button'
                          className='btn btn-primary button1 mt-lg-2'
                          onClick={() => handleAddLink('social_links')}
                        >
                          {t('jobseekerEditProfile.addMoreButton')}
                        </button>
                      ) : (
                        <button
                          type='button'
                          className='btn btn-danger button2 mt-lg-2'
                          onClick={() => handleRemoveLink('social_links', index)}
                        >
                          {t('jobseekerEditProfile.removeButton')}
                        </button>
                      )
                  }
                </div>
              </div>
            );
          })
        }
      </div>
      <div className='form-outline mb-5 DashBoardInputBx'>
        <label id='video_cv_link' className='form-label' htmlFor='video_cv_link'>
          {t('jobseekerEditProfile.videoCV')}
        </label>
        <input
          type='text'
          name='video_cv_link'
          value={video_cv_link}
          onChange={e => handleChange(e)}
          placeholder={t('jobseekerEditProfile.addLink')}
          className={`form-control ${errors.video_cv_link && 'input-error'}`}
        />
        {
          errors.video_cv_link && (
            <div className='text-danger'>{errors.video_cv_link}</div>
          )
        }
        <div id='emailHelp' className='form-text'>
          {t('jobseekerEditProfile.videoCVBelowTxt')}
        </div>
      </div>
    </>
  );
};

export default ContactInformationBlock;
