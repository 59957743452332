/* eslint-disable react/prop-types */
import axios from 'axios';
import React from 'react';
import Cookies from 'js-cookie';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';
// api
import ApiKey from '../../../api/ApiKey';
import BaseApi from '../../../api/BaseApi';
// helpers
import { debounceAsync, selectStyles } from '../../../helpers';

const EducationInfoBlock = ({ errors, faculties, setErrors, directories, profileBody, handleChange, setFaculties, setProfileBody }) => {
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');

  const { educations = [], additional_educations = [] } = profileBody;
  const { months = [], yearList = [], education_level = [] } = directories;

  const getInstitutionOptions = async (inputValue, index) => {
    if (inputValue.length < 2) {
      return [];
    }

    try {
      const response = await axios.post(
        BaseApi + '/candidates/getInstitutions',
        {
          query: inputValue,
          education_level: educations[index]?.edu_type,
        },
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.data.response?.length === 0) {
        throw new Error('No options found');
      }

      const options = response.data.response.map(position => ({
        value: position.id,
        label: position.name,
      }));

      return options;
    } catch (error) {
      console.error('Error fetching cities:', error);
      return [];
    }
  };

  const getFacultyOptions = async (id, index) => {
    if (!id) {
      setFaculties(prevState => {
        const newFaculties = [...prevState];
        newFaculties[index] = [];

        return newFaculties;
      });

      setProfileBody(prevState => {
        const newEducations = [...prevState.educations];
        newEducations[index].faculty = '';

        return {
          ...prevState,
          educations: newEducations,
        };
      });

      setErrors(prev => {
        const newErrors = { ...prev };

        if (!newErrors.educations[index]) {
          newErrors.educations[index] = {};
        }

        newErrors.educations[index].faculty = '';

        return newErrors;
      });

      return;
    }

    setFaculties(prevState => {
      const newFaculties = [...prevState];
      newFaculties[index] = [];

      return newFaculties;
    });

    setProfileBody(prevState => {
      const newEducations = [...prevState.educations];
      newEducations[index].faculty = '';

      return {
        ...prevState,
        educations: newEducations,
      };
    });

    setErrors(prev => {
      const newErrors = { ...prev };

      if (!newErrors.educations[index]) {
        newErrors.educations[index] = {};
      }

      newErrors.educations[index].faculty = '';

      return newErrors;
    });

    try {
      const positions = await axios.post(
        BaseApi + '/candidates/getFaculties',
        { institution_id: id },
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      const options = positions.data.response.map(position => ({
        value: position.id,
        label: position.name,
      }));

      setFaculties(prevState => {
        const newFaculties = [...prevState];
        newFaculties[index] = options;

        return newFaculties;
      });
    } catch (error) {
      console.error('Error fetching faculties:', error);
      return [];
    }
  };

  const getSpecialtyOptions = async (inputValue, index) => {
    if (inputValue.length < 2) {
      return [];
    }

    try {
      const response = await axios.post(
        BaseApi + '/candidates/getSpecialties',
        {
          query: inputValue,
          institution_id: educations[index]?.institution.value,
        },
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.data.response?.length === 0) {
        throw new Error('No options found');
      }

      const options = response.data.response.map(position => ({
        value: position.id,
        label: position.name,
      }));

      return options;
    } catch (error) {
      console.error('Error fetching cities:', error);
      return [];
    }
  };

  const handleAddItem = type => {
    const newItem = type === 'educations'
      ? {
        faculty: '',
        edu_type: '',
        specialty: '',
        institution: '',
        to_year_edu: '',
        to_month_edu: '',
        from_year_edu: '',
        still_learning: 0,
        from_month_edu: '',
      }
      : {
        certificate: '',
        course_link: '',
        course_name: '',
      };

    setProfileBody(prevState => ({
      ...prevState,
      [type]: [...prevState[type], newItem],
    }));
  };

  const handleFieldChange = (field, selectedOption, index) => {
    setProfileBody(prevState => {
      const newEducations = [...prevState.educations];
      newEducations[index][field] = selectedOption;

      return {
        ...prevState,
        educations: newEducations,
      };
    });

    setErrors(prev => {
      const newErrors = { ...prev };

      if (!newErrors.educations[index]) {
        newErrors.educations[index] = {};
      }

      newErrors.educations[index][field] = '';

      return newErrors;
    });
  };

  const handleRemoveItem = (type, index) => {
    if (type === 'educations') {
      setFaculties(prevState => prevState.filter((_, i) => i !== index));
    }

    setProfileBody(prevState => ({
      ...prevState,
      [type]: prevState[type].filter((_, i) => i !== index),
    }));

    setErrors(prevState => ({
      ...prevState,
      [type]: prevState[type].filter((_, i) => i !== index),
    }));
  };

  // const handleRemove = async id => {
  //   try {
  //     const confirmationResult = await Swal.fire({
  //       icon: 'question',
  //       showCancelButton: true,
  //       cancelButtonText: t('jobseekerEducation.no'),
  //       confirmButtonText: t('jobseekerEducation.yes'),
  //       text: t('jobseekerEducation.removeConfirmTxt'),
  //       title: t('jobseekerEducation.removeConfirmTitle'),
  //     });

  //     if (confirmationResult.isConfirmed) {
  //       const response = await axios.post(
  //         BaseApi + `/candidates/deleteeducation/${id}`,
  //         null,
  //         {
  //           headers: {
  //             key: ApiKey,
  //             token: tokenKey,
  //             'Content-Type': 'application/json',
  //           },
  //         },
  //       );

  //       if (response.data.status === 200) {
  //         getData();

  //         resultInfo('success', t('jobseekerEducation.removeSuccessTitle'), t('jobseekerEducation.close'));
  //       } else if (response.data.status === 400) {
  //         navigate('/');

  //         Cookies.remove('fname');
  //         Cookies.remove('user_id');
  //         Cookies.remove('user_type');
  //         Cookies.remove('tokenClient');

  //         resultInfo('warning', response.data.message, t('jobseekerEducation.close'));
  //       } else {
  //         resultInfo('error', response.data.message, t('jobseekerEducation.close'));
  //       }
  //     }
  //   } catch (error) {
  //     setLoading(false);

  //     if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
  //       navigate('/');

  //       logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
  //     } else {
  //       Swal.fire({
  //         icon: 'error',
  //         text: t('jobseekerEducation.removeFailedTxt'),
  //         confirmButtonText: t('jobseekerEducation.close'),
  //         title: t('jobseekerEducation.removeFailedTitle'),
  //       });
  //     }

  //     console.error(error);
  //   }
  // };

  const handleFileUpload = async (e, index) => {
    const file = e.target.files[0];

    if (!file)  return;

    const base64 = await convertToBase64(file);

    if (file.size >= 5 * 1024 * 1024) {
      setProfileBody(prevState => {
        const updatedEducations = [...prevState.additional_educations];

        updatedEducations[index] = {
          ...updatedEducations[index],
          certificate: base64,
        };

        return {
          ...prevState,
          additional_educations: updatedEducations,
        };
      });

      setErrors(prev => {
        const newErrors = { ...prev };

        if (!newErrors.additional_educations[index]) {
          newErrors.additional_educations[index] = {};
        }

        newErrors.additional_educations[index].certificate = t('jobseekerEditProfile.maxFileSize');

        return newErrors;
      });

      return;
    } else {
      setProfileBody(prevState => {
        const updatedEducations = [...prevState.additional_educations];

        updatedEducations[index] = {
          ...updatedEducations[index],
          certificate: base64,
        };

        return {
          ...prevState,
          additional_educations: updatedEducations,
        };
      });

      setErrors(prev => {
        const newErrors = { ...prev };

        if (!newErrors.additional_educations[index]) {
          newErrors.additional_educations[index] = {};
        }

        newErrors.additional_educations[index].certificate = '';

        return newErrors;
      });
    }
  };

  const handleFileRemove = index => {
    const fileInput = document.querySelector(`[name="certificate_${index}"]`);

    if (fileInput) {
      fileInput.value = '';
    }

    setProfileBody(prevState => {
      const updatedEducations = [...prevState.additional_educations];

      updatedEducations[index] = {
        ...updatedEducations[index],
        certificate: '',
      };

      return {
        ...prevState,
        additional_educations: updatedEducations,
      };
    });

    setErrors(prev => {
      const newErrors = { ...prev };

      if (newErrors.additional_educations[index]) {
        newErrors.additional_educations[index].certificate = '';
      }

      return newErrors;
    });
  };

  const debouncedGetSpecialtyOptions = debounceAsync(getSpecialtyOptions, 500);
  const debouncedGetInstitutionOptions = debounceAsync(getInstitutionOptions, 500);

  return (
    <>
      <h3 className='mb-4'>{t('jobseekerEditProfile.educationInfo')}</h3>
      <div className='mt-4 mb-4'>
        {
          educations.map((i, index) => (
            <>
              <div className={`${index === 0 ? 'mt-4' : 'mt-5'} mb-4 form-outline DashBoardInputBx`}>
                <label id={`edu_type_${index}`} className='form-label' htmlFor={`edu_type_${index}`}>
                  {t('jobseekerEditProfile.educationalDegree')}
                  <span className='RedStar'>*</span>
                </label>
                <select
                  value={i.edu_type}
                  name={`edu_type_${index}`}
                  aria-label='Default select example'
                  onChange={e => handleChange(e, index)}
                  className={`form-select ${errors.educations[index]?.edu_type && 'input-error'}`}
                >
                  <option selected value=''>
                    {t('jobseekerEditProfile.selectEducationalDegree')}
                  </option>
                  {
                    education_level.map(({ name, value }) => (
                      <option key={value} value={value}>
                        {name}
                      </option>
                    ))
                  }
                </select>
                {
                  errors.educations[index]?.edu_type && (
                    <div className='text-danger'>{errors.educations[index]?.edu_type}</div>
                  )
                }
              </div>
              <div className='form-outline mb-4 DashBoardInputBx'>
                <label id={`institution_${index}`} className='form-label' htmlFor={`institution_${index}`}>
                  {t('jobseekerEditProfile.institution')}
                  <span className='RedStar'>*</span>
                </label>
                <AsyncSelect
                  defaultOptions
                  styles={selectStyles}
                  value={i.institution}
                  name={`institution_${index}`}
                  loadingMessage={() => t('jobseekerEditProfile.pleaseWait')}
                  placeholder={t('jobseekerEditProfile.enterInstitutionName')}
                  loadOptions={value => debouncedGetInstitutionOptions(value, index)}
                  className={`${errors.educations[index]?.institution && 'input-error-react-select'}`}
                  noOptionsMessage={
                    ({ inputValue }) => inputValue.length >= 2
                      ? t('jobseekerEditProfile.noOptionsFound')
                      : t('jobseekerEditProfile.enterInstitutionName')
                  }
                  onChange={
                    selectedOption => {
                      getFacultyOptions(selectedOption.value, index);
                      handleFieldChange('institution', selectedOption, index);
                    }
                  }
                />
                {
                  errors.educations[index]?.institution && (
                    <div className='text-danger'>{errors.educations[index]?.institution}</div>
                  )
                }
              </div>
              {
                (faculties[index]?.length > 0 || i.faculty) && (
                  <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
                    <label id={`faculty_${index}`} className='form-label' htmlFor={`faculty_${index}`}>
                      {t('jobseekerEditProfile.faculty')}
                      <span className='RedStar'>*</span>
                    </label>
                    <Select
                      isSearchable
                      value={i.faculty}
                      styles={selectStyles}
                      name={`faculty_${index}`}
                      options={faculties[index]?.map(i => (i))}
                      placeholder={t('jobseekerEditProfile.selectFaculty')}
                      className={`${errors.educations[index]?.faculty && 'input-error-react-select'}`}
                      onChange={selectedOption => handleFieldChange('faculty', selectedOption, index)}
                      noOptionsMessage={
                        () => i.institution
                          ? t('jobseekerEditProfile.noOptionsFound')
                          : t('jobseekerEditProfile.selectInstitutionFirst')
                      }
                    />
                    {
                      errors.educations[index]?.faculty && (
                        <div className='text-danger'>{errors.educations[index]?.faculty}</div>
                      )
                    }
                  </div>
                )
              }
              {
                (i.institution && i.edu_type !== '7') && (
                  <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
                    <label id={`specialty_${index}`} className='form-label' htmlFor={`specialty_${index}`}>
                      {t('jobseekerEditProfile.specialty')}
                      <span className='RedStar'>*</span>
                    </label>
                    <AsyncSelect
                      defaultOptions
                      value={i.specialty}
                      styles={selectStyles}
                      name={`specialty_${index}`}
                      placeholder={t('jobseekerEditProfile.enterSpecialtyName')}
                      loadingMessage={() => t('jobseekerEditProfile.pleaseWait')}
                      loadOptions={value => debouncedGetSpecialtyOptions(value, index)}
                      className={`${errors.educations[index]?.specialty && 'input-error-react-select'}`}
                      onChange={selectedOption => handleFieldChange('specialty', selectedOption, index)}
                      noOptionsMessage={
                        ({ inputValue }) => inputValue.length >= 2
                          ? t('jobseekerEditProfile.noOptionsFound')
                          : t('jobseekerEditProfile.enterSpecialtyName')
                      }
                    />
                    {
                      errors.educations[index]?.specialty && (
                        <div className='text-danger'>{errors.educations[index]?.specialty}</div>
                      )
                    }
                  </div>
                )
              }
              <div className='form-outline mb-4 DashBoardInputBx'>
                <div className='row'>
                  <div className='col-lg-6'>
                    <p className='mb-4'>
                      {t('jobseekerEditProfile.startOfEducation')}
                    </p>
                    <div className='row'>
                      <div className='col-lg-6 form-outline mb-4 DashBoardInputBx'>
                        <label id={`from_month_edu_${index}`} className='form-label' htmlFor={`from_month_edu_${index}`}>
                          {t('jobseekerEditProfile.month')}
                          <span className='RedStar'>*</span>
                        </label>
                        <select
                          value={i.from_month_edu}
                          name={`from_month_edu_${index}`}
                          aria-label='Default select example'
                          onChange={e => handleChange(e, index)}
                          className={`form-select ${errors.educations[index]?.from_month_edu && 'input-error'}`}
                        >
                          <option selected value=''>
                            {t('jobseekerEditProfile.selectMonth')}
                          </option>
                          {
                            months.map(i => (
                              <option value={i.id} key={i.id}>
                                {i.name}
                              </option>
                            ))
                          }
                        </select>
                        {
                          errors.educations[index]?.from_month_edu && (
                            <div className='text-danger'>{errors.educations[index]?.from_month_edu}</div>
                          )
                        }
                      </div>
                      <div className='col-lg-6 form-outline mb-4 DashBoardInputBx'>
                        <label id={`from_year_edu_${index}`} className='form-label' htmlFor={`from_year_edu_${index}`}>
                          {t('jobseekerEditProfile.year')}
                          <span className='RedStar'>*</span>
                        </label>
                        <select
                          value={i.from_year_edu}
                          name={`from_year_edu_${index}`}
                          aria-label='Default select example'
                          onChange={e => handleChange(e, index)}
                          className={`form-select ${errors.educations[index]?.from_year_edu && 'input-error'}`}
                        >
                          <option selected value=''>
                            {t('jobseekerEditProfile.selectYear')}
                          </option>
                          {
                            Object.entries(yearList).map(([index, value]) => (
                              <option key={index} value={value}>{value}</option>
                            ))
                          }
                        </select>
                        {
                          errors.educations[index]?.from_year_edu && (
                            <div className='text-danger'>{errors.educations[index]?.from_year_edu}</div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <p className='mb-4'>
                      {t('jobseekerEditProfile.endOfEducation')}
                    </p>
                    <div className='row'>
                      <div className='col-lg-6 form-outline mb-4 DashBoardInputBx'>
                        <label id={`to_month_edu_${index}`} className='form-label' htmlFor={`to_month_edu_${index}`}>
                          {t('jobseekerEditProfile.month')}
                          {!i.still_learning && <span className='RedStar'>*</span>}
                        </label>
                        <select
                          value={i.to_month_edu}
                          name={`to_month_edu_${index}`}
                          aria-label='Default select example'
                          onChange={e => handleChange(e, index)}
                          className={`form-select ${errors.educations[index]?.to_month_edu && 'input-error'}`}
                        >
                          <option selected value=''>
                            {t('jobseekerEditProfile.selectMonth')}
                          </option>
                          {
                            months.map(i => (
                              <option value={i.id} key={i.id}>
                                {i.name}
                              </option>
                            ))
                          }
                        </select>
                        {
                          errors.educations[index]?.to_month_edu && (
                            <div className='text-danger'>{errors.educations[index]?.to_month_edu}</div>
                          )
                        }
                      </div>
                      <div className='col-lg-6 form-outline mb-4 DashBoardInputBx'>
                        <label id={`to_year_edu_${index}`} className='form-label' htmlFor={`to_year_edu_${index}`}>
                          {t('jobseekerEditProfile.year')}
                          {!i.still_learning && <span className='RedStar'>*</span>}
                        </label>
                        <select
                          value={i.to_year_edu}
                          name={`to_year_edu_${index}`}
                          aria-label='Default select example'
                          onChange={e => handleChange(e, index)}
                          className={`form-select ${errors.educations[index]?.to_year_edu && 'input-error'}`}
                        >
                          <option selected value=''>
                            {t('jobseekerEditProfile.selectYear')}
                          </option>
                          {
                            Object.entries(yearList).map(([index, value]) => (
                              <option key={index} value={value}>{value}</option>
                            ))
                          }
                        </select>
                        {
                          errors.educations[index]?.to_year_edu && (
                            <div className='text-danger'>{errors.educations[index]?.to_year_edu}</div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                  <label className='mb-4' style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                      type='checkbox'
                      name='still_learning'
                      className='me-2 checkboxInput'
                      onChange={e => handleChange(e, index)}
                      checked={educations[index].still_learning}
                    />
                    {t('jobseekerEditProfile.stillLearning')}
                  </label>
                </div>
                <div className={`${educations.length > 1 && index < educations.length - 1 && 'mb-5'} row`}>
                  <div className='removeButtonJobseeker'>
                    <button
                      type='button'
                      disabled={educations.length === 1}
                      className='btn btn-primary button2'
                      onClick={() => handleRemoveItem('educations', index)}
                    >
                      {t('jobseekerEditProfile.removeButton')}
                    </button>
                    <button
                      type='button'
                      className='btn btn-primary button1'
                      onClick={() => handleAddItem('educations')}
                    >
                      {t('jobseekerEditProfile.addMoreButton')}
                    </button>
                  </div>
                </div>
              </div>
              {
                educations.length > 1 && index < educations.length - 1 && <hr />
              }
            </>
          ))
        }
      </div>
      <h4 className='mb-4'>{t('jobseekerEditProfile.additionalEducation')}</h4>
      <div className='mb-5'>
        {
          additional_educations.map((i, index) => (
            <>
              <div className={`${index === 0 ? 'mt-4' : 'mt-5'} mb-4 form-outline DashBoardInputBx`}>
                <label id={`course_name_${index}`} className='form-label' htmlFor={`course_name_${index}`}>
                  {t('jobseekerEditProfile.courseName')}
                </label>
                <input
                  type='text'
                  value={i.course_name}
                  name={`course_name_${index}`}
                  onChange={e => handleChange(e, index)}
                  placeholder={t('jobseekerEditProfile.courseName')}
                  className={`form-control ${errors.additional_educations[index]?.course_name && 'input-error'}`}
                />
                {
                  errors.additional_educations[index]?.course_name && (
                    <div className='text-danger'>{errors.additional_educations[index]?.course_name}</div>
                  )
                }
              </div>
              <div className='form-outline mb-4 DashBoardInputBx'>
                <label id={`course_link_${index}`} className='form-label' htmlFor={`course_link_${index}`}>
                  {t('jobseekerEditProfile.courseLink')}
                </label>
                <input
                  type='text'
                  value={i.course_link}
                  name={`course_link_${index}`}
                  onChange={e => handleChange(e, index)}
                  placeholder={t('jobseekerEditProfile.courseLink')}
                  className={`form-control ${errors.additional_educations[index]?.course_link && 'input-error'}`}
                />
                {
                  errors.additional_educations[index]?.course_link && (
                    <div className='text-danger'>{errors.additional_educations[index]?.course_link}</div>
                  )
                }
              </div>
              <div className='form-outline mb-4 DashBoardInputBx'>
                <label id={`certificate_${index}`} className='form-label' htmlFor={`certificate_${index}`}>
                  {t('jobseekerEditProfile.certificate')}
                </label>
                <input
                  type='file'
                  accept='.jpeg, .png, .jpg'
                  name={`certificate_${index}`}
                  onChange={e => handleFileUpload(e, index)}
                  className={`form-control ${errors.additional_educations[index]?.certificate && 'input-error'}`}
                />
                {
                  errors.additional_educations[index]?.certificate && (
                    <div className='text-danger'>{errors.additional_educations[index]?.certificate}</div>
                  )
                }
              </div>
              {
                i.certificate && (
                  <div className='JSEPFirstSegment mx-0 mt-0 mb-4'>
                    <div className='profileImageBox'>
                      <img
                        alt='certificate'
                        src={i.certificate}
                        className='profileImage'
                      />
                    </div>
                    <button
                      type='button'
                      className='btn btn-primary button1'
                      onClick={() => handleFileRemove(index)}
                    >
                      {t('jobseekerEditProfile.removeButton')}
                    </button>
                  </div>
                )
              }
              <div className={`${additional_educations.length > 1 && index < additional_educations.length - 1 && 'mb-5'} row`}>
                <div className='removeButtonJobseeker'>
                  <button
                    type='button'
                    className='btn btn-primary button2'
                    disabled={additional_educations.length === 1}
                    onClick={() => handleRemoveItem('additional_educations', index)}
                  >
                    {t('jobseekerEditProfile.removeButton')}
                  </button>
                  <button
                    type='button'
                    className='btn btn-primary button1'
                    onClick={() => handleAddItem('additional_educations')}
                  >
                    {t('jobseekerEditProfile.addMoreButton')}
                  </button>
                </div>
              </div>
              {
                additional_educations.length > 1 && index < additional_educations.length - 1 && <hr />
              }
            </>
          ))
        }
      </div>
    </>
  );
};

export default EducationInfoBlock;

function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = error => {
      reject(error);
    };
  });
}
