/* eslint-disable unused-imports/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Footer from '../element/Footer';
import JSSidebar from './JSSidebar';
import NavBar from '../element/NavBar';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useTranslation } from 'react-i18next';
import { logOutIfTokenExpired, resultInfo } from '../helpers';

const JSChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState({
    old_password: '',
    new_password: '',
    conf_password: '',
  });
  const [errors, setErrors] = useState({
    old_password: '',
    new_password: '',
    conf_password: '',
  });
  const navigate = useNavigate();

  const tokenKey = Cookies.get('tokenClient');
  const primaryColor = Cookies.get('primaryColor');
  const secondaryColor = Cookies.get('secondaryColor');
  const [t, i18n] = useTranslation('global');

  const [hoverFirstButtonColor, setHoverFirstButtonColor] = useState(false);

  const handleFirstButtonMouseEnter = () => {
    setHoverFirstButtonColor(true);
  };

  const handleFirstButtonMouseLeave = () => {
    setHoverFirstButtonColor(false);
  };

  const [hoverSecondButtonColor, setHoverSecondButtonColor] = useState(false);

  const handleSecondButtonMouseEnter = () => {
    setHoverSecondButtonColor(true);
  };

  const handleSecondButtonMouseLeave = () => {
    setHoverSecondButtonColor(false);
  };

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate('/user/jobseekerlogin');
    } else {
      // TokenKey is present, fetch data or perform other actions
      // getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  const handleClick = async () => {
    try {
      const newErrors = {};

      if (password.old_password === '') {
        newErrors.old_password = t('jobseekerChangePassword.oldPassRequired');
        window.scrollTo(0, 0);
      }

      if (password.new_password === '') {
        newErrors.new_password = t('jobseekerChangePassword.newPassRequired');
        window.scrollTo(0, 0);
      }
      if (password.conf_password === '') {
        newErrors.conf_password = t('jobseekerChangePassword.confPassRequired');
        window.scrollTo(0, 0);
      }
      if (password.new_password) {
        if (password.new_password.length < 8) {
          newErrors.new_password = t('jobseekerChangePassword.passLengthError');
        }
      }
      if (password.conf_password) {
        if (password.conf_password.length < 8) {
          newErrors.conf_password = t(
            'jobseekerChangePassword.passLengthError',
          );
        }
      }
      if (password.new_password && password.conf_password) {
        if (password.new_password !== password.conf_password) {
          newErrors.conf_password = t('jobseekerChangePassword.passMatchError');
        }
      }

      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        const confirmationResult = await Swal.fire({
          icon: 'question',
          showCancelButton: true,
          text: t('jobseekerChangePassword.confirmTxt'),
          title: t('jobseekerChangePassword.confirmTitle'),
          cancelButtonText: t('jobseekerChangePassword.no'),
          confirmButtonText: t('jobseekerChangePassword.yes'),
        });

        if (confirmationResult.isConfirmed) {
          setLoading(true);

          const response = await axios.post(
            BaseApi + '/candidates/changePassword',
            password,
            {
              headers: {
                key: ApiKey,
                token: tokenKey,
                'Content-Type': 'application/json',
              },
            },
          );

          setLoading(false);
          if (response.data.status === 200) {
            resultInfo('success', t('jobseekerChangePassword.successTxt'), t('jobseekerChangePassword.close'));

            navigate('/candidates/my-cv-list');
          }
          else if (response.data.status === 400) {
            navigate('/');

            Cookies.remove('fname');
            Cookies.remove('user_id');
            Cookies.remove('user_type');
            Cookies.remove('tokenClient');

            resultInfo('warning', response.data.message, t('searchJobPage.close'));
          }
          else {
            resultInfo('warning', response.data.message, t('searchJobPage.close'));
          }
        }
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        resultInfo('error', t('jobseekerChangePassword.failedTxt'), t('jobseekerChangePassword.close'));
      }

      console.error('Couldn\'t change jobseeker password', error);
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setPassword(prev => ({
      ...prev,
      [name]: value,
    }));
    setErrors(prev => ({
      ...prev,
      [name]: '',
    }));
  };

  return (
    <>
      <NavBar />
      <div className='container changePassword editProfile'>
        <div className='row'>
          <div className='col-lg-3'>
            <JSSidebar />
          </div>
          {loading ? (
            <div className='loader-container'></div>
          ) : (
            <>
              <div
                className='col-lg-9 mb-5'
                style={{
                  borderLeft: '2px solid #e6e8e7',
                  borderRight: '2px solid #e6e8e7',
                }}
              >
                <div className='d-flex mx-3'>
                  <img src='/Images/employerSide/icon9color.png' alt='' />
                  <h3 className='mx-2'>
                    {t('jobseekerChangePassword.changeYourPassword')}
                  </h3>
                </div>
                <form>
                  <div className='mb-5 mt-5'>
                    <div className='form-outline mb-5 DashBoardInputBx'>
                      <label className='form-label' htmlFor='form3Example1'>
                        {t('jobseekerChangePassword.oldPassword')}{' '}
                        <span className='RedStar'>*</span>
                      </label>
                      <input
                        type='password'
                        id='form3Example1'
                        className={`form-control ${
                          errors.old_password && 'input-error'
                        }`}
                        placeholder={t('jobseekerChangePassword.oldPassword')}
                        value={password.old_password}
                        name='old_password'
                        onChange={handleChange}
                      />
                      {errors.old_password && (
                        <div className='text-danger'>{errors.old_password}</div>
                      )}
                    </div>
                    <div className='form-outline mb-5 DashBoardInputBx'>
                      <label className='form-label' htmlFor='form3Example3'>
                        {t('jobseekerChangePassword.newPassword')}{' '}
                        <span className='RedStar'>*</span>
                      </label>
                      <input
                        type='password'
                        id='form3Example3'
                        className={`form-control ${
                          errors.new_password && 'input-error'
                        }`}
                        placeholder={t('jobseekerChangePassword.newPassword')}
                        value={password.new_password}
                        name='new_password'
                        onChange={handleChange}
                      />
                      {errors.new_password && (
                        <div className='text-danger'>{errors.new_password}</div>
                      )}
                      {password.new_password && (
                        <PasswordStrengthBar password={password.new_password} />
                      )}{' '}
                    </div>
                    <div className='form-outline mb-5 DashBoardInputBx'>
                      <label className='form-label' htmlFor='form3Example3'>
                        {t('jobseekerChangePassword.confirmPassword')}{' '}
                        <span className='RedStar'>*</span>
                      </label>
                      <input
                        type='password'
                        id='form3Example3'
                        className={`form-control ${
                          errors.conf_password && 'input-error'
                        }`}
                        placeholder={t(
                          'jobseekerChangePassword.confirmPassword',
                        )}
                        value={password.conf_password}
                        name='conf_password'
                        onChange={handleChange}
                      />
                      {errors.conf_password && (
                        <div className='text-danger'>
                          {errors.conf_password}
                        </div>
                      )}
                      {password.conf_password && (
                        <PasswordStrengthBar
                          password={password.conf_password}
                        />
                      )}
                    </div>
                  </div>
                  <div className='bottomButtons ms-3'>
                    <button
                      type='button'
                      onClick={handleClick}
                      className='btn btn-primary button1'
                    >
                      {t('jobseekerChangePassword.updateButton')}
                    </button>
                    <button
                      type='button'
                      className='btn btn-primary button2'
                      onClick={() => navigate('/candidates/my-cv-list')}
                    >
                      {t('jobseekerChangePassword.cancelButton')}
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default JSChangePassword;
