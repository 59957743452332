/* eslint-disable unused-imports/no-unused-vars */
import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  EmailShareButton,
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  InstapaperShareButton,
} from 'react-share';
import {
  EmailIcon,
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  PinterestIcon,
  InstapaperIcon,
} from 'react-share';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// components
import Footer from './Footer';
import NavBar from './NavBar';
import JobCard from './JobCard';
// helpers
import { logOutIfTokenExpired, resultInfo } from '../helpers';

const JobDescription = () => {
  const { slug } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const GUID = localStorage.getItem('GUID');
  const userType = Cookies.get('user_type');
  const tokenKey = Cookies.get('tokenClient');
  const userId = Cookies.get('user_id') || '';
  const primaryColor = Cookies.get('primaryColor');
  const secondaryColor = Cookies.get('secondaryColor');
  const jobSearched = sessionStorage.getItem('jobSearched');

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [skillData, setSkillData] = useState([]);
  const [langsData, setLengsData] = useState([]);
  const [relevantJob, setRelevantJob] = useState([]);
  const [coverLetterData, setCoverLetterData] = useState([]);
  const [validationError, setValidationError] = useState('');
  const [subCategoriesData, setSubCategoriesData] = useState([]);
  const [coverLetterSelected, setCoverLetterSelected] = useState();
  const [jobDescriptionData, setJobDescriptionData] = useState([]);
  const [hideCompanyContact, setHideCompanyContact] = useState(true);
  const [hideVacancyContact, setHideVacancyContact] = useState(true);
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);

  const [hoverColor, setHoverColor] = useState(false);
  const [hoverSearchColor, setHoverSearchColor] = useState(false);
  const [hoverUploadCVColor, setHoverUploadCVColor] = useState(false);

  const handleSearchMouseEnter = () => {
    setHoverSearchColor(true);
  };

  const handleSearchMouseLeave = () => {
    setHoverSearchColor(false);
  };

  const handleUploadCVMouseEnter = () => {
    setHoverUploadCVColor(true);
  };

  const handleUploadCVMouseLeave = () => {
    setHoverUploadCVColor(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + `/candidates/apps_jobdetail/${slug}`,
        { guid: GUID, user_id: userId },
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);

      if (response.data.status === 200) {
        const {
          title,
          salary,
          user_id,
          job_type,
          location,
          posted_date,
          company_name,
          contact_name,
        } = response.data.response;

        const metaTitle = document.querySelector('title');
        const metaDescription = document.querySelector('meta[name="description"]');
        const metaTitleValue = `${title} - ${company_name} в місті ${location}, створена ${posted_date} | GRC.ua`; // eslint-disable-line
        const metaDescriptionValue = `${company_name} шукає ${title} у місті ${location}. Тип зайнятості - ${job_type}. Заробітна плата: ${salary}. Контактна особа - ${contact_name ? contact_name : 'не зазначено'}. Запропонуйте резюме на сайті GRC.ua`; // eslint-disable-line

        if (metaTitle) {
          metaTitle.textContent = metaTitleValue;
        }

        if (metaDescription) {
          metaDescription.content = metaDescriptionValue;
        }

        sessionStorage.removeItem('jobSearched');
        setJobDescriptionData(response.data.response);
        setLengsData(response.data.response.lang_skills);
        setSkillData(response.data.response.skills_array);
        setRelevantJob(response.data.response.relevantJobList);
        setCoverLetterData(response.data.response.coverletter);
        setSubCategoriesData(response.data.response.sub_categories);
      } else {
        resultInfo('error', response.data.message, t('jobDescription.close'));
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      }

      console.error('Cannot get job description through applied job section at job seeker', error);
    }
  };

  const getAppliedData = async id => {
    try {
      setLoading(true);

      await axios.post(
        BaseApi + `/job/applypop/${id}`,
        null,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error('Error at applied button at job description');
    }
  };

  useEffect(() => {
    getAppliedData();
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const savedJob = async slug => {
    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + `/job/JobSave/${slug}`,
        null,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);

      if (response.data.status === 200) {
        getData();

        resultInfo('success', t('jobDescription.savedJobSuccessTitle'), t('jobDescription.close'));
      } else {
        resultInfo('error', response.data.message, t('jobDescription.close'));
      }
    } catch (error) {
      setLoading(false);

      resultInfo('error', t('jobDescription.savedJobFailedTitle'), t('jobDescription.close'));

      console.error('Cannot save job!', error);
    }
  };

  const handleDeclarationSubmit = async slug => {
    if (!isAgreementChecked) {
      setValidationError(t('jobDescription.declarationValidationError'));
      setError(false);
    } else {
      setLoading(true);
      try {
        const response = await axios.post(
          BaseApi + `/job/jobApplyDetail/${slug}`,
          coverLetterSelected,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              'Content-Type': 'application/json',
            },
          },
        );

        setLoading(false);

        if (response.data.status === 200) {
          getData();

          resultInfo('success', t('jobDescription.jobAppliedSuccessTitle'), t('jobDescription.close'));
        } else {
          getData();

          resultInfo('error', response.data.message, t('jobDescription.close'));
        }
      } catch (error) {
        setLoading(false);

        if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
          navigate('/');

          logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
        } else {
          resultInfo('error', t('jobDescription.jobAppliedFailedTitle'), t('jobDescription.close'));
        }

        console.error('Error on clicking the submit button at apply job modal!');
      }
    }
  };

  const handleClickWithoutLogin = () => {
    navigate('/user/jobseekerlogin');

    const Toast = Swal.mixin({
      timer: 3000,
      toast: true,
      position: 'top-end',
      timerProgressBar: true,
      showConfirmButton: false,
      didOpen: toast => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: 'warning',
      title: t('jobDescription.pleaseLogin'),
    });
  };

  const handleAgreementChange = () => {
    if (validationError) {
      setError(true);
      setValidationError('');
    }

    setIsAgreementChecked(!isAgreementChecked);
    setError(!error);
  };

  useEffect(() => {
    const canonicalUrl = `${window.location.origin}${location.pathname}`;

    let linkElement = document.querySelector('link[rel="canonical"]');

    if (linkElement) {
      linkElement.setAttribute('href', canonicalUrl);
    } else {
      linkElement = document.createElement('link');
      linkElement.setAttribute('rel', 'canonical');
      linkElement.setAttribute('href', canonicalUrl);
      document.head.appendChild(linkElement);
    }
  }, [location.pathname]);

  return (
    <>
      <NavBar />
      {
        loading
          ? (
            <div className='loader-container'></div>
          ) : (
            <>
              <section className='JobDetailTopBx'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-7 col-md-7'>
                      <div className='single-job-detail'>
                        <div className='logo-detail'>
                          <img
                            alt='Company logo'
                            className='JDImage'
                            src={
                              jobDescriptionData.logo === 'https://dev.grc.com.ua/job-board-script/public/files/user/employer/' ||
                              jobDescriptionData.logo === 'https://grc.ua/files/user/employer/' ||
                              !jobDescriptionData.logo
                                ? '/Images/jobseekerSide/dummy-profile.png'
                                : jobDescriptionData.logo
                            }
                          />
                        </div>
                        <div className='job-meta-detail'>
                          <h2>
                            {
                              jobDescriptionData.title
                                ? HTMLReactParser(jobDescriptionData.title)
                                : ''
                            }
                          </h2>
                          <p>
                            <i className='fa-sharp fa-solid fa-briefcase' />
                            {' '}
                            {jobDescriptionData.experience}
                          </p>
                          <p>
                            <i className='fa-sharp fa-solid fa-location-dot' />
                            {' '}
                            {jobDescriptionData.location}
                          </p>
                          <p>
                            <i className='fa-solid fa-calendar-days' />
                            {' '}
                            {jobDescriptionData.posted_date}
                          </p>
                        </div>
                      </div>
                    </div>
                    {
                      userType !== 'recruiter' && (
                        <div className='col-lg-5 col-md-5'>
                          <div className='JobDetailBtnBx'>
                            {/* <div className='ShareBtnDetails'>
                              <Link
                                data-bs-toggle='modal'
                                onClick={() => handleOpen()}
                                data-bs-target='#exampleModal'
                              >
                                <img src='/Images/share.png' alt='' />
                              </Link>
                            </div> */}
                            <div
                              tabIndex='-1'
                              id='exampleModal'
                              aria-hidden='true'
                              className='modal fade'
                              aria-labelledby='exampleModalLabel'
                            >
                              <div className='modal-dialog modal-dialog-centered'>
                                <div className='modal-content'>
                                  <div className='modal-header'>
                                    <h2
                                      className='modal-title fs-5'
                                      id='exampleModalLabel'
                                    >
                                      {t('jobDescription.shareNow')}
                                    </h2>
                                    <button
                                      type='button'
                                      aria-label='Close'
                                      className='btn-close'
                                      data-bs-dismiss='modal'
                                    />
                                  </div>
                                  <div className='modal-body'>
                                    <ul className='shareIconsList'>
                                      <li className='shareIconsButtons'>
                                        <FacebookShareButton url={jobDescriptionData.job_url}>
                                          <FacebookIcon logoFillColor='white' round={true} />
                                        </FacebookShareButton>
                                      </li>
                                      <li className='shareIconsButtons'>
                                        <TwitterShareButton url={jobDescriptionData.job_url}>
                                          <TwitterIcon logoFillColor='white' round={true} />
                                        </TwitterShareButton>
                                      </li>
                                      <li className='shareIconsButtons'>
                                        <WhatsappShareButton url={jobDescriptionData.job_url}>
                                          <WhatsappIcon logoFillColor='white' round={true} />
                                        </WhatsappShareButton>
                                      </li>
                                      <li className='shareIconsButtons'>
                                        <LinkedinShareButton url={jobDescriptionData.job_url}>
                                          <LinkedinIcon logoFillColor='white' round={true} />
                                        </LinkedinShareButton>
                                      </li>
                                      <li className='shareIconsButtons'>
                                        <EmailShareButton url='www.logicspice.com'>
                                          <EmailIcon logoFillColor='white' round={true} />
                                        </EmailShareButton>
                                      </li>
                                      <li className='shareIconsButtons'>
                                        <PinterestShareButton url={jobDescriptionData.job_url}>
                                          <PinterestIcon logoFillColor='white' round={true} />
                                        </PinterestShareButton>
                                      </li>
                                      <li className='shareIconsButtons'>
                                        <InstapaperShareButton url={jobDescriptionData.job_url}>
                                          <InstapaperIcon logoFillColor='white' round={true} />
                                        </InstapaperShareButton>
                                      </li>
                                      <li className='shareIconsButtons'>
                                        <TelegramShareButton url={jobDescriptionData.job_url}>
                                          <TelegramIcon round={true} logoFillColor='white' />
                                        </TelegramShareButton>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* {
                              tokenKey != null
                                ? (
                                  <div className='ApplyJobBtnBx'>
                                    {
                                      jobDescriptionData.is_applied === 1
                                        ? (
                                          <button
                                            className='btn btn-primary'
                                            style={{
                                              minWidth: '115px',
                                              backgroundColor: `${
                                                secondaryColor && (hoverColor ? secondaryColor : primaryColor)
                                              }`,
                                              border: `${
                                                secondaryColor && (hoverColor ? secondaryColor : primaryColor)
                                              }`,
                                            }}
                                          >
                                            {t('jobDescription.applied')}
                                          </button>
                                        ) : (
                                          <button
                                            data-bs-toggle='modal'
                                            className='btn btn-primary'
                                            data-bs-target='#applyModal'
                                            style={{
                                              minWidth: '115px',
                                              backgroundColor: `${
                                                secondaryColor && (hoverColor ? secondaryColor : primaryColor)
                                              }`,
                                              border: `${
                                                secondaryColor && (hoverColor ? secondaryColor : primaryColor)
                                              }`,
                                            }}
                                          >
                                            {t('jobDescription.applyNow')}
                                          </button>
                                        )
                                    }
                                    {
                                      jobDescriptionData.is_saved === 1
                                        ? (
                                          <button className='btn btn-secondary'>
                                            <i className='fa fa-light fa-star-o' /> {t('jobDescription.saved')}
                                          </button>
                                        ) : (
                                          <button
                                            className='btn btn-secondary'
                                            onClick={() => savedJob(slug)}
                                            style={{
                                              borderRadius: '4px',
                                              color: secondaryColor,
                                              backgroundColor: 'white',
                                              border: `1px solid ${secondaryColor}`,
                                            }}
                                          >
                                            <i className='fa fa-light fa-star-o' /> {t('jobDescription.saveJob')}
                                          </button>
                                        )
                                    }
                                  </div>
                                ) : (
                                  <div className='ApplyJobBtnBx'>
                                    <button
                                      className='btn btn-primary'
                                      onClick={() => handleClickWithoutLogin()}
                                      style={{
                                        minWidth: '115px',
                                        backgroundColor: `${
                                          secondaryColor && (hoverColor ? secondaryColor : primaryColor)
                                        }`,
                                        border: `${
                                          secondaryColor && (hoverColor ? secondaryColor : primaryColor)
                                        }`,
                                      }}
                                    >
                                      {t('jobDescription.applyNow')}
                                    </button>
                                    <button
                                      onClick={() => handleClickWithoutLogin()}
                                      className='btn btn-secondary'
                                      style={{
                                        color: secondaryColor,
                                        border: `1px solid ${secondaryColor}`,
                                        backgroundColor: 'white',
                                        borderRadius: '4px',
                                      }}
                                    >
                                      <i className='fa fa-light fa-star-o' /> {t('jobDescription.saveJob')}
                                    </button>
                                  </div>
                                )
                            } */}
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </section>
              <section className='JobDetailsDescriptionSection'>
                <div className='container JDSection2'>
                  <div className='row'>
                    <div className='col-lg-8 col-md-8'>
                      <div className='JobDescriptionBx standartListStyle mb-4 mb-md-0'>
                        <h2 className='mb-4'>{t('jobDescription.jobDescription')}</h2>
                        <p>
                          {
                            jobDescriptionData.description
                              ? HTMLReactParser(jobDescriptionData.description)
                              : ''
                          }
                        </p>
                        <h2 className='mt-5 mb-4'>{t('jobDescription.skillRequired')}</h2>
                        <div>
                          {
                            skillData.length > 0
                              ? skillData.map((i, index) => (
                                <p className='keySkillitem' key={index}>
                                  <i className='fa-solid fa-arrow-right' />
                                  {' '}
                                  {i}
                                </p>
                              ))
                              : t('jobDescription.notAvailable')
                          }
                        </div>
                        {
                          langsData.length > 0 && (
                            <div>
                              <h2 className='mt-5 mb-4'>{t('employerCreateJob.additionalLanguages')}</h2>
                              {
                                langsData.map((i, index) => (
                                  <p className='keySkillitem' key={index}>
                                    <i className='fa-solid fa-arrow-right' />
                                    {' '}
                                    {i}
                                  </p>
                                ))
                              }
                            </div>
                          )
                        }
                        <h2 className='mt-5'>{t('jobDescription.vacancyContacts')}</h2>
                        <div className='JobDetailsInfo'>
                          {
                            hideVacancyContact
                              ? (
                                <Link
                                  id='vacancyContacts'
                                  className='hideContact'
                                  onClick={() => setHideVacancyContact(false)}
                                >
                                  {t('jobDescription.showVacancyContacts')}
                                </Link>
                              ) : (
                                <ul>
                                  <li>
                                    <span className='jobDescriptionKeys'>
                                      <i className='fa-solid fa-user' /> {t('jobDescription.contactName')}:{' '}
                                    </span>
                                    {
                                      jobDescriptionData.contact_name
                                        ? jobDescriptionData.contact_name
                                        : t('jobDescription.notAvailable')
                                    }
                                  </li>
                                  <li>
                                    <span className='jobDescriptionKeys'>
                                      <i className='fa-solid fa-envelope' /> {t('jobDescription.email')}:{' '}
                                    </span>
                                    {
                                      jobDescriptionData.contact_email
                                        ? (
                                          <a href={`mailto:${jobDescriptionData.contact_email}`}>
                                            {jobDescriptionData.contact_email}
                                          </a>
                                        )
                                        : t('jobDescription.notAvailable')
                                    }
                                  </li>
                                  <li>
                                    <span className='jobDescriptionKeys'>
                                      <i className='fa-solid fa-phone' /> {t('jobDescription.contactNumber')}:{' '}
                                    </span>
                                    {
                                      jobDescriptionData.contact_number
                                        ? (
                                          <a href={`tel:${jobDescriptionData.contact_number}`}>
                                            {jobDescriptionData.contact_number}
                                          </a>
                                        ) : (
                                          t('jobDescription.notAvailable')
                                        )
                                    }
                                  </li>
                                  {
                                    jobDescriptionData.contact_number_2 && (
                                      <li>
                                        <span className='jobDescriptionKeys'>
                                          <i className='fa-solid fa-phone' /> {t('jobDescription.contactNumber')}:{' '}
                                        </span>
                                        <a href={`tel:${jobDescriptionData.contact_number_2}`}>
                                          {jobDescriptionData.contact_number_2}
                                        </a>
                                      </li>
                                    )
                                  }
                                  {
                                    jobDescriptionData.contact_number_3 && (
                                      <li>
                                        <span className='jobDescriptionKeys'>
                                          <i className='fa-solid fa-phone' /> {t('jobDescription.contactNumber')}:{' '}
                                        </span>
                                        <a href={`tel:${jobDescriptionData.contact_number_3}`}>
                                          {jobDescriptionData.contact_number_3}
                                        </a>
                                      </li>
                                    )
                                  }
                                  {
                                    jobDescriptionData.form_link && (
                                      <li>
                                        <span className='jobDescriptionKeys'>
                                          <i className='fas fa-external-link-alt' />{' '}
                                          {t('jobDescription.formLink')}:{' '}
                                        </span>
                                        <a href={jobDescriptionData.form_link} target='blank'>
                                          {jobDescriptionData.form_link}
                                        </a>
                                      </li>
                                    )
                                  }
                                </ul>
                              )
                          }
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-4'>
                      <div className='card Jcard JobDetailsCard'>
                        <div className='card-title'>
                          <h2>{t('jobDescription.jobOverview')}</h2>
                        </div>
                        <div className='card-body JcardBody'>
                          <div className='JobDetailsInfo'>
                            <ul>
                              <li>
                                <span className='jobDescriptionKeys'>
                                  <i className='fa-solid fa-info-circle' /> {t('jobDescription.vacancyID')}:{' '}
                                </span>
                                {jobDescriptionData.job_id && jobDescriptionData.job_id}
                              </li>
                              <li>
                                <span className='jobDescriptionKeys'>
                                  <i className='fa-solid fa-calendar-days' /> {t('jobDescription.datePosted')}:{' '}
                                </span>
                                {jobDescriptionData.posted_date}
                              </li>
                              <li>
                                <span className='jobDescriptionKeys'>
                                  <i className='fa-solid fa-sitemap' /> {t('jobDescription.category')}:{' '}
                                </span>
                                {jobDescriptionData.category}
                              </li>
                              <li>
                                <span className='jobDescriptionKeys'>
                                  <i className='fa-solid fa-sitemap' /> {t('jobDescription.subCategory')}:{' '}
                                </span>
                                {
                                  subCategoriesData.length > 0
                                    ? subCategoriesData.map((i, index) => (
                                      i !== undefined && (
                                        <p className='keySkillitem' key={index}>
                                          <i className='fa-solid fa-arrow-right' />
                                          {' '}
                                          {i}
                                        </p>
                                      )
                                    ))
                                    : t('jobDescription.notAvailable')
                                }
                              </li>
                              <li>
                                <span className='jobDescriptionKeys'>
                                  <i className='fa-solid fa-clock' /> {t('jobDescription.jobType')}:{' '}
                                </span>
                                {jobDescriptionData.job_type}
                              </li>
                              <li>
                                <span className='jobDescriptionKeys'>
                                  <i className='fa-solid fa-dollar-sign' />{' '}
                                  {t('jobDescription.salary')}:{' '}
                                </span>
                                {
                                  jobDescriptionData.salary !== 'N/A'
                                    ? jobDescriptionData.salary
                                    : t('jobDescription.notAvailable')
                                }
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className='card Jcard JobDetailsCard'>
                        <div className='card-title'>
                          <h2>{t('jobDescription.contactInfo')}</h2>
                        </div>
                        <div className='card-body JcardBody'>
                          <div className='JobDetailsInfo'>
                            <ul>
                              <li>
                                <span className='jobDescriptionKeys'>
                                  <i className='fa-solid fa-users' /> {t('jobDescription.companyName')}:{' '}
                                </span>
                                {
                                  jobDescriptionData.company_name
                                    ? jobDescriptionData.company_name
                                    : t('jobDescription.notAvailable')
                                }
                              </li>
                              <li>
                                <span className='jobDescriptionKeys'>
                                  <i className='fa-solid fa-user' /> {t('jobDescription.recruiterName')}:{' '}
                                </span>
                                {
                                  jobDescriptionData.contact_name
                                    ? jobDescriptionData.contact_name
                                    : t('jobDescription.notAvailable')
                                }
                              </li>
                              <li>
                                <span className='jobDescriptionKeys'>
                                  <i className='fa-solid fa-phone' /> {t('jobDescription.contactCompany')}:{' '}
                                </span>
                                {
                                  hideCompanyContact ? (
                                    <Link
                                      id='companyContacts'
                                      className='hideContact'
                                      onClick={() => setHideCompanyContact(false)}
                                    >
                                      {t('jobDescription.showContactCompany')}
                                    </Link>
                                  ) : (
                                    jobDescriptionData.contact_number
                                      ? (
                                        <a href={`tel:${jobDescriptionData.contact_number}`}>
                                          {jobDescriptionData.contact_number}
                                        </a>
                                      )
                                      : t('jobDescription.notAvailable')
                                  )
                                }
                              </li>
                              <li>
                                <span className='jobDescriptionKeys'>
                                  <i className='fa-solid fa-share' /> {t('jobDescription.website')}:{' '}
                                </span>
                                {
                                  jobDescriptionData.website && jobDescriptionData.website !== 'N/A'
                                    ? (
                                      <a href={jobDescriptionData.website} target='blank'>
                                        {jobDescriptionData.website}
                                      </a>
                                    )
                                    : t('jobDescription.notAvailable')
                                }
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      relevantJob.length > 0 && (
                        <div className='similarJobsSection'>
                          <h2 className='mt-md-5'>{t('jobDescription.similarJobs')}</h2>
                          <div className='row'>
                            {
                              relevantJob.map((i, index) => (
                                <div
                                  key={index}
                                  className='mt-4 col-lg-4 col-md-4'
                                  onClick={() => window.location.reload()}
                                >
                                  <JobCard
                                    logo={i.logo}
                                    slug={i.slug}
                                    title={i.title}
                                    created={i.created}
                                    min_exp={i.min_exp}
                                    cat_slug={i.cat_slug}
                                    job_city={i.job_city}
                                    work_type={i.work_type}
                                    salary_max={i.salary_max}
                                    salary_min={i.salary_min}
                                    company_name={i.company_name}
                                    salary_currency={i.salary_currency}
                                  />
                                </div>
                              ))
                            }
                          </div>
                          <div className='text-end'>
                            <Link to={`/searchjob/c-${relevantJob[0].cat_slug}`}>
                              <button className='btn button1 mt-3'>
                                {t('jobDescription.showMore')}
                              </button>
                            </Link>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </section>
              <Footer />
              {/* Reply Modal  */}
              <div
                tabIndex='-1'
                id='applyModal'
                aria-hidden='true'
                aria-labelledby='exampleModalLabel'
                className='modal fade  membershipModal'
              >
                <div className='modal-dialog'>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h2
                        className='text-center modal-title fs-5'
                        id='exampleModalLabel'
                      >
                        {t('jobDescription.jobApplicationConfirmation')}
                      </h2>
                      <button
                        type='button'
                        className='btn-close'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                      ></button>
                    </div>
                    <div className='modal-body text-center'>
                      <h5 className='m-2'>{t('jobDescription.declaration')}:</h5>
                      <p>
                        {t('jobDescription.declarationTxt')}
                      </p>
                      <div className='m-2'>
                        <label>
                          <input
                            required
                            type='checkbox'
                            name='agreement'
                            checked={isAgreementChecked}
                            onChange={handleAgreementChange}
                          />
                          {' '}
                          {t('jobDescription.declarationAgreed')}
                          <span className='RedStar'>*</span>
                        </label>
                      </div>
                      {
                        validationError && (
                          <p style={{ color: 'red' }}>{validationError}</p>
                        )
                      }
                      <h5 className='m-2'> {t('jobDescription.selectCoverLetter')}</h5>
                      {
                        coverLetterData && (
                          coverLetterData.map((i, index) => (
                            <div key={index}>
                              <input
                                type='radio'
                                id={i.id}
                                name='coverletter'
                                value={i.id}
                                onChange={e =>
                                  setCoverLetterSelected(e.target.value)
                                }
                              />
                              {' '}
                              {i.title}
                            </div>
                          )))
                      }
                      <hr />
                      <div className='d-flex justify-content-evenly'>
                        <button
                          type='button'
                          className='btn btn-primary button1'
                          style={{
                            backgroundColor: hoverSearchColor
                              ? secondaryColor
                              : primaryColor,
                            border: hoverSearchColor
                              ? secondaryColor
                              : primaryColor,
                          }}
                          onMouseEnter={handleSearchMouseEnter}
                          onMouseLeave={handleSearchMouseLeave}
                          data-bs-dismiss={error && 'modal'}
                          aria-label={error && 'close'}
                          onClick={() => handleDeclarationSubmit(slug)}
                        >
                          {t('jobDescription.submitButton')}
                        </button>
                        <Link
                          to='/candidates/editprofile'
                          type='button'
                          className='btn btn-primary button2'
                          style={{
                            color: hoverUploadCVColor
                              ? primaryColor
                              : secondaryColor,
                            backgroundColor: 'white',
                            border: hoverUploadCVColor
                              ? `2px solid ${primaryColor}`
                              : `2px solid ${secondaryColor}`,
                          }}
                          onMouseEnter={handleUploadCVMouseEnter}
                          onMouseLeave={handleUploadCVMouseLeave}
                          data-bs-dismiss='modal'
                          aria-label='Close'
                          onClick={() => navigate('/candidates/editprofile')}
                        >
                          {t('jobDescription.addCoverLetter')}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
      }
    </>
  );
};

export default JobDescription;
