/* eslint-disable react/prop-types */
import axios from 'axios';
import Cookies from 'js-cookie';
import Select from 'react-select';
import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';
// api
import ApiKey from '../../../api/ApiKey';
import BaseApi from '../../../api/BaseApi';
import { getCities } from '../../../employersSide/api/getCities';
import { getSubCategories } from '../../../employersSide/api/getSubCategories';
// helpers
import { debounceAsync, selectStyles } from '../../../helpers';

const DesiredPositionBlock = ({ errors, setErrors, directories, profileBody, handleChange, setProfileBody }) => {
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');

  const [subCategories, setSubCategories] = useState([]);

  const { work_types = [], countries = [], categories = [], currencies = [] } = directories;

  const {
    position = '',
    work_type = '',
    salary_max = '',
    salary_min = '',
    relocations = [],
    desired_country = '',
    salary_currency = '',
    desired_category = [],
  } = profileBody;

  const getPositionOptions = async inputValue => {
    if (inputValue.length < 2) {
      return [];
    }

    try {
      const positions = await axios.post(
        BaseApi + '/candidates/getPositions',
        { query: inputValue },
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      const options = positions.data.response.map(position => ({
        value: position.id,
        label: position.name,
      }));

      return options;
    } catch (error) {
      console.error('Error fetching cities:', error);
      return [];
    }
  };

  const getSubCategoriesOptions = async (id, index) => {
    if (!id) {
      setSubCategories([]);
      setProfileBody(prevState => {
        const newSubCategory = [...prevState.desired_category];
        newSubCategory[index].sub_category = [];

        return {
          ...prevState,
          desired_category: newSubCategory,
        };
      });

      setErrors(prev => {
        const newErrors = { ...prev };

        if (!newErrors.desired_category[index]) {
          newErrors.desired_category[index] = {};
        }

        newErrors.desired_category[index].sub_category = '';

        return newErrors;
      });

      return;
    }

    setSubCategories([]);
    setProfileBody(prevState => {
      const newSubCategory = [...prevState.desired_category];
      newSubCategory[index].sub_category = [];

      return {
        ...prevState,
        desired_category: newSubCategory,
      };
    });

    setErrors(prev => {
      const newErrors = { ...prev };

      if (!newErrors.desired_category[index]) {
        newErrors.desired_category[index] = {};
      }

      newErrors.desired_category[index].sub_category = '';

      return newErrors;
    });

    try {
      const response = await getSubCategories(id);
      const data = response.data.response;

      setSubCategories(data);
    } catch (error) {
      console.error('Couldn\'t get Sub category data!', error);
    }
  };

  const getCitiesOptions = async inputValue => {
    if (!desired_country || inputValue.length < 2) {
      return [];
    }

    try {
      const cities = await getCities(parseInt(desired_country), inputValue);

      if (cities.data.response?.length === 0) {
        throw new Error('No options found');
      }

      const options = cities.data.response.map(city => ({
        value: city.id,
        label: `${city.name}${city.region && `, ${city.region}`}`,
      }));

      return options;
    } catch (error) {
      console.error('Error fetching cities:', error);
      return [];
    }
  };

  const handleFieldChange = (field, selectedOptions, limit = null) => {
    if (limit && selectedOptions.length > limit) {
      setErrors(prev => ({
        ...prev,
        [field]: t(`jobseekerEditProfile.${field}Limit`),
      }));

      return;
    }

    setProfileBody(prevState => ({
      ...prevState,
      [field]: selectedOptions,
    }));

    setErrors(prev => ({
      ...prev,
      [field]: '',
    }));
  };

  const handleAddDesiredCategory = () => {
    const newDesiredCategory = {
      category: '',
      sub_category: [],
    };

    setProfileBody(prevState => ({
      ...prevState,
      desired_category: [...prevState.desired_category, newDesiredCategory],
    }));
  };

  const handleSubCategoryChange = (selectedOptions, index) => {
    if (selectedOptions.length > 2) {
      setErrors(prev => ({
        ...prev,
        desired_category: prev.desired_category.map((error, i) =>
          i === index ? { ...error, sub_category: t('jobseekerEditProfile.subCategoryLimit') } : error,
        ),
      }));

      return;
    }

    setProfileBody(prevState => {
      const newSubCategory = [...prevState.desired_category];
      newSubCategory[index].sub_category = selectedOptions;

      return {
        ...prevState,
        desired_category: newSubCategory,
      };
    });

    setErrors(prev => {
      const newErrors = { ...prev };

      if (!newErrors.desired_category[index]) {
        newErrors.desired_category[index] = {};
      }

      newErrors.desired_category[index].sub_category = '';

      return newErrors;
    });
  };

  const handleRemoveDesiredCategory = index => {
    setProfileBody(prevState => ({
      ...prevState,
      desired_category: prevState.desired_category.filter((_, i) => i !== index),
    }));

    setErrors(prevState => ({
      ...prevState,
      desired_category: prevState.desired_category.filter((_, i) => i !== index),
    }));
  };

  const debouncedGetCitiesOptions = debounceAsync(getCitiesOptions, 500);
  const debouncedGetPositionOptions = debounceAsync(getPositionOptions, 500);

  return (
    <>
      <h3 className='mb-4'>{t('jobseekerEditProfile.desiredPosition')}</h3>
      <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
        <label id='position' className='form-label' htmlFor='position'>
          {t('jobseekerEditProfile.desiredPosition')}
          <span className='RedStar'>*</span>
        </label>
        <AsyncSelect
          defaultOptions
          value={position}
          styles={selectStyles}
          loadOptions={debouncedGetPositionOptions}
          placeholder={t('jobseekerEditProfile.enterPosition')}
          loadingMessage={() => t('jobseekerEditProfile.pleaseWait')}
          className={`${errors.position && 'input-error-react-select'}`}
          onChange={selectedOptions => handleFieldChange('position', selectedOptions)}
          noOptionsMessage={
            ({ inputValue }) => inputValue.length >= 2
              ? t('jobseekerEditProfile.noOptionsFound')
              : t('jobseekerEditProfile.enterPosition')
          }
        />
        {
          errors.position && (
            <div className='text-danger'>{errors.position}</div>
          )
        }
      </div>
      <div className='mt-4 mb-4'>
        {
          desired_category.map((i, index) => (
            <>
              <div className='mt-4 mb-4 form-outline DashBoardInputBx'>
                <label id={`category_${index}`} className='form-label' htmlFor={`category_${index}`}>
                  {t('jobseekerEditProfile.category')}
                  <span className='RedStar'>*</span>
                </label>
                <select
                  value={i.category}
                  name={`category_${index}`}
                  aria-label='Default select example'
                  className={`form-select ${errors.desired_category[index]?.category && 'input-error'}`}
                  onChange={e => {
                    handleChange(e, index);
                    getSubCategoriesOptions(e.target.value, index);
                  }}
                >
                  <option value=''>
                    {t('jobseekerEditProfile.selectCategory')}
                  </option>
                  {
                    categories.map((i, index) => (
                      <option value={i.id} key={index}>
                        {i.name}
                      </option>
                    ))
                  }
                </select>
                {
                  errors.desired_category[index]?.category && (
                    <div className='text-danger'>{errors.desired_category[index]?.category}</div>
                  )
                }
              </div>
              <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
                <label id={`sub_category_${index}`} className='form-label' htmlFor={`sub_category_${index}`}>
                  {t('jobseekerEditProfile.subCategory')}
                  <span className='RedStar'>*</span>
                </label>
                <Select
                  isMulti
                  isSearchable
                  styles={selectStyles}
                  value={i.sub_category}
                  name={`sub_category_${index}`}
                  placeholder={t('jobseekerEditProfile.selectSubCategory')}
                  options={subCategories.map(i => ({ value: i.id, label: i.name }))}
                  onChange={selectedOptions => handleSubCategoryChange(selectedOptions, index)}
                  className={`${errors.desired_category[index]?.sub_category && 'input-error-react-select'}`}
                  noOptionsMessage={
                    () => i.category
                      ? t('jobseekerEditProfile.noOptionsFound')
                      : t('jobseekerEditProfile.selectCategoryFirst')
                  }
                />
                {
                  errors.desired_category[index]?.sub_category && (
                    <div className='text-danger'>{errors.desired_category[index]?.sub_category}</div>
                  )
                }
              </div>
              <div className='row'>
                <div className='removeButtonJobseeker'>
                  <button
                    type='button'
                    className='btn btn-primary button2'
                    disabled={desired_category.length === 1}
                    onClick={() => handleRemoveDesiredCategory(index)}
                  >
                    {t('jobseekerEditProfile.removeButton')}
                  </button>
                  <button
                    type='button'
                    onClick={handleAddDesiredCategory}
                    className='btn btn-primary button1'
                    disabled={desired_category.length > 2}
                  >
                    {t('jobseekerEditProfile.addMoreButton')}
                  </button>
                </div>
              </div>
            </>
          ))
        }
      </div>
      <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
        <label id='desired_country' className='form-label' htmlFor='desired_country'>
          {t('jobseekerEditProfile.country')}
          <span className='RedStar'>*</span>
        </label>
        <select
          name='desired_country'
          value={desired_country}
          onChange={e => handleChange(e)}
          aria-label='Default select example'
          className={`form-select ${errors.desired_country && 'input-error'}`}
        >
          <option value=''>
            {t('jobseekerEditProfile.selectCountry')}
          </option>
          {
            countries.map((i, index) => (
              <option value={i.id} key={index}>
                {i.name}
              </option>
            ))
          }
        </select>
        {
          errors.desired_country && (
            <div className='text-danger'>{errors.desired_country}</div>
          )
        }
      </div>
      {
        desired_country && (
          <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
            <label id='relocations' className='form-label' htmlFor='relocations'>
              {t('jobseekerEditProfile.relocations')}
              <span className='RedStar'>*</span>
            </label>
            <AsyncSelect
              isMulti
              defaultOptions
              value={relocations}
              styles={selectStyles}
              loadOptions={debouncedGetCitiesOptions}
              placeholder={t('jobseekerEditProfile.enterCityName')}
              loadingMessage={() => t('jobseekerEditProfile.pleaseWait')}
              className={`${errors.relocations && 'input-error-react-select'}`}
              onChange={selectedOptions => handleFieldChange('relocations', selectedOptions, 5)}
              noOptionsMessage={
                ({ inputValue }) => inputValue.length >= 2
                  ? t('jobseekerEditProfile.noOptionsFound')
                  : t('jobseekerEditProfile.enterCityName')
              }
            />
            {
              errors.relocations && (
                <div className='text-danger'>{errors.relocations}</div>
              )
            }
          </div>
        )
      }
      <div className='row'>
        <div className='col-lg-4 form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
          <label id='salary_min' className='form-label' htmlFor='salary_min'>
            {t('jobseekerEditProfile.expectedSalaryFrom')}
          </label>
          <input
            type='text'
            name='salary_min'
            value={salary_min}
            onChange={handleChange}
            placeholder={t('jobseekerEditProfile.from')}
            className={`form-control ${errors.salary_min && 'input-error'}`}
          />
          {
            errors.salary_min && (
              <div className='text-danger'>
                {errors.salary_min}
              </div>
            )
          }
        </div>
        <div className='col-lg-4 form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
          <label id='salary_max' className='form-label' htmlFor='salary_max'>
            {t('jobseekerEditProfile.expectedSalaryTo')}
          </label>
          <input
            type='text'
            name='salary_max'
            value={salary_max}
            onChange={handleChange}
            placeholder={t('jobseekerEditProfile.to')}
            className={`form-control ${errors.salary_max && 'input-error'}`}
          />
          {
            errors.salary_max && (
              <div className='text-danger'>
                {errors.salary_max}
              </div>
            )
          }
        </div>
        <div
          className='col-lg-4 form-outline mb-4 DashBoardInputBx DashBoardCreatBx selectCurrency'
        >
          <label id='salary_currency' className='form-label' htmlFor='form3Example1'>
            {t('jobseekerEditProfile.selectCurrency')}
            {(salary_min || salary_max) && <span className='RedStar'>*</span>}
          </label>
          <select
            name='salary_currency'
            onChange={handleChange}
            value={salary_currency}
            aria-label='Default select example'
            className={`form-select ${errors.salary_currency && 'input-error'}`}
          >
            <option selected value=''>
              {t('jobseekerEditProfile.selectCurrency')}
            </option>
            {
              Object.entries(currencies).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))
            }
          </select>
          {
            errors.salary_currency && (
              <div className='text-danger'>{errors.salary_currency}</div>
            )
          }
        </div>
      </div>
      <div className='form-outline mb-5 DashBoardInputBx DashBoardCreatBx'>
        <label id='work_type' className='form-label' htmlFor='work_type'>
          {t('jobseekerEditProfile.workType')}
          <span className='RedStar'>*</span>
        </label>
        <Select
          isMulti
          name='work_type'
          value={work_type}
          styles={selectStyles}
          placeholder={t('jobseekerEditProfile.selectWorkType')}
          className={`${errors.work_type && 'input-error-react-select'}`}
          noOptionsMessage={() => t('jobseekerEditProfile.noOptionsFound')}
          onChange={selectedOptions => handleFieldChange('work_type', selectedOptions)}
          options={Object.entries(work_types).map(([key, value]) => ({ value: key, label: value }))}
        />
        {
          errors.work_type && (
            <div className='text-danger'>{errors.work_type}</div>
          )
        }
      </div>
    </>
  );
};

export default DesiredPositionBlock;
