import axios from 'axios';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// components
import JSSidebar from './JSSidebar';
import Footer from '../element/Footer';
import NavBar from '../element/NavBar';
// helpers
import { logOutIfTokenExpired, resultInfo } from '../helpers';
// mui
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const JSMyCVList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');

  const [cvList, setCvList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + '/candidates/my-cv-list',
        null,
        {
          headers: {
            'Content-Type': 'application/json',
            key: ApiKey,
            token: tokenKey,
          },
        },
      );

      setLoading(false);

      if (response.data.status === 200) {
        setCvList(response.data.response);
      } else if (response.data.status === 400) {
        navigate('/');

        Cookies.remove('fname');
        Cookies.remove('user_id');
        Cookies.remove('user_type');
        Cookies.remove('tokenClient');

        resultInfo('warning', response.data.message, t('jobseekerManageAlert.close'));
      } else {
        resultInfo('error', response.data.message, t('jobseekerManageAlert.close'));
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      }

      setCvList([
        { id: 1, title: 'Моє 1 резюме', last_change: '01.08.2024', views: 150, slug: 'my-first-cv' },
        { id: 2, title: 'Моє 2 резюме', last_change: '12.03.2024', views: 100500, slug: 'my-second-cv' },
      ]);
      console.error('Cannot get profile data', error);
    }
  };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/user/jobseekerlogin');
    } else {
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); // eslint-disable-line

  return (
    <>
      <NavBar />
      <div className='container changeLogo'>
        <div className='row'>
          <div className='col-lg-3'>
            <JSSidebar />
          </div>
          {
            loading
              ? (
                <div className='loader-container'></div>
              ) : (
                <div className='col-lg-9 mb-5' style={{ borderLeft: '2px solid #e6e8e7', borderRight: '2px solid #e6e8e7' }} >
                  <div className='mx-lg-3 d-flex PageHeader'>
                    <img className='me-2' src='/Images/jobseekerSide/cv-list-color.png' alt='' />
                    <h3>{t('jobseekerMyProfile.myCVList')}</h3>
                  </div>
                  <div className='mx-lg-3 PageHeader'>
                    <div className='MJBody mt-4'>
                      <table className='table'>
                        <thead>
                          <tr className='table-active TrFirst'>
                            <th className='' scope='col p-3'>
                              {t('jobseekerMyProfile.cvTitle')}
                            </th>
                            <th className='' scope='col p-3'>
                              {t('jobseekerMyProfile.updated')}
                            </th>
                            <th className='' scope='col p-3'>
                              {t('jobseekerMyProfile.views')}
                            </th>
                            <th className='' scope='col p-3'>
                              {t('jobseekerMyProfile.actions')}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            cvList.map(i => (
                              <tr className='TrDefoult' key={i.id}>
                                <td className=''>
                                  <Link to={`/candidates/my-cv-list/${i.slug}`}>
                                    {i.title}
                                  </Link>
                                </td>
                                <td className=''>
                                  {i.last_change}
                                </td>
                                <td className=''>
                                  {i.views}
                                </td>
                                <td className='APActionButton'>
                                  <button
                                    className='btn-primary'
                                    // onClick={() => handleActivate(i.slug)}
                                  >
                                    <Tooltip title={t('jobseekerMyProfile.edit')}>
                                      <EditOutlinedIcon />
                                    </Tooltip>
                                  </button>
                                  <button
                                    disabled
                                    className='btn-primary button1'
                                    // onClick={() => navigate(`/admin/jobs/editjob/${i.slug}`)}
                                  >
                                    <Tooltip title={t('jobseekerMyProfile.copy')}>
                                      <FileCopyOutlinedIcon />
                                    </Tooltip>
                                  </button>
                                  <button
                                    disabled
                                    className='btn-primary button1'
                                    // onClick={() => navigate(`/admin/jobs/editjob/${i.slug}`)}
                                  >
                                    <Tooltip title={t('jobseekerMyProfile.downloadPDF')}>
                                      <FileDownloadOutlinedIcon />
                                    </Tooltip>
                                  </button>
                                  <button
                                    disabled
                                    className='btn-secondary button1'
                                    // onClick={() => handleDelete(i.slug)}
                                  >
                                    <Tooltip title={t('jobseekerMyProfile.delete')}>
                                      <DeleteIcon />
                                    </Tooltip>
                                  </button>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )
          }
        </div>
      </div>
      <Footer />
    </>
  );
};

export default JSMyCVList;
