/* eslint-disable react/prop-types */
import Select from 'react-select';
import JoditEditor from 'jodit-react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
// helpers
import { editorConfig, selectStyles } from '../../../helpers';

const AdditionalInfoBlock = ({ errors, setErrors, directories, profileBody, handleChange, setProfileBody }) => {
  const editor = useRef(null);
  const { t } = useTranslation('global');

  const {
    langs = [],
    skill_list = [],
    lang_skills = [],
    visibilities = [
      { id: 5, name: 'Видно всім' },
      { id: 0, name: 'Не видно нікому' },
      { id: 1, name: 'Видно компаніям з білого списку' },
      { id: 2, name: 'Не видно компаніям з чорного списку' },
      { id: 3, name: 'Видно тільки авторизованим роботодавцям' },
      { id: 6, name: 'Видно тільки по прямому посиланню, але приховано від пошуку' },
    ],
    driver_categories = [],
  } = directories;

  const {
    skills = [],
    languages = [],
    visibility = '',
    driver_license = 0,
    about_yourself = '',
    driver_category = [],
  } = profileBody;

  const handleFieldChange = (field, selectedOptions, limit = null) => {
    if (limit && selectedOptions.length > limit) {
      setErrors(prev => ({
        ...prev,
        [field]: t(`jobseekerEditProfile.${field}Limit`),
      }));

      return;
    }

    setProfileBody(prevState => ({
      ...prevState,
      [field]: selectedOptions,
    }));

    setErrors(prev => ({
      ...prev,
      [field]: '',
    }));
  };

  const handleAddLanguage = () => {
    const newLanguage = {
      lng: '',
      lng_skill: '',
    };

    setProfileBody(prevState => ({
      ...prevState,
      languages: [...prevState.languages, newLanguage],
    }));
  };

  const handleRemoveLanguage = index => {
    setProfileBody(prevState => ({
      ...prevState,
      languages: prevState.languages.filter((_, i) => i !== index),
    }));

    setErrors(prevState => ({
      ...prevState,
      languages: prevState.languages.filter((_, i) => i !== index),
    }));
  };

  return (
    <>
      <h3 className='mb-4'>{t('jobseekerEditProfile.additionalInfo')}</h3>
      <div className='form-outline mb-4 DashBoardInputBx APJoditEditor'>
        <label id='about_yourself' className='form-label' htmlFor='about_yourself'>
          {t('jobseekerEditProfile.aboutYourself')}
          <span className='RedStar'>*</span>
        </label>
        <JoditEditor
          ref={editor}
          name='about_yourself'
          value={about_yourself}
          config={{
            ...editorConfig,
            minHeight: '240px',
            placeholder: t('jobseekerEditProfile.aboutYourselfPlaceholder'),
          }}
          onBlur={about_yourself =>
            handleChange({
              target: {
                value: about_yourself,
                name: 'about_yourself',
              },
            })
          }
        />
        {
          errors.about_yourself && (
            <div className='text-danger'>{errors.about_yourself}</div>
          )
        }
      </div>
      <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
        <label id='skills' className='form-label' htmlFor='skills'>
          {t('jobseekerEditProfile.skills')}
          <span className='RedStar'>*</span>
        </label>
        <Select
          isMulti
          isSearchable
          name='skills'
          value={skills}
          styles={selectStyles}
          placeholder={t('jobseekerEditProfile.selectSkills')}
          className={`${errors.skills && 'input-error-react-select'}`}
          noOptionsMessage={() => t('jobseekerEditProfile.noOptionsFound')}
          onChange={selectedOptions => handleFieldChange('skills', selectedOptions, 12)}
          options={skill_list.map(i => ({
            value: i.id,
            label: i.name,
          }))}
        />
        {
          errors.skills && (
            <div className='text-danger'>{errors.skills}</div>
          )
        }
      </div>
      <div className='mt-4'>
        {
          languages.map((i, index) => (
            <>
              <div className='row'>
                <div className='col-lg-4 mb-4 form-outline DashBoardInputBx DashBoardCreatBx'>
                  <label id={`lng_${index}`} className='form-label' htmlFor={`lng_${index}`}>
                    {t('jobseekerEditProfile.language')}
                    {(i.lng || i.lng_skill) && <span className='RedStar'>*</span>}
                  </label>
                  <select
                    value={i.lng}
                    name={`lng_${index}`}
                    aria-label='Default select example'
                    onChange={e => handleChange(e, index)}
                    className={`form-select ${errors.languages[index]?.lng && 'input-error'}`}
                  >
                    <option value=''>
                      {t('jobseekerEditProfile.selectLanguage')}
                    </option>
                    {
                      langs.map(i => (
                        <option key={i.id} value={i.id}>
                          {i.name}
                        </option>
                      ))
                    }
                  </select>
                  {
                    errors.languages[index]?.lng && (
                      <div className='text-danger'>{errors.languages[index]?.lng}</div>
                    )
                  }
                </div>
                <div className='col-lg-4 mb-4 form-outline DashBoardInputBx DashBoardCreatBx'>
                  <label id={`lng_skill_${index}`} className='form-label' htmlFor={`lng_skill_${index}`}>
                    {t('jobseekerEditProfile.proficiency')}
                    {(i.lng || i.lng_skill) && <span className='RedStar'>*</span>}
                  </label>
                  <select
                    value={i.lng_skill}
                    name={`lng_skill_${index}`}
                    aria-label='Default select example'
                    onChange={e => handleChange(e, index)}
                    className={`form-select ${errors.languages[index]?.lng_skill && 'input-error'}`}
                  >
                    <option value=''>
                      {t('jobseekerEditProfile.selectProficiency')}
                    </option>
                    {
                      lang_skills.map(i => (
                        <option key={i.id} value={i.id}>
                          {i.name}
                        </option>
                      ))
                    }
                  </select>
                  {
                    errors.languages[index]?.lng_skill && (
                      <div className='text-danger'>{errors.languages[index]?.lng_skill}</div>
                    )
                  }
                </div>
                <div className='mb-4 col-lg-4 text-center'>
                  {
                    index === languages.length - 1 && languages.length < 5
                      ? (
                        <button
                          type='button'
                          onClick={handleAddLanguage}
                          className='btn btn-primary button1 mt-lg-2'
                        >
                          {t('jobseekerEditProfile.addMoreButton')}
                        </button>
                      ) : (
                        <button
                          type='button'
                          className='btn btn-danger button2 mt-lg-2'
                          onClick={() => handleRemoveLanguage(index)}
                        >
                          {t('jobseekerEditProfile.removeButton')}
                        </button>
                      )
                  }
                </div>
              </div>
            </>
          ))
        }
      </div>
      <div className='mb-4 row align-items-center'>
        <div className='col-lg-4 form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
          <label style={{ height: '68px', display: 'flex', alignItems: 'center' }}>
            <input
              type='checkbox'
              name='driver_license'
              checked={driver_license}
              className='me-2 checkboxInput'
              onChange={e => handleChange(e)}
            />
            {t('jobseekerEditProfile.driverLicense')}
          </label>
        </div>
        {
          driver_license != 0 && (
            <div className='col-lg-8 form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
              <label id='driver_category' className='form-label' htmlFor='driver_category'>
                {t('jobseekerEditProfile.driverCategory')}
                <span className='RedStar'>*</span>
              </label>
              <Select
                isMulti
                isSearchable
                styles={selectStyles}
                name='driver_category'
                value={driver_category}
                placeholder={t('jobseekerEditProfile.selectDriverCategory')}
                noOptionsMessage={() => t('jobseekerEditProfile.noOptionsFound')}
                className={`${errors.driver_category && 'input-error-react-select'}`}
                onChange={selectedOptions => handleFieldChange('driver_category', selectedOptions)}
                options={driver_categories.map(i => ({
                  value: i.id,
                  label: i.name,
                }))}
              />
              {
                errors.driver_category && (
                  <div className='text-danger'>{errors.driver_category}</div>
                )
              }
            </div>
          )
        }
        <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
          <label id='visibility' className='form-label' htmlFor='visibility'>
            {t('jobseekerEditProfile.visibility')}
            <span className='RedStar'>*</span>
          </label>
          <Select
            isSearchable
            name='visibility'
            value={visibility}
            styles={selectStyles}
            placeholder={t('jobseekerEditProfile.selectVisibility')}
            noOptionsMessage={() => t('jobseekerEditProfile.noOptionsFound')}
            className={`${errors.visibility && 'input-error-react-select'}`}
            onChange={selectedOptions => handleFieldChange('visibility', selectedOptions)}
            options={visibilities.map(i => ({
              value: i.id,
              label: i.name,
            }))}
          />
          {
            errors.visibility && (
              <div className='text-danger'>{errors.visibility}</div>
            )
          }
        </div>
      </div>
    </>
  );
};

export default AdditionalInfoBlock;
