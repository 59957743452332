/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getNameFromArray } from '../../../helpers';

const months = [
  { 'id': 1, 'name': 'Січень' },
  { 'id': 2, 'name': 'Лютий' },
  { 'id': 3, 'name': 'Березень' },
  { 'id': 4, 'name': 'Квітень' },
  { 'id': 5, 'name': 'Травень' },
  { 'id': 6, 'name': 'Червень' },
  { 'id': 7, 'name': 'Липень' },
  { 'id': 8, 'name': 'Серпень' },
  { 'id': 9, 'name': 'Вересень' },
  { 'id': 10, 'name': 'Жовтень' },
  { 'id': 11, 'name': 'Листопад' },
  { 'id': 12, 'name': 'Грудень' },
];
const education_level = [
  { 'name': 'Доктор наук', 'id': 5 },
  { 'name': 'Кандидат наук', 'id': 4 },
  { 'name': 'Магістр', 'id': 3 },
  { 'name': 'Бакалавр', 'id': 2 },
  { 'name': 'Вища', 'id': 1 },
  { 'name': 'Незакінчена вища', 'id': 6 },
  { 'name': 'Середня спеціальна', 'id': 8 },
  { 'name': 'Середня', 'id': 7 },
];

const EducationInfo = ({ cvDetails }) => {
  const { t } = useTranslation('global');

  const { educations = [], additional_educations = [] } = cvDetails;

  return (
    <div className='educationSection'>
      <h4>
        {t('CVCard.education')}
      </h4>
      <div className='educationBox'>
        {
          educations?.map(i => (
            <div key={i.id} className='row'>
              <p className='col-lg-3'>
                {getNameFromArray(i.from_month_edu, months)}{' '}{i.from_year_edu}{' - '}
                {
                  i.still_learning
                    ? t('CVCard.stillLearning')
                    : `${getNameFromArray(i.to_month_edu, months)} ${i.to_year_edu}`
                }
              </p>
              <div className='col-lg-9'>
                <h5>
                  {getNameFromArray(i.edu_type, education_level)}
                  {', '}
                  {i.specialty.label}
                </h5>
                <h6>
                  {i.institution.label}
                </h6>
                {
                  i.faculty && (
                    <p>
                      {i.faculty.label}
                    </p>
                  )
                }
              </div>
            </div>
          ))
        }
      </div>
      {
        additional_educations?.length > 0 && (
          <>
            <h4>
              {t('CVCard.additionalEducation')}
            </h4>
            <div className='additionalEducationBox'>
              {
                additional_educations?.map(i => (
                  <div key={i.id} className='row'>
                    <p className='col-lg-3'>
                      {/* {getNameFromArray(i.from_month_edu, months)}{' '}{i.from_year_edu}{' - '}
                        {
                          i.still_learning
                            ? t('CVCard.stillLearning')
                            : `${getNameFromArray(i.to_month_edu, months)} ${i.to_year_edu}`
                        } */}
                    </p>
                    <div className='col-lg-9'>
                      <h5>
                        {i.course_name}
                      </h5>
                      {
                        i.certificate && (
                          <div className='profileImageBox'>
                            <img
                              alt='certificate'
                              src={i.certificate}
                              className='profileImage'
                            />
                          </div>
                        )
                      }
                      {
                        i.course_link && (
                          <a href={i.course_link} target='_blank' rel='noopener noreferrer nofollow'>
                            {t('CVCard.courseLink')}
                          </a>
                        )
                      }
                    </div>
                  </div>
                ))
              }
            </div>
          </>
        )
      }
    </div>
  );
};

export default EducationInfo;
