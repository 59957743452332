/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
// helpers
import { formatPhoneNumber } from '../../../helpers';
// mui
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const currencies = {
  '1': '₴',
  '2': '$',
  '3': '€',
};

const categories = [
  {
    'id': 7,
    'name': 'Автомобільний бізнес',
    'slug': 'automotive-business',
  },
  {
    'id': 4,
    'name': 'Адміністративний персонал',
    'slug': 'administrative-personnel',
  },
  {
    'id': 5,
    'name': 'Банки, інвестиції, лізинг',
    'slug': 'banks-investments-finance',
  },
  {
    'id': 8,
    'name': 'Безпека',
    'slug': 'security',
  },
  {
    'id': 20,
    'name': 'Будівництво, нерухомість',
    'slug': 'construction-real-estate',
  },
  {
    'id': 2,
    'name': 'Бухгалтерія, управлінський облік, фінанси підприємства',
    'slug': 'accounting-management-accounting-corporate-finance',
  },
  {
    'id': 10,
    'name': 'Видобуток сировини',
    'slug': 'raw-materials',
  },
  {
    'id': 18,
    'name': 'Виробництво, сільське господарство',
    'slug': 'manufacturing-agriculture',
  },
  {
    'id': 9,
    'name': 'Вищий менеджмент',
    'slug': 'management',
  },
  {
    'id': 16,
    'name': 'Державна служба, некомерційні організації',
    'slug': 'government-ngos',
  },
  {
    'id': 27,
    'name': 'Домашній персонал',
    'slug': 'domestic-staff',
  },
  {
    'id': 26,
    'name': 'Закупівлі',
    'slug': 'procurement',
  },
  {
    'id': 25,
    'name': 'Інсталяція і сервіс',
    'slug': 'installation-and-service',
  },
  {
    'id': 1,
    'name': 'Інформаційні технології, інтернет, телеком',
    'slug': 'it-internet-telecom',
  },
  {
    'id': 12,
    'name': 'Консультування',
    'slug': 'consulting',
  },
  {
    'id': 3,
    'name': 'Маркетинг, реклама, PR',
    'slug': 'marketing-advertising-pr',
  },
  {
    'id': 13,
    'name': 'Медицина, фармацевтика',
    'slug': 'medicine-pharmaceuticals',
  },
  {
    'id': 11,
    'name': 'Мистецтво, розваги, мас-медіа',
    'slug': 'art-entertainment-media',
  },
  {
    'id': 14,
    'name': 'Наука, освіта',
    'slug': 'science-education',
  },
  {
    'id': 15,
    'name': 'Початок кар\'єри, студенти',
    'slug': 'career-starters-students',
  },
  {
    'id': 17,
    'name': 'Продажі',
    'slug': 'sales',
  },
  {
    'id': 29,
    'name': 'Робітничий персонал',
    'slug': 'maintenance-and-operations-personnel',
  },
  {
    'id': 24,
    'name': 'Спортивні клуби, фітнес, салони краси',
    'slug': 'sports-clubs-fitness-clubs-beauty-salons',
  },
  {
    'id': 19,
    'name': 'Страхування',
    'slug': 'insurance',
  },
  {
    'id': 21,
    'name': 'Транспорт, логістика',
    'slug': 'transport-logistics',
  },
  {
    'id': 22,
    'name': 'Туризм, готелі, ресторани',
    'slug': 'tourism-hotels-restaurants',
  },
  {
    'id': 6,
    'name': 'Управління персоналом, тренінги',
    'slug': 'human-resources-training',
  },
  {
    'id': 23,
    'name': 'Юристи',
    'slug': 'lawyers',
  },
];

const getCategoryName = id => {
  for (let i = 0; i < categories.length; i++) {
    if (categories[i].id == id) {
      return categories[i].name;
    }
  }
};

const getFormatedMessengerLink = (type, link) => {
  let formattedLink;

  switch (type) {
    case 'WhatsApp':
      formattedLink = `https://wa.me/${link.replace(/\D/g, '')}`;
      break;
    case 'Signal':
      formattedLink = `https://signal.me/#p/${link.replace(/\D/g, '')}`;
      break;
    case 'Viber':
      formattedLink = `viber://chat?number=${link.replace(/\D/g, '')}`;
      break;
    case 'Telegram':
      formattedLink = `https://t.me/${link}`;
      break;
    case 'Skype':
      formattedLink = `skype:${link}?chat`;
      break;
    case 'Discord':
      formattedLink = `https://discord.com/users/${link}`;
      break;
    default:
      formattedLink = link;
  }

  return formattedLink;
};

const calculateAge = birthday => {
  const birthDate = new Date(birthday);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

const PersonalInfo = ({ cvDetails }) => {
  const { t } = useTranslation('global');

  const {
    city = '',
    gender = '',
    birthday = '',
    position = '',
    last_name = '',
    work_type = [],
    first_name = '',
    salary_max = '',
    salary_min = '',
    middle_name = '',
    relocations = [],
    social_links = [],
    email_address = '',
    profile_image = '',
    video_cv_link = '',
    contact_number = '',
    messenger_links = [],
    salary_currency = '',
    contact_number_2 = '',
    contact_number_3 = '',
    desired_category = [],
  } = cvDetails;

  return (
    <div className='personalInfoSection'>
      <div>
        <h1 className='mb-4'>
          {position.label}
        </h1>
        <div className='personalInfoBox'>
          <div className='genderAgeRegionBox'>
            <p>
              <FiberManualRecordIcon sx={{ mr: '8px', width: '10px', height: '10px', color: '#294A9C' }} />
              {gender === '1' ? t('CVCard.male') : t('CVCard.female')}
            </p>
            <p>
              <FiberManualRecordIcon sx={{ mr: '8px', width: '10px', height: '10px', color: '#294A9C' }} />
              {t('CVCard.age')} - {calculateAge(birthday)}
            </p>
            <p>
              <FiberManualRecordIcon sx={{ mr: '8px', width: '10px', height: '10px', color: '#294A9C' }} />
              {city.label}
            </p>
          </div>
          <h2>
            {last_name}{' '}{first_name}{' '}{middle_name && middle_name}
          </h2>
          <h3>
            {salary_min ? `${parseInt(salary_min).toLocaleString('uk-UA')} - ` : ''}
            {salary_max && parseInt(salary_max).toLocaleString('uk-UA')}
            {' '}
            {salary_currency && currencies[salary_currency]}
            {salary_currency && `/${t('CVCard.month')}`}
          </h3>
        </div>
        <div className='contactsBox'>
          <p>
            <i className='fa-solid fa-phone' />
            <a href={`tel:${contact_number_2}`}>
              {formatPhoneNumber(contact_number)}
            </a>
          </p>
          {
            contact_number_2 && (
              <p>
                <i className='fa-solid fa-phone' />
                <a href={`tel:${contact_number_2}`}>
                  {formatPhoneNumber(contact_number_2)}
                </a>
              </p>
            )
          }
          {
            contact_number_3 && (
              <p>
                <i className='fa-solid fa-phone' />
                <a href={`tel:${contact_number_3}`}>
                  {formatPhoneNumber(contact_number_3)}
                </a>
              </p>
            )
          }
          <p>
            <i className='fa-solid fa-envelope' />
            <a href={`mailto:${email_address}`}>
              {email_address}
            </a>
          </p>
          {
            video_cv_link && (
              <p>
                <i className='fa-solid fa-video' />
                <a href={video_cv_link} target='_blank' rel='noopener noreferrer nofollow'>
                  {t('CVCard.videoCV')}
                </a>
              </p>
            )
          }
        </div>
        {
          (messenger_links?.length > 0 || social_links?.length > 0) && (
            <div className='socialLinksBox'>
              <div className='messengersLinks'>
                {
                  messenger_links?.map(i => (
                    <a key={i.id} href={getFormatedMessengerLink(i.type, i.link)} target='_blank' rel='noopener noreferrer nofollow'>
                      <button className='btn' title={i.type}>
                        <i className={`fa-brands fa-${i.type === 'Signal' ? 'signal-messenger' : i.type.toLowerCase()}`} />
                      </button>
                    </a>
                  ))
                }
              </div>
              <div className='socialLinks'>
                {
                  social_links?.map(i => (
                    <a key={i.id} href={i.link} target='_blank' rel='noopener noreferrer nofollow'>
                      <button className='btn' title={i.type}>
                        <i className={`fa-brands fa-${i.type === 'Twitter' ? 'x-twitter' : i.type.toLowerCase()}`} />
                      </button>
                    </a>
                  ))
                }
              </div>
            </div>
          )
        }
        <div className='wishesInfoBox'>
          <p>
            <i className='fa-solid fa-sitemap' />
            <span>{t('CVCard.category')}{': '}</span>
            {
              Array.from(new Set(
                desired_category?.map(i => getCategoryName(i.category)),
              )).join('; ')
            }
          </p>
          <p>
            <i className='fa-solid fa-sitemap' />
            <span>{t('CVCard.subCategory')}{': '}</span>
            {
              Array.from(new Set(
                desired_category?.flatMap(category =>
                  category.sub_category.map(subCategory => subCategory.label),
                ),
              )).join(', ')
            }
          </p>
          <p>
            <i className='fa-sharp fa-solid fa-location-dot' />
            <span>{t('CVCard.relocations')}{': '}</span>
            {relocations?.map(i => i.label).join('; ')}
          </p>
          <p>
            <i className='fa-solid fa-clock' />
            <span>{t('CVCard.relocations')}{': '}</span>
            {work_type?.map(i => i.label).join(', ')}
          </p>
        </div>
      </div>
      <div className='profileImageBox'>
        <img
          alt='profile image'
          className='profileImage'
          src={profile_image ? profile_image : '/Images/jobseekerSide/dummy-profile.png'}
        />
      </div>
    </div>
  );
};

export default PersonalInfo;
