/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
// helpers
import { getNameFromArray } from '../../../helpers';
// mui
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const langs = [
  {
    'id': 74,
    'name': 'Абазинська',
    'slug': 'abq',
  },
  {
    'id': 68,
    'name': 'Абхазька',
    'slug': 'ab',
  },
  {
    'id': 176,
    'name': 'Аварська',
    'slug': 'av',
  },
  {
    'id': 1,
    'name': 'Азербайджанська',
    'slug': 'az',
  },
  {
    'id': 2,
    'name': 'Албанська',
    'slug': 'sq',
  },
];

const lng_skills = [
  {
    'id': 6,
    'name': 'A1 (початковий) або вище',
    'slug': 'A1',
  },
  {
    'id': 7,
    'name': 'A2 (елементарний) або вище',
    'slug': 'A2',
  },
  {
    'id': 8,
    'name': 'B1 (середній) або вище',
    'slug': 'B1',
  },
  {
    'id': 9,
    'name': 'B2 (вищий середній) або вище',
    'slug': 'B2',
  },
  {
    'id': 10,
    'name': 'C1 (просунутий) або вище',
    'slug': 'C1',
  },
  {
    'id': 11,
    'name': 'C2 (доскональний) або вище',
    'slug': 'C2',
  },
  {
    'id': 12,
    'name': 'L1 рідна',
    'slug': 'L1',
  },
];

const LanguagesInfo = ({ cvDetails }) => {
  const { t } = useTranslation('global');

  const { languages = [] } = cvDetails;

  return (
    <div className='languagesSection'>
      <h4>
        {t('CVCard.additionalLanguages')}
      </h4>
      <div className='languagesBox'>
        {
          languages?.map(i => (
            <p key={i.id}>
              <FiberManualRecordIcon sx={{ mr: '8px', width: '10px', height: '10px', color: '#294A9C' }} />
              {getNameFromArray(i.lng, langs)}
              {' - '}
              <span>
                {getNameFromArray(i.lng_skill, lng_skills)}
              </span>
            </p>
          ))
        }
      </div>
    </div>
  );
};

export default LanguagesInfo;
