import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
// api
import ApiKey from '../api/ApiKey';
import BaseApi from '../api/BaseApi';
// components
import JSSidebar from './JSSidebar';
import Footer from '../element/Footer';
import NavBar from '../element/NavBar';
// helpers
import { logOutIfTokenExpired, resultInfo, scrollToElement } from '../helpers';

const JSVideoCV = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');

  const [path, setPath] = useState('');
  const [video, setVideo] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ path: '' });

  const getData = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        BaseApi + '/candidates/addVideoCv',
        null,
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      setLoading(false);
      setVideo(response.data.response.path);
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      }

      console.error('Cannot get video data', error);
    }
  };

  const handleVideoRemove = async () => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: t('jobseekerVideoCV.no'),
        confirmButtonText: t('jobseekerVideoCV.yes'),
        text: t('jobseekerVideoCV.confirmTxtVideoRemove'),
        title: t('jobseekerVideoCV.confirmTitleVideoRemove'),
      });

      if (confirmationResult.isConfirmed) {
        setLoading(true);

        await axios.post(
          BaseApi + '/candidates/deleteVideo',
          null,
          {
            headers: {
              key: ApiKey,
              token: tokenKey,
              'Content-Type': 'application/json',
            },
          },
        );

        setLoading(false);
        getData();
        window.scroll(0, 0);

        resultInfo('success', t('jobseekerVideoCV.successTxtVideoRemove'), t('jobseekerVideoCV.close'));
      }
    } catch (error) {
      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        resultInfo('error', t('jobseekerVideoCV.failedTxtVideoRemove'), t('jobseekerVideoCV.close'));
      }

      console.error(error);
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;

    setPath(value);
    setErrors(prev => ({
      ...prev,
      [name]: '',
    }));
  };

  const handleSubmit = async () => {
    try {
      const newErrors = {};
      const urlFormat = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

      if (!urlFormat.test(path.trim())) {
        newErrors.path = t('jobseekerVideoCV.urlInvalid');
        scrollToElement('path');
      }

      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        const confirmationResult = await Swal.fire({
          icon: 'question',
          showCancelButton: true,
          cancelButtonText: t('jobseekerVideoCV.no'),
          confirmButtonText: t('jobseekerVideoCV.yes'),
          title: t('jobseekerVideoCV.confirmTitleVideoUpload'),
        });

        if (confirmationResult.isConfirmed) {
          setLoading(true);

          const response = await axios.post(
            BaseApi + '/candidates/addVideoCv',
            { path },
            {
              headers: {
                key: ApiKey,
                token: tokenKey,
                'Content-Type': 'application/json',
              },
            },
          );

          setLoading(false);

          if (response.data.status === 200) {
            resultInfo('success', t('jobseekerVideoCV.successTxtVideoUpload'), t('jobseekerVideoCV.close'));

            setPath('');
            getData();
          }
          else if (response.data.status === 400) {
            navigate('/');

            Cookies.remove('fname');
            Cookies.remove('user_id');
            Cookies.remove('user_type');
            Cookies.remove('tokenClient');

            resultInfo('warning', response.data.message, t('jobseekerVideoCV.close'));
          }
          else if (response.data.status === 500) {
            resultInfo('warning', response.data.message, t('jobseekerVideoCV.close'));
          } else {
            resultInfo('error', response.data.message, t('jobseekerVideoCV.close'));
          }
        }
      }
    } catch (error) {
      setLoading(false);

      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      } else {
        Swal.fire({
          icon: 'error',
          confirmButtonText: t('jobseekerVideoCV.close'),
          text: t('jobseekerExperience.failedTxtVideoUpload'),
          title: t('jobseekerExperience.failedTitleVideoUpload'),
        });
      }

      console.error(error);
    }
  };

  useEffect(() => {
    if (!tokenKey) {
      navigate('/user/jobseekerlogin');
    } else {
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]); // eslint-disable-line

  return (
    <>
      <NavBar />
      <div className='container editProfile'>
        <div className='row'>
          <div className='col-lg-3'>
            <JSSidebar />
          </div>
          {
            loading
              ? (
                <div className='loader-container'></div>
              ) : (
                <div
                  className='col-lg-9 mb-5'
                  style={{
                    borderLeft: '2px solid #e6e8e7',
                    borderRight: '2px solid #e6e8e7',
                  }}
                >
                  <div className='mx-3 d-flex PageHeader'>
                    <img src='/Images/employerSide/icon8color.png' alt='' />
                    <h3 className=''>{t('jobseekerVideoCV.videoCV')}</h3>
                  </div>
                  <form>
                    <div className='mb-5 mt-5 mx-4'>
                      <div className='form-outline mb-4 DashBoardInputBx'>
                        <label id='path' className='form-label' htmlFor='form3Example3'>
                          {t('jobseekerVideoCV.addLink')}
                        </label>
                        <input
                          name='path'
                          type='text'
                          value={path}
                          className='form-control'
                          onChange={e => handleChange(e)}
                          placeholder={t('jobseekerVideoCV.addLink')}
                        />
                        {
                          errors.path && (
                            <div className='text-danger'>{errors.path}</div>
                          )
                        }
                        <div id='emailHelp' className='form-text'>
                          {t('jobseekerVideoCV.belowTxt')}
                        </div>
                      </div>
                      <div className='mt-4 mb-4 bottomButtons'>
                        <button
                          type='button'
                          onClick={handleSubmit}
                          className='btn btn-primary button1'
                        >
                          {
                            video
                              ? t('jobseekerVideoCV.updateButton')
                              : t('jobseekerVideoCV.addButton')
                          }
                        </button>
                        <button
                          type='button'
                          className='btn btn-primary button2'
                          onClick={() => navigate('/candidates/my-cv-list')}
                        >
                          {t('jobseekerVideoCV.cancelButton')}
                        </button>
                      </div>
                      {
                        video && (
                          <div className='form-outline mb-5 DashBoardInputBx'>
                            <label htmlFor='formFile' className='form-label'>
                              {t('jobseekerVideoCV.uploadedVideo')}
                            </label>
                            <div className='ChoosPlanBx checkCertificate'>
                              <div className='EPJobseekerCertificatesDetails EPJobseekerVidio'>
                                <ul>
                                  <li>
                                    <i>
                                      <ReactPlayer
                                        controls
                                        url={video}
                                        width='100%'
                                        allowfullscreen={true}
                                      />
                                    </i>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <button
                              type='button'
                              onClick={handleVideoRemove}
                              className='mt-4 btn btn-primary button1'
                            >
                              {t('jobseekerVideoCV.remove')}
                            </button>
                          </div>
                        )
                      }
                    </div>
                  </form>
                </div>
              )
          }
        </div>
      </div>
      <Footer />
    </>
  );
};

export default JSVideoCV;
