/* eslint-disable react/prop-types */
import React from 'react';
import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';
// api
import { getCities } from '../../../employersSide/api/getCities';
// helpers
import { debounceAsync, selectStyles } from '../../../helpers';

const PersonalInformationBlock = ({ errors, setErrors, directories, profileBody, handleChange, setProfileBody }) => {
  const { t } = useTranslation('global');

  const { countries = [] } = directories;

  const {
    city = '',
    gender = '',
    country = '',
    birthday = '',
    last_name = '',
    first_name = '',
    middle_name = '',
  } = profileBody;

  const getCitiesOptions = async inputValue => {
    if (!country || inputValue.length < 2) {
      return [];
    }

    try {
      const cities = await getCities(parseInt(country), inputValue);

      if (cities.data.response?.length === 0) {
        throw new Error('No options found');
      }

      const options = cities.data.response.map(city => ({
        value: city.id,
        label: `${city.name}${city.region && `, ${city.region}`}`,
      }));

      return options;
    } catch (error) {
      console.error('Error fetching cities:', error);
      return [];
    }
  };

  const handleCityChange = selectedOption => {
    setProfileBody(prevState => ({
      ...prevState,
      city: selectedOption,
    }));

    setErrors(prev => ({
      ...prev,
      city: '',
    }));
  };

  const debouncedGetCitiesOptions = debounceAsync(getCitiesOptions, 500);

  return (
    <>
      <h3 className='mb-4'>{t('jobseekerEditProfile.personalInformation')}</h3>
      <div className='form-outline mb-4 DashBoardInputBx'>
        <label id='first_name' className='form-label' htmlFor='first_name'>
          {t('jobseekerEditProfile.firstName')}
          <span className='RedStar'>*</span>
        </label>
        <input
          type='text'
          name='first_name'
          value={first_name}
          onChange={handleChange}
          placeholder={t('jobseekerEditProfile.firstName')}
          className={`form-control ${errors.first_name && 'input-error'}`}
        />
        {
          errors.first_name && (
            <div className='text-danger'>{errors.first_name}</div>
          )
        }
      </div>
      <div className='form-outline mb-4 DashBoardInputBx'>
        <label id='last_name' className='form-label' htmlFor='last_name'>
          {t('jobseekerEditProfile.lastName')}
          <span className='RedStar'>*</span>
        </label>
        <input
          type='text'
          name='last_name'
          value={last_name}
          onChange={handleChange}
          placeholder={t('jobseekerEditProfile.lastName')}
          className={`form-control ${errors.last_name && 'input-error'}`}
        />
        {
          errors.last_name && (
            <div className='text-danger'>{errors.last_name}</div>
          )
        }
      </div>
      <div className='form-outline mb-4 DashBoardInputBx'>
        <label id='middle_name' className='form-label' htmlFor='middle_name'>
          {t('jobseekerEditProfile.middleName')}
        </label>
        <input
          type='text'
          name='middle_name'
          value={middle_name}
          onChange={handleChange}
          placeholder={t('jobseekerEditProfile.middleName')}
          className={`form-control ${errors.middle_name && 'input-error'}`}
        />
        {
          errors.middle_name && (
            <div className='text-danger'>{errors.middle_name}</div>
          )
        }
      </div>
      <div className='mb-4'>
        <div className={`${errors.gender && 'input-error'} radioInput form-outline DashBoardInputBx`}>
          <label id='gender' className='form-label' htmlFor='form3Example3'>
            {t('jobseekerEditProfile.gender')}
            <span className='RedStar'>*</span>
          </label>
          <div>
            <label className='me-4' htmlFor='female'>
              <input
                id='female'
                type='radio'
                name='gender'
                value='female'
                onChange={handleChange}
                checked={gender === '0'}
              />
              {t('jobseekerEditProfile.female')}
            </label>
            <label htmlFor='male'>
              <input
                id='male'
                type='radio'
                value='male'
                name='gender'
                onChange={handleChange}
                checked={gender === '1'}
              />
              {t('jobseekerEditProfile.male')}
            </label>
          </div>
        </div>
        {
          errors.gender && (
            <div className='text-danger'>{errors.gender}</div>
          )
        }
      </div>
      <div className='form-outline mb-4 DashBoardInputBx'>
        <label id='birthday' className='form-label' htmlFor='birthday'>
          {t('jobseekerEditProfile.birthday')}
          <span className='RedStar'>*</span>
        </label>
        <input
          type='date'
          name='birthday'
          min='1930-01-01'
          value={birthday}
          onChange={handleChange}
          max={new Date().toISOString().split('T')[0]}
          placeholder={t('jobseekerEditProfile.birthday')}
          className={`form-control ${errors.birthday && 'input-error'}`}
        />
        {
          errors.birthday && (
            <div className='text-danger'>{errors.birthday}</div>
          )
        }
      </div>
      <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
        <label id='country' className='form-label' htmlFor='country'>
          {t('jobseekerEditProfile.country')}
          <span className='RedStar'>*</span>
        </label>
        <select
          name='country'
          value={country}
          onChange={e => handleChange(e)}
          aria-label='Default select example'
          className={`form-select ${errors.country && 'input-error'}`}
        >
          <option value=''>
            {t('jobseekerEditProfile.selectCountry')}
          </option>
          {
            countries.map((i, index) => (
              <option value={i.id} key={index}>
                {i.name}
              </option>
            ))
          }
        </select>
        {
          errors.country && (
            <div className='text-danger'>{errors.country}</div>
          )
        }
      </div>
      {
        country && (
          <div className='form-outline mb-5 DashBoardInputBx DashBoardCreatBx'>
            <label id='city' className='form-label' htmlFor='city'>
              {t('jobseekerEditProfile.city')}
              <span className='RedStar'>*</span>
            </label>
            <AsyncSelect
              value={city}
              defaultOptions
              styles={selectStyles}
              onChange={handleCityChange}
              loadOptions={debouncedGetCitiesOptions}
              placeholder={t('jobseekerEditProfile.enterCityName')}
              className={`${errors.city && 'input-error-react-select'}`}
              loadingMessage={() => t('jobseekerEditProfile.pleaseWait')}
              noOptionsMessage={
                ({ inputValue }) => inputValue.length >= 2
                  ? t('jobseekerEditProfile.noOptionsFound')
                  : t('jobseekerEditProfile.enterCityName')
              }
            />
            {
              errors.city && (
                <div className='text-danger'>{errors.city}</div>
              )
            }
          </div>
        )
      }
    </>
  );
};

export default PersonalInformationBlock;
