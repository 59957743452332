/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';

const AboutYourselfInfo = ({ cvDetails }) => {
  const { t } = useTranslation('global');

  const { about_yourself = '' } = cvDetails;

  return (
    <div className='aboutYourselfSection'>
      <h4>
        {t('CVCard.aboutYourself')}
      </h4>
      <div className='standartListStyle'>
        {HTMLReactParser(about_yourself)}
      </div>
    </div>
  );
};

export default AboutYourselfInfo;
