/* eslint-disable react/prop-types */
import axios from 'axios';
import Cookies from 'js-cookie';
import Select from 'react-select';
import JoditEditor from 'jodit-react';
import React, { useRef } from 'react';
import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';
// api
import ApiKey from '../../../api/ApiKey';
import BaseApi from '../../../api/BaseApi';
import { getCities } from '../../../employersSide/api/getCities';
// helpers
import { debounceAsync, editorConfig, selectStyles } from '../../../helpers';

const WorkExperienceBlock = ({ errors, setErrors, directories, profileBody, handleChange, setProfileBody }) => {
  const editor = useRef(null);
  const { t } = useTranslation('global');
  const tokenKey = Cookies.get('tokenClient');

  const { experiences = [] } = profileBody;
  const { months = [], yearList = [], countries = [], industries = [] } = directories;

  const getCitiesOptions = async (inputValue, index) => {
    if (!experiences[index].exp_country || inputValue.length < 2) {
      return [];
    }

    try {
      const cities = await getCities(parseInt(experiences[index].exp_country), inputValue);

      if (cities.data.response?.length === 0) {
        throw new Error('No options found');
      }

      const options = cities.data.response.map(city => ({
        value: city.id,
        label: `${city.name}${city.region && `, ${city.region}`}`,
      }));

      return options;
    } catch (error) {
      console.error('Error fetching cities:', error);
      return [];
    }
  };

  const getSelectOptions = async (endpoint, inputValue) => {
    if (inputValue.length < 2) {
      return [];
    }

    try {
      const response = await axios.post(
        BaseApi + endpoint,
        { query: inputValue },
        {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.data.response?.length === 0) {
        throw new Error('No options found');
      }

      const options = response.data.response.map(position => ({
        value: position.id,
        label: position.name,
      }));

      return options;
    } catch (error) {
      if (endpoint === '/candidates/getCompanies') {
        return [{ value: inputValue, label: inputValue }];
      }

      console.error('Error fetching cities:', error);
      return [];
    }
  };

  const handleAddExperience = () => {
    const newExperience = {
      exp_city: '',
      industry: [],
      position: '',
      exp_country: 5,
      to_year_exp: '',
      achievements: '',
      organization: '',
      still_working: 0,
      to_month_exp: '',
      from_year_exp: '',
      from_month_exp: '',
    };

    setProfileBody(prevState => ({
      ...prevState,
      experiences: [...prevState.experiences, newExperience],
    }));
  };

  const handleSelectChange = (type, selectedOption, index) => {
    setProfileBody(prevState => {
      const newExperiences = [...prevState.experiences];
      newExperiences[index][type] = selectedOption;

      return {
        ...prevState,
        experiences: newExperiences,
      };
    });

    setErrors(prev => {
      const newErrors = { ...prev };

      if (!newErrors.experiences[index]) {
        newErrors.experiences[index] = {};
      }

      newErrors.experiences[index][type] = '';

      return newErrors;
    });
  };

  const handleIndustryChange = (selectedOptions, index) => {
    if (selectedOptions.length > 5) {
      setErrors(prev => ({
        ...prev,
        experiences: prev.experiences.map((error, i) =>
          i === index ? { ...error, industry: t('jobseekerEditProfile.industryLimit') } : error,
        ),
      }));

      return;
    }

    setProfileBody(prevState => {
      const newExperiences = [...prevState.experiences];
      newExperiences[index].industry = selectedOptions;

      return {
        ...prevState,
        experiences: newExperiences,
      };
    });

    setErrors(prev => {
      const newErrors = { ...prev };

      if (!newErrors.experiences[index]) {
        newErrors.experiences[index] = {};
      }

      newErrors.experiences[index].industry = '';

      return newErrors;
    });
  };

  const handleRemoveExperience = index => {
    setProfileBody(prevState => ({
      ...prevState,
      experiences: prevState.experiences.filter((_, i) => i !== index),
    }));

    setErrors(prevState => ({
      ...prevState,
      experiences: prevState.experiences.filter((_, i) => i !== index),
    }));
  };

  // const handleRemove = async id => {
  //   try {
  //     const confirmationResult = await Swal.fire({
  //       icon: 'question',
  //       showCancelButton: true,
  //       cancelButtonText: t('jobseekerExperience.no'),
  //       confirmButtonText: t('jobseekerExperience.yes'),
  //       text: t('jobseekerExperience.removeConfirmTxt'),
  //       title: t('jobseekerExperience.removeConfirmTitle'),
  //     });

  //     if (id !== null && confirmationResult.isConfirmed) {
  //       const response = await axios.post(
  //         BaseApi + `/candidates/deleteexperience/${id}`,
  //         null,
  //         {
  //           headers: {
  //             key: ApiKey,
  //             token: tokenKey,
  //             'Content-Type': 'application/json',
  //           },
  //         },
  //       );

  //       if (response.data.status === 200) {
  //         getData();

  //         resultInfo('success', t('jobseekerExperience.removeSuccessTitle'), t('jobseekerExperience.close'));

  //         window.scrollTo(0, 0);
  //       } else if (response.data.status === 400) {
  //         navigate('/');

  //         Cookies.remove('fname');
  //         Cookies.remove('user_id');
  //         Cookies.remove('user_type');
  //         Cookies.remove('tokenClient');

  //         resultInfo('warning', response.data.message, t('jobseekerExperience.close'));
  //       }
  //       else if (response.data.status === 500) {
  //         resultInfo('warning', response.data.message, t('jobseekerExperience.close'));
  //       } else {
  //         resultInfo('error', response.data.message, t('jobseekerExperience.close'));
  //       }
  //     }
  //   } catch (error) {
  //     setLoading(false);

  //     if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
  //       navigate('/');

  //       logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
  //     } else {
  //       Swal.fire({
  //         icon: 'error',
  //         text: t('jobseekerExperience.removeFailedTxt'),
  //         confirmButtonText: t('jobseekerExperience.close'),
  //         title: t('jobseekerExperience.removeFailedTitle'),
  //       });
  //     }

  //     console.error(error);
  //   }
  // };

  const debouncedGetCitiesOptions = debounceAsync(getCitiesOptions, 500);
  const debouncedGetSelectOptions = debounceAsync(getSelectOptions, 500);

  return (
    <>
      <h3 className='mb-4'>{t('jobseekerEditProfile.workExperience')}</h3>
      <div className='mt-4 mb-5'>
        {
          experiences.map((i, index) => (
            <>
              <div className={`${index === 0 ? 'mt-4' : 'mt-5'} mb-4 form-outline DashBoardInputBx`}>
                <label id={`position_${index}`} className='form-label' htmlFor={`position_${index}`}>
                  {t('jobseekerEditProfile.position')}
                  <span className='RedStar'>*</span>
                </label>
                <AsyncSelect
                  defaultOptions
                  value={i.position}
                  styles={selectStyles}
                  name={`position_${index}`}
                  placeholder={t('jobseekerEditProfile.enterPosition')}
                  loadingMessage={() => t('jobseekerEditProfile.pleaseWait')}
                  className={`${errors.experiences[index]?.position && 'input-error-react-select'}`}
                  onChange={selectedOption => handleSelectChange('position', selectedOption, index)}
                  loadOptions={inputValue => debouncedGetSelectOptions('/candidates/getPositions', inputValue)}
                  noOptionsMessage={
                    ({ inputValue }) => inputValue.length >= 2
                      ? t('jobseekerEditProfile.noOptionsFound')
                      : t('jobseekerEditProfile.enterPosition')
                  }
                />
                {
                  errors.experiences[index]?.position && (
                    <div className='text-danger'>{errors.experiences[index]?.position}</div>
                  )
                }
              </div>
              <div className='form-outline mb-4 DashBoardInputBx'>
                <label id={`organization_${index}`} className='form-label' htmlFor={`organization_${index}`}>
                  {t('jobseekerEditProfile.organization')}
                  <span className='RedStar'>*</span>
                </label>
                <AsyncSelect
                  defaultOptions
                  styles={selectStyles}
                  value={i.organization}
                  name={`organization_${index}`}
                  placeholder={t('jobseekerEditProfile.enterOrganization')}
                  loadingMessage={() => t('jobseekerEditProfile.pleaseWait')}
                  className={`${errors.experiences[index]?.organization && 'input-error-react-select'}`}
                  onChange={selectedOption => handleSelectChange('organization', selectedOption, index)}
                  loadOptions={inputValue => debouncedGetSelectOptions('/candidates/getCompanies', inputValue)}
                  noOptionsMessage={
                    ({ inputValue }) => inputValue.length >= 2
                      ? t('jobseekerEditProfile.noOptionsFound')
                      : t('jobseekerEditProfile.enterOrganization')
                  }
                />
                {
                  errors.experiences[index]?.organization && (
                    <div className='text-danger'>{errors.experiences[index]?.organization}</div>
                  )
                }
              </div>
              <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
                <label id={`industry_${index}`} className='form-label' htmlFor={`industry_${index}`}>
                  {t('jobseekerEditProfile.industry')}
                  <span className='RedStar'>*</span>
                </label>
                <Select
                  isMulti
                  isSearchable
                  value={i.industry}
                  styles={selectStyles}
                  name={`industry_${index}`}
                  placeholder={t('jobseekerEditProfile.selectIndustry')}
                  options={industries.map(i => ({ value: i.id, label: i.name }))}
                  noOptionsMessage={() => t('jobseekerEditProfile.noOptionsFound')}
                  onChange={selectedOption => handleIndustryChange(selectedOption, index)}
                  className={`${errors.experiences[index]?.industry && 'input-error-react-select'}`}
                />
                {
                  errors.experiences[index]?.industry && (
                    <div className='text-danger'>{errors.experiences[index]?.industry}</div>
                  )
                }
              </div>
              <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
                <label id={`exp_country_${index}`} className='form-label' htmlFor={`exp_country_${index}`}>
                  {t('jobseekerEditProfile.country')}
                  <span className='RedStar'>*</span>
                </label>
                <select
                  value={i.exp_country}
                  name={`exp_country_${index}`}
                  aria-label='Default select example'
                  onChange={e => handleChange(e, index)}
                  className={`form-select ${errors.experiences[index]?.exp_country && 'input-error'}`}
                >
                  <option value=''>
                    {t('jobseekerEditProfile.selectCountry')}
                  </option>
                  {
                    countries.map((i, index) => (
                      <option value={i.id} key={index}>
                        {i.name}
                      </option>
                    ))
                  }
                </select>
                {
                  errors.experiences[index]?.exp_country && (
                    <div className='text-danger'>{errors.experiences[index]?.exp_country}</div>
                  )
                }
              </div>
              {
                i.exp_country && (
                  <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx'>
                    <label id={`exp_city_${index}`} className='form-label' htmlFor={`exp_city_${index}`}>
                      {t('jobseekerEditProfile.city')}
                    </label>
                    <AsyncSelect
                      defaultOptions
                      value={i.exp_city}
                      styles={selectStyles}
                      placeholder={t('jobseekerEditProfile.enterCityName')}
                      loadingMessage={() => t('jobseekerEditProfile.pleaseWait')}
                      loadOptions={inputValue => debouncedGetCitiesOptions(inputValue, index)}
                      className={`${errors.experiences[index]?.exp_city && 'input-error-react-select'}`}
                      onChange={selectedOption => handleSelectChange('exp_city', selectedOption, index)}
                      noOptionsMessage={
                        ({ inputValue }) => inputValue.length >= 2
                          ? t('jobseekerEditProfile.noOptionsFound')
                          : t('jobseekerEditProfile.enterCityName')
                      }
                    />
                    {
                      errors.experiences[index]?.exp_city && (
                        <div className='text-danger'>{errors.experiences[index]?.exp_city}</div>
                      )
                    }
                  </div>
                )
              }
              <div className='row'>
                <div className='col-lg-6'>
                  <p className='mb-4'>
                    {t('jobseekerEditProfile.startOfWork')}
                  </p>
                  <div className='row'>
                    <div className='col-lg-6 form-outline mb-4 DashBoardInputBx'>
                      <label id={`from_month_exp_${index}`} className='form-label' htmlFor={`from_month_exp_${index}`}>
                        {t('jobseekerEditProfile.month')}
                        <span className='RedStar'>*</span>
                      </label>
                      <select
                        value={i.from_month_exp}
                        name={`from_month_exp_${index}`}
                        aria-label='Default select example'
                        onChange={e => handleChange(e, index)}
                        className={`form-select ${errors.experiences[index]?.from_month_exp && 'input-error'}`}
                      >
                        <option selected value=''>
                          {t('jobseekerEditProfile.selectMonth')}
                        </option>
                        {
                          months.map(i => (
                            <option value={i.id} key={i.id}>
                              {i.name}
                            </option>
                          ))
                        }
                      </select>
                      {
                        errors.experiences[index]?.from_month_exp && (
                          <div className='text-danger'>{errors.experiences[index]?.from_month_exp}</div>
                        )
                      }
                    </div>
                    <div className='col-lg-6 form-outline mb-4 DashBoardInputBx'>
                      <label id={`from_year_exp_${index}`} className='form-label' htmlFor={`from_year_exp_${index}`}>
                        {t('jobseekerEditProfile.year')}
                        <span className='RedStar'>*</span>
                      </label>
                      <select
                        value={i.from_year_exp}
                        name={`from_year_exp_${index}`}
                        aria-label='Default select example'
                        onChange={e => handleChange(e, index)}
                        className={`form-select ${errors.experiences[index]?.from_year_exp && 'input-error'}`}
                      >
                        <option selected value=''>
                          {t('jobseekerEditProfile.selectYear')}
                        </option>
                        {
                          Object.entries(yearList).map(([index, value]) => (
                            <option key={index} value={value}>{value}</option>
                          ))
                        }
                      </select>
                      {
                        errors.experiences[index]?.from_year_exp && (
                          <div className='text-danger'>{errors.experiences[index]?.from_year_exp}</div>
                        )
                      }
                    </div>
                  </div>
                </div>
                <div className='col-lg-6'>
                  <p className='mb-4'>
                    {t('jobseekerEditProfile.endOfWork')}
                  </p>
                  <div className='row'>
                    <div className='col-lg-6 form-outline mb-4 DashBoardInputBx'>
                      <label id={`to_month_exp_${index}`} className='form-label' htmlFor={`to_month_exp_${index}`}>
                        {t('jobseekerEditProfile.month')}
                        {!i.still_working && (<span className='RedStar'>*</span>)}
                      </label>
                      <select
                        value={i.to_month_exp}
                        name={`to_month_exp_${index}`}
                        aria-label='Default select example'
                        onChange={e => handleChange(e, index)}
                        className={`form-select ${errors.experiences[index]?.to_month_exp && 'input-error'}`}
                      >
                        <option selected value=''>
                          {t('jobseekerEditProfile.selectMonth')}
                        </option>
                        {
                          months.map(i => (
                            <option value={i.id} key={i.id}>
                              {i.name}
                            </option>
                          ))
                        }
                      </select>
                      {
                        errors.experiences[index]?.to_month_exp && (
                          <div className='text-danger'>{errors.experiences[index]?.to_month_exp}</div>
                        )
                      }
                    </div>
                    <div className='col-lg-6 form-outline mb-4 DashBoardInputBx'>
                      <label id={`to_year_exp_${index}`} className='form-label' htmlFor={`to_year_exp_${index}`}>
                        {t('jobseekerEditProfile.year')}
                        {!i.still_working && (<span className='RedStar'>*</span>)}
                      </label>
                      <select
                        value={i.to_year_exp}
                        name={`to_year_exp_${index}`}
                        aria-label='Default select example'
                        onChange={e => handleChange(e, index)}
                        className={`form-select ${errors.experiences[index]?.to_year_exp && 'input-error'}`}
                      >
                        <option selected value=''>
                          {t('jobseekerEditProfile.selectYear')}
                        </option>
                        {
                          Object.entries(yearList).map(([index, value]) => (
                            <option key={index} value={value}>{value}</option>
                          ))
                        }
                      </select>
                      {
                        errors.experiences[index]?.to_year_exp && (
                          <div className='text-danger'>{errors.experiences[index]?.to_year_exp}</div>
                        )
                      }
                    </div>
                  </div>
                </div>
                <label className='mb-4' style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type='checkbox'
                    className='me-2 checkboxInput'
                    name={`still_working_${index}`}
                    onChange={e => handleChange(e, index)}
                    checked={experiences[index].still_working}
                  />
                  {t('jobseekerEditProfile.stillWorking')}
                </label>
              </div>
              <div className='form-outline mb-4 DashBoardInputBx DashBoardCreatBx APJoditEditor'>
                <label id={`achievements_${index}`} className='form-label' htmlFor={`achievements_${index}`}>
                  {t('jobseekerEditProfile.achievements')}
                  <span className='RedStar'>*</span>
                </label>
                <JoditEditor
                  ref={editor}
                  value={i.achievements}
                  name={`achievements_${index}`}
                  placeholder={t('jobseekerEditProfile.achievements')}
                  onBlur={achievements => handleChange({ target: { name: `achievements_${index}`, value: achievements } }, index)}
                  config={{
                    ...editorConfig,
                    minHeight: '240px',
                    placeholder: t('jobseekerEditProfile.achievementsPlaceholder'),
                  }}
                />
                {
                  errors.experiences[index]?.achievements && (
                    <div className='text-danger'>{errors.experiences[index]?.achievements}</div>
                  )
                }
              </div>
              <div className={`${experiences.length > 1 && index < experiences.length - 1 && 'mb-5'} row`}>
                <div className='removeButtonJobseeker'>
                  <button
                    type='button'
                    className='btn btn-primary button2'
                    disabled={experiences.length === 1}
                    onClick={() => handleRemoveExperience(index)}
                  >
                    {t('jobseekerEditProfile.removeButton')}
                  </button>
                  <button
                    type='button'
                    onClick={handleAddExperience}
                    className='btn btn-primary button1'
                  >
                    {t('jobseekerEditProfile.addMoreButton')}
                  </button>
                </div>
              </div>
              {
                experiences.length > 1 && index < experiences.length - 1 && <hr />
              }
            </>
          ))
        }
      </div>
    </>
  );
};

export default WorkExperienceBlock;
