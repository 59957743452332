import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';
// api
import ApiKey from '../../api/ApiKey';
import BaseApi from '../../api/BaseApi';
// components
// import LanguageSwitcher from './components/LanguageSwitcher';
// helpers
import { logOutIfTokenExpired } from '../../helpers';
// mui
import SearchIcon from '@mui/icons-material/Search';

const NavBar = () => {
  const navigate = useNavigate();

  const { t } = useTranslation('global');

  const tokenKey = Cookies.get('tokenClient');

  const [login, setLogin] = useState(false);
  const [userName, setUserName] = useState();
  const [userType, setUserType] = useState();

  const navSetter = useCallback(() => {
    if (tokenKey) {
      setLogin(true);
      setUserName(Cookies.get('fname'));
      setUserType(Cookies.get('user_type'));
    } else {
      setLogin(false);
    }
  }, [tokenKey, setLogin, setUserName, setUserType]);

  const handleLogOut = async () => {
    try {
      const confirmationResult = await Swal.fire({
        icon: 'question',
        showCancelButton: true,
        title: t('navHeaders.logout'),
        cancelButtonText: t('navHeaders.no'),
        text: t('navHeaders.logoutConfirmTxt'),
        confirmButtonText: t('navHeaders.yes'),
      });

      if (confirmationResult.isConfirmed) {
        await axios.post(BaseApi + '/users/logout', null, {
          headers: {
            key: ApiKey,
            token: tokenKey,
            'Content-Type': 'application/json',
          },
        });

        Cookies.remove('fname');
        Cookies.remove('user_id');
        Cookies.remove('user_type');
        Cookies.remove('tokenClient');

        navigate('/');

        window.location.reload();

        const Toast = Swal.mixin({
          timer: 3000,
          toast: true,
          position: 'top-end',
          timerProgressBar: true,
          showConfirmButton: false,
          didOpen: toast => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: 'success',
          title: t('navHeaders.successTitle'),
        });
      }
    } catch (error) {
      if (error.message === 'Network Error' || error.response.data.message === 'Token has expired') {
        navigate('/');

        logOutIfTokenExpired(t('tokenExpired.tokenExpired'), t('tokenExpired.close'));
      }

      console.error(error);
    }
  };

  useEffect(() => {
    navSetter();
  }, [navSetter]);

  return (
    <Navbar expand='md' className='defaultnavbar'>
      <Container>
        <NavLink to='/'>
          <Navbar.Brand>
            <img
              alt='logo'
              width='50px'
              src='/Images/Logo.png'
              className='frontendNavLogo'
            />
          </Navbar.Brand>
        </NavLink>
        <Navbar.Toggle aria-controls='navbarScroll' />
        <Navbar.Collapse id='navbarScroll' className='navbardefault'>
          <Nav
            navbarScroll
            className='navigation'
          >
            <NavLink
              to='/'
              exact='true'
              className='navitems'
              activeClassName='active'
            >
              {t('navHeaders.home')}
            </NavLink>
            <NavLink
              to='/aboutus'
              className='navitems'
              activeClassName='active'
            >
              {t('navHeaders.aboutus')}
            </NavLink>
            {
              !login
                ? (
                  <>
                    <NavLink
                      to='/forjobseekers'
                      className='navitems'
                      activeClassName='active'
                    >
                      {t('navHeaders.forJobseekers')}
                    </NavLink>
                    <NavLink
                      to='/foremployers'
                      className='navitems'
                      activeClassName='active'
                    >
                      {t('navHeaders.forEmployers')}
                    </NavLink>
                  </>
                )
                : (
                  userType === 'recruiter'
                    ? (
                      <NavLink
                        className='navitems'
                        activeClassName='active'
                        to='/candidates/listing'
                      >
                        {t('navHeaders.jobseekers')}
                      </NavLink>
                    )
                    : (
                      <NavLink
                        to='/foremployers'
                        className='navitems'
                        activeClassName='active'
                      >
                        {t('navHeaders.forEmployers')}
                      </NavLink>
                    )
                )
            }
            <NavLink
              to='/blog'
              className='navitems'
              activeClassName='active'
            >
              {t('navHeaders.blog')}
            </NavLink>
            <NavLink
              to='/contact'
              className='navitems'
              activeClassName='active'
            >
              {t('navHeaders.contactus')}
            </NavLink>
            <NavLink
              to='/searchjob'
              activeClassName='active'
              className='navitems SearchIcon'
            >
              <SearchIcon sx={{ width: 32, height: 32, color: '#3C3C3C', '&:hover': { color: '#f81f1f' } }} />
            </NavLink>
            {/* <LanguageSwitcher /> */}
            {
              login ? (
                <>
                  {
                    userType === 'recruiter' && (
                      <>
                        <NavLink
                          className='navitems'
                          to='/user/myprofile'
                          activeClassName='active'
                        >
                          <i className='fa-solid fa-user me-2'></i>
                          {userName}
                        </NavLink>
                        <Link
                          className='navitems'
                          onClick={handleLogOut}
                        >
                          {t('navHeaders.logout')}
                        </Link>
                      </>
                    )
                  }
                  {/* {
                    userType === 'candidate' && (
                      <>
                        <NavLink
                          activeClassName='active'
                          to='/candidates/my-cv-list'
                          className='navitems navUsername'
                        >
                          <i className='fa-solid fa-user me-2'></i>
                          {userName}
                        </NavLink>
                        <Link
                          className='navitems'
                          onClick={handleLogOut}
                        >
                          {t('navHeaders.logout')}
                        </Link>
                      </>
                    )
                  } */}
                </>
              ) : (
                <>
                  <div className='dropdown'>
                    <button
                      type='button'
                      aria-expanded='false'
                      data-bs-toggle='dropdown'
                      className='btn navButton1 dropdown-toggle'
                    >
                      {t('navHeaders.login')}
                    </button>
                    <ul className='dropdown-menu'>
                      <li>
                        <Link to='/user/employerlogin' className='dropdown-item'>
                          {t('navHeaders.employerLogin')}
                        </Link>
                      </li>
                      {/* <li>
                        <Link to='/user/jobseekerlogin' className='dropdown-item'>
                          {t('navHeaders.jobseekerLogin')}
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                  <div className='dropdown'>
                    <button
                      type='button'
                      aria-expanded='false'
                      data-bs-toggle='dropdown'
                      className='btn navButton2 dropdown-toggle'
                    >
                      {t('navHeaders.register')}
                    </button>
                    <ul className='dropdown-menu'>
                      <li>
                        <Link
                          to='/user/register/employer'
                          className='dropdown-item'
                        >
                          {t('navHeaders.employerRegister')}
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          to='/user/register/jobseeker'
                          className='dropdown-item'
                        >
                          {t('navHeaders.jobseekerRegister')}
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </>
              )
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
